import DEBUG from '@/common/DEBUG';
import { APIURL } from '@/common/constants'

const apiurl = APIURL;
DEBUG.log('APIUrl - ', apiurl);

import * as grpcWeb from 'grpc-web';

const AccessTokenValidationUnaryInterceptor = function () { };
AccessTokenValidationUnaryInterceptor.prototype.intercept = function (request, invoker) {
	// Update the request message before the RPC.
	const reqMsg = request.getRequestMessage();
	reqMsg.setMessage('[Intercept request]' + reqMsg.getMessage());

	// After the RPC returns successfully, update the response.
	return invoker(request).then((response) => {
		// You can also do something with response metadata here.
		DEBUG.log(response.getMetadata());

		// Update the response message.
		const responseMsg = response.getResponseMessage();
		responseMsg.setMessage('[Intercept response]' + responseMsg.getMessage());

		return response;
	});
};

const connectOptions = {
	// @ts-ignore
	//'unaryInterceptors': [new AccessTokenValidationUnaryInterceptor()]
};

import { AuthenticationServicePromiseClient } from '../grpcservices/Authentication_grpc_web_pb';
const authenticationServiceClient = new AuthenticationServicePromiseClient(apiurl, null, connectOptions);

import { AccountServicePromiseClient } from '../grpcservices/Account_grpc_web_pb';
const accountServiceClient = new AccountServicePromiseClient(apiurl, null, connectOptions);

import { DiagnosticsServicePromiseClient } from '../grpcservices/Diagnostics_grpc_web_pb';
const diagnosticsServiceClient = new DiagnosticsServicePromiseClient(apiurl, null, connectOptions);

import { GameServicePromiseClient } from '../grpcservices/Game_grpc_web_pb';
const gameServiceClient = new GameServicePromiseClient(apiurl, null, connectOptions);

// Use: https://chrome.google.com/webstore/detail/grpc-web-developer-tools/ddamlpimmiapbcopeoifjfmoabdbfbjj?hl=en
const enableDevTools = (<any>window).__GRPCWEB_DEVTOOLS__ || (() => { });

enableDevTools([
	authenticationServiceClient,
	accountServiceClient,
	gameServiceClient,
	diagnosticsServiceClient
]);

export {
	authenticationServiceClient,
	accountServiceClient,
	gameServiceClient,
	diagnosticsServiceClient
}