/**
 * @fileoverview gRPC-Web generated client stub for services
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var Authentication_pb = require('./Authentication_pb.js')
const proto = {};
proto.services = require('./Account_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.services.AccountServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.services.AccountServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.services.GetCurrentUserInfoResponse>}
 */
const methodDescriptor_AccountService_GetCurrentUserInfo = new grpc.web.MethodDescriptor(
  '/services.AccountService/GetCurrentUserInfo',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.services.GetCurrentUserInfoResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetCurrentUserInfoResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetCurrentUserInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetCurrentUserInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.getCurrentUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/GetCurrentUserInfo',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetCurrentUserInfo,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetCurrentUserInfoResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.getCurrentUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/GetCurrentUserInfo',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetCurrentUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetUserAvatarRequest,
 *   !proto.services.GetUserAvatarResponse>}
 */
const methodDescriptor_AccountService_GetUserAvatar = new grpc.web.MethodDescriptor(
  '/services.AccountService/GetUserAvatar',
  grpc.web.MethodType.UNARY,
  proto.services.GetUserAvatarRequest,
  proto.services.GetUserAvatarResponse,
  /**
   * @param {!proto.services.GetUserAvatarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetUserAvatarResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetUserAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetUserAvatarResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetUserAvatarResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.getUserAvatar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/GetUserAvatar',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetUserAvatar,
      callback);
};


/**
 * @param {!proto.services.GetUserAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetUserAvatarResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.getUserAvatar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/GetUserAvatar',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetUserAvatar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetUserAvatarRequestEx,
 *   !proto.services.GetUserAvatarResponseEx>}
 */
const methodDescriptor_AccountService_GetUserAvatarEx = new grpc.web.MethodDescriptor(
  '/services.AccountService/GetUserAvatarEx',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.services.GetUserAvatarRequestEx,
  proto.services.GetUserAvatarResponseEx,
  /**
   * @param {!proto.services.GetUserAvatarRequestEx} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetUserAvatarResponseEx.deserializeBinary
);


/**
 * @param {!proto.services.GetUserAvatarRequestEx} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetUserAvatarResponseEx>}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.getUserAvatarEx =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/services.AccountService/GetUserAvatarEx',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetUserAvatarEx);
};


/**
 * @param {!proto.services.GetUserAvatarRequestEx} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetUserAvatarResponseEx>}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServicePromiseClient.prototype.getUserAvatarEx =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/services.AccountService/GetUserAvatarEx',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetUserAvatarEx);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UpdateCurrentUserInfoRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AccountService_UpdateCurrentUserInfo = new grpc.web.MethodDescriptor(
  '/services.AccountService/UpdateCurrentUserInfo',
  grpc.web.MethodType.UNARY,
  proto.services.UpdateCurrentUserInfoRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.UpdateCurrentUserInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.UpdateCurrentUserInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.updateCurrentUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/UpdateCurrentUserInfo',
      request,
      metadata || {},
      methodDescriptor_AccountService_UpdateCurrentUserInfo,
      callback);
};


/**
 * @param {!proto.services.UpdateCurrentUserInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.updateCurrentUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/UpdateCurrentUserInfo',
      request,
      metadata || {},
      methodDescriptor_AccountService_UpdateCurrentUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AccountService_AcceptEULA = new grpc.web.MethodDescriptor(
  '/services.AccountService/AcceptEULA',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.acceptEULA =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/AcceptEULA',
      request,
      metadata || {},
      methodDescriptor_AccountService_AcceptEULA,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.acceptEULA =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/AcceptEULA',
      request,
      metadata || {},
      methodDescriptor_AccountService_AcceptEULA);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UploadAvatarRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AccountService_UploadUserAvatar = new grpc.web.MethodDescriptor(
  '/services.AccountService/UploadUserAvatar',
  grpc.web.MethodType.UNARY,
  proto.services.UploadAvatarRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.UploadAvatarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.UploadAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.uploadUserAvatar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/UploadUserAvatar',
      request,
      metadata || {},
      methodDescriptor_AccountService_UploadUserAvatar,
      callback);
};


/**
 * @param {!proto.services.UploadAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.uploadUserAvatar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/UploadUserAvatar',
      request,
      metadata || {},
      methodDescriptor_AccountService_UploadUserAvatar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.DeleteUserAvatarRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AccountService_DeleteUserAvatar = new grpc.web.MethodDescriptor(
  '/services.AccountService/DeleteUserAvatar',
  grpc.web.MethodType.UNARY,
  proto.services.DeleteUserAvatarRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.DeleteUserAvatarRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.DeleteUserAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.deleteUserAvatar =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/DeleteUserAvatar',
      request,
      metadata || {},
      methodDescriptor_AccountService_DeleteUserAvatar,
      callback);
};


/**
 * @param {!proto.services.DeleteUserAvatarRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.deleteUserAvatar =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/DeleteUserAvatar',
      request,
      metadata || {},
      methodDescriptor_AccountService_DeleteUserAvatar);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.services.GetRolesResponse>}
 */
const methodDescriptor_AccountService_GetRoles = new grpc.web.MethodDescriptor(
  '/services.AccountService/GetRoles',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.services.GetRolesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetRolesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.getRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/GetRoles',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetRoles,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetRolesResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.getRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/GetRoles',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetUsersRequest,
 *   !proto.services.GetUsersResponse>}
 */
const methodDescriptor_AccountService_GetUsers = new grpc.web.MethodDescriptor(
  '/services.AccountService/GetUsers',
  grpc.web.MethodType.UNARY,
  proto.services.GetUsersRequest,
  proto.services.GetUsersResponse,
  /**
   * @param {!proto.services.GetUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetUsersResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.getUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/GetUsers',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetUsers,
      callback);
};


/**
 * @param {!proto.services.GetUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetUsersResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.getUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/GetUsers',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetUserRequest,
 *   !proto.services.UserResponse>}
 */
const methodDescriptor_AccountService_GetUser = new grpc.web.MethodDescriptor(
  '/services.AccountService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.services.GetUserRequest,
  proto.services.UserResponse,
  /**
   * @param {!proto.services.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.UserResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/GetUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetUser,
      callback);
};


/**
 * @param {!proto.services.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.UserResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/GetUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UserRoleRequest,
 *   !proto.services.UserResponse>}
 */
const methodDescriptor_AccountService_AddRoleToUser = new grpc.web.MethodDescriptor(
  '/services.AccountService/AddRoleToUser',
  grpc.web.MethodType.UNARY,
  proto.services.UserRoleRequest,
  proto.services.UserResponse,
  /**
   * @param {!proto.services.UserRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.UserResponse.deserializeBinary
);


/**
 * @param {!proto.services.UserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.addRoleToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/AddRoleToUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_AddRoleToUser,
      callback);
};


/**
 * @param {!proto.services.UserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.UserResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.addRoleToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/AddRoleToUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_AddRoleToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UserRoleRequest,
 *   !proto.services.UserResponse>}
 */
const methodDescriptor_AccountService_RemoveRoleFromUser = new grpc.web.MethodDescriptor(
  '/services.AccountService/RemoveRoleFromUser',
  grpc.web.MethodType.UNARY,
  proto.services.UserRoleRequest,
  proto.services.UserResponse,
  /**
   * @param {!proto.services.UserRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.UserResponse.deserializeBinary
);


/**
 * @param {!proto.services.UserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.removeRoleFromUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/RemoveRoleFromUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_RemoveRoleFromUser,
      callback);
};


/**
 * @param {!proto.services.UserRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.UserResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.removeRoleFromUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/RemoveRoleFromUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_RemoveRoleFromUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UserClaimRequest,
 *   !proto.services.UserResponse>}
 */
const methodDescriptor_AccountService_AddClaimToUser = new grpc.web.MethodDescriptor(
  '/services.AccountService/AddClaimToUser',
  grpc.web.MethodType.UNARY,
  proto.services.UserClaimRequest,
  proto.services.UserResponse,
  /**
   * @param {!proto.services.UserClaimRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.UserResponse.deserializeBinary
);


/**
 * @param {!proto.services.UserClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.addClaimToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/AddClaimToUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_AddClaimToUser,
      callback);
};


/**
 * @param {!proto.services.UserClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.UserResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.addClaimToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/AddClaimToUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_AddClaimToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UserClaimRequest,
 *   !proto.services.UserResponse>}
 */
const methodDescriptor_AccountService_RemoveClaimFromUser = new grpc.web.MethodDescriptor(
  '/services.AccountService/RemoveClaimFromUser',
  grpc.web.MethodType.UNARY,
  proto.services.UserClaimRequest,
  proto.services.UserResponse,
  /**
   * @param {!proto.services.UserClaimRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.UserResponse.deserializeBinary
);


/**
 * @param {!proto.services.UserClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.UserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.UserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.removeClaimFromUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/RemoveClaimFromUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_RemoveClaimFromUser,
      callback);
};


/**
 * @param {!proto.services.UserClaimRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.UserResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.removeClaimFromUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/RemoveClaimFromUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_RemoveClaimFromUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UserId,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AccountService_LockUser = new grpc.web.MethodDescriptor(
  '/services.AccountService/LockUser',
  grpc.web.MethodType.UNARY,
  proto.services.UserId,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.UserId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.UserId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.lockUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/LockUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_LockUser,
      callback);
};


/**
 * @param {!proto.services.UserId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.lockUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/LockUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_LockUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UserId,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AccountService_UnlockUser = new grpc.web.MethodDescriptor(
  '/services.AccountService/UnlockUser',
  grpc.web.MethodType.UNARY,
  proto.services.UserId,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.UserId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.UserId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.unlockUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/UnlockUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_UnlockUser,
      callback);
};


/**
 * @param {!proto.services.UserId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.unlockUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/UnlockUser',
      request,
      metadata || {},
      methodDescriptor_AccountService_UnlockUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetCurrentUserWalletRequest,
 *   !proto.services.GetCurrentUserWalletResponse>}
 */
const methodDescriptor_AccountService_GetCurrentUserWallet = new grpc.web.MethodDescriptor(
  '/services.AccountService/GetCurrentUserWallet',
  grpc.web.MethodType.UNARY,
  proto.services.GetCurrentUserWalletRequest,
  proto.services.GetCurrentUserWalletResponse,
  /**
   * @param {!proto.services.GetCurrentUserWalletRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetCurrentUserWalletResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetCurrentUserWalletRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetCurrentUserWalletResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetCurrentUserWalletResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.getCurrentUserWallet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/GetCurrentUserWallet',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetCurrentUserWallet,
      callback);
};


/**
 * @param {!proto.services.GetCurrentUserWalletRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetCurrentUserWalletResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.getCurrentUserWallet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/GetCurrentUserWallet',
      request,
      metadata || {},
      methodDescriptor_AccountService_GetCurrentUserWallet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UpdateCurrentUserWalletRequest,
 *   !proto.services.UpdateCurrentUserWalletResponse>}
 */
const methodDescriptor_AccountService_UpdateCurrentUserWallet = new grpc.web.MethodDescriptor(
  '/services.AccountService/UpdateCurrentUserWallet',
  grpc.web.MethodType.UNARY,
  proto.services.UpdateCurrentUserWalletRequest,
  proto.services.UpdateCurrentUserWalletResponse,
  /**
   * @param {!proto.services.UpdateCurrentUserWalletRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.UpdateCurrentUserWalletResponse.deserializeBinary
);


/**
 * @param {!proto.services.UpdateCurrentUserWalletRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.UpdateCurrentUserWalletResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.UpdateCurrentUserWalletResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AccountServiceClient.prototype.updateCurrentUserWallet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AccountService/UpdateCurrentUserWallet',
      request,
      metadata || {},
      methodDescriptor_AccountService_UpdateCurrentUserWallet,
      callback);
};


/**
 * @param {!proto.services.UpdateCurrentUserWalletRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.UpdateCurrentUserWalletResponse>}
 *     Promise that resolves to the response
 */
proto.services.AccountServicePromiseClient.prototype.updateCurrentUserWallet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AccountService/UpdateCurrentUserWallet',
      request,
      metadata || {},
      methodDescriptor_AccountService_UpdateCurrentUserWallet);
};


module.exports = proto.services;

