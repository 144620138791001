/**
 * @fileoverview gRPC-Web generated client stub for services
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.services = require('./Diagnostics_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.services.DiagnosticsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.services.DiagnosticsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.services.GetEnvironmentInfoResponse>}
 */
const methodDescriptor_DiagnosticsService_GetEnvironmentInfo = new grpc.web.MethodDescriptor(
  '/services.DiagnosticsService/GetEnvironmentInfo',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.services.GetEnvironmentInfoResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetEnvironmentInfoResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetEnvironmentInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetEnvironmentInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.DiagnosticsServiceClient.prototype.getEnvironmentInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.DiagnosticsService/GetEnvironmentInfo',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_GetEnvironmentInfo,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetEnvironmentInfoResponse>}
 *     Promise that resolves to the response
 */
proto.services.DiagnosticsServicePromiseClient.prototype.getEnvironmentInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.DiagnosticsService/GetEnvironmentInfo',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_GetEnvironmentInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.services.GetEnvironmentInfoResponse>}
 */
const methodDescriptor_DiagnosticsService_GetEnvironmentInfo2 = new grpc.web.MethodDescriptor(
  '/services.DiagnosticsService/GetEnvironmentInfo2',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.services.GetEnvironmentInfoResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetEnvironmentInfoResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetEnvironmentInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetEnvironmentInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.DiagnosticsServiceClient.prototype.getEnvironmentInfo2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.DiagnosticsService/GetEnvironmentInfo2',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_GetEnvironmentInfo2,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetEnvironmentInfoResponse>}
 *     Promise that resolves to the response
 */
proto.services.DiagnosticsServicePromiseClient.prototype.getEnvironmentInfo2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.DiagnosticsService/GetEnvironmentInfo2',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_GetEnvironmentInfo2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.SendMailRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_DiagnosticsService_SendMail = new grpc.web.MethodDescriptor(
  '/services.DiagnosticsService/SendMail',
  grpc.web.MethodType.UNARY,
  proto.services.SendMailRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.SendMailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.SendMailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.DiagnosticsServiceClient.prototype.sendMail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.DiagnosticsService/SendMail',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_SendMail,
      callback);
};


/**
 * @param {!proto.services.SendMailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.DiagnosticsServicePromiseClient.prototype.sendMail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.DiagnosticsService/SendMail',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_SendMail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_DiagnosticsService_SendSignalRMessage = new grpc.web.MethodDescriptor(
  '/services.DiagnosticsService/SendSignalRMessage',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.DiagnosticsServiceClient.prototype.sendSignalRMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.DiagnosticsService/SendSignalRMessage',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_SendSignalRMessage,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.DiagnosticsServicePromiseClient.prototype.sendSignalRMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.DiagnosticsService/SendSignalRMessage',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_SendSignalRMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_DiagnosticsService_SendSignalRMessage2 = new grpc.web.MethodDescriptor(
  '/services.DiagnosticsService/SendSignalRMessage2',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.DiagnosticsServiceClient.prototype.sendSignalRMessage2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.DiagnosticsService/SendSignalRMessage2',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_SendSignalRMessage2,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.DiagnosticsServicePromiseClient.prototype.sendSignalRMessage2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.DiagnosticsService/SendSignalRMessage2',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_SendSignalRMessage2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.SetCacheValueRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_DiagnosticsService_SetCacheValue = new grpc.web.MethodDescriptor(
  '/services.DiagnosticsService/SetCacheValue',
  grpc.web.MethodType.UNARY,
  proto.services.SetCacheValueRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.SetCacheValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.SetCacheValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.DiagnosticsServiceClient.prototype.setCacheValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.DiagnosticsService/SetCacheValue',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_SetCacheValue,
      callback);
};


/**
 * @param {!proto.services.SetCacheValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.DiagnosticsServicePromiseClient.prototype.setCacheValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.DiagnosticsService/SetCacheValue',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_SetCacheValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetCacheValueRequest,
 *   !proto.services.GetCacheValueResponse>}
 */
const methodDescriptor_DiagnosticsService_GetCacheValue = new grpc.web.MethodDescriptor(
  '/services.DiagnosticsService/GetCacheValue',
  grpc.web.MethodType.UNARY,
  proto.services.GetCacheValueRequest,
  proto.services.GetCacheValueResponse,
  /**
   * @param {!proto.services.GetCacheValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetCacheValueResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetCacheValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetCacheValueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetCacheValueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.DiagnosticsServiceClient.prototype.getCacheValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.DiagnosticsService/GetCacheValue',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_GetCacheValue,
      callback);
};


/**
 * @param {!proto.services.GetCacheValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetCacheValueResponse>}
 *     Promise that resolves to the response
 */
proto.services.DiagnosticsServicePromiseClient.prototype.getCacheValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.DiagnosticsService/GetCacheValue',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_GetCacheValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetCacheValueRequest,
 *   !proto.services.GetCacheValueResponse>}
 */
const methodDescriptor_DiagnosticsService_GetCacheValue2 = new grpc.web.MethodDescriptor(
  '/services.DiagnosticsService/GetCacheValue2',
  grpc.web.MethodType.UNARY,
  proto.services.GetCacheValueRequest,
  proto.services.GetCacheValueResponse,
  /**
   * @param {!proto.services.GetCacheValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetCacheValueResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetCacheValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetCacheValueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetCacheValueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.DiagnosticsServiceClient.prototype.getCacheValue2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.DiagnosticsService/GetCacheValue2',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_GetCacheValue2,
      callback);
};


/**
 * @param {!proto.services.GetCacheValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetCacheValueResponse>}
 *     Promise that resolves to the response
 */
proto.services.DiagnosticsServicePromiseClient.prototype.getCacheValue2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.DiagnosticsService/GetCacheValue2',
      request,
      metadata || {},
      methodDescriptor_DiagnosticsService_GetCacheValue2);
};


module.exports = proto.services;

