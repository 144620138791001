<template>
	<section>
		<appprogressbar />
		<router-view />
	</section>
</template>
<script lang="js">
	import appprogressbar from '@/components/appprogressbar'
	import DEBUG from '@/common/DEBUG';

	export default {
		name: 'app',

		components: {
			appprogressbar
		},

		//mounted() {
		//	const that = this
		//	const route = that.$router
		//	let progresstimer = null;

		//	route.beforeEach((from, to, next) => {
		//		DEBUG.log('beforeEach');

		//		that.processingpercent = 10;
		//		progresstimer = setInterval(() => {
		//			if (that.processingpercent < 90) {
		//				that.processingpercent += 4
		//			}
		//		}, 1000)

		//		next()
		//	})

		//	route.afterEach((from, to) => {
		//		DEBUG.log('afterEach');

		//		clearInterval(progresstimer)
		//		that.processingpercent = 100
		//		that.$nextTick(() => {
		//			that.processingpercent = 0
		//		})
		//	})
		//}
	}
</script>

<style lang="scss">
	@import "./assets/scss/style.scss";
	@import "./assets/scss/auth.scss";

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	#nav {
		padding: 30px;
		a

	{
		font-weight: bold;
		color: #2c3e50;
		&.router-link-exact-active

	{
		color: #42b983;
	}

	}
	}
</style>