"use strict;"
//const IsProduction = process.env.NODE_ENV !== 'production'

import Vue from 'vue'
import Vuex from 'vuex'
import BUILD from '@/config/build'
import SWHelper from '@/common/swutils'
import DEBUG from '@/common/DEBUG'
import JWT from '@/common/jwt.service'
import { splitEmailIds } from '@/common/utils'
import { getNumberWithOrdinal } from '@/common/utils'
import FileStreamer from '@/common/FileStreamer'
import { wrapError } from '@/common/errorunwrapper'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { Int32Value, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import {
	authenticationServiceClient,
	gameServiceClient,
	diagnosticsServiceClient,
	accountServiceClient
} from '@/services'

import {
	LoginRequest,
	LoginWithGameOTPRequest,
	RegisterRequest,
	PasswordResetRequest,
	PasswordResetChallengeRequest,
	IsValidGameOTPRequest
} from '@/grpcservices/Authentication_pb'

import {
	GetUserRequest,
	GetUsersRequest,
	BLOBData, BLOBMetadata,
	UploadAvatarRequest,
	UploadAvatarRequestEx,
	DeleteUserAvatarRequest,
	GetUserAvatarRequest,
	GetCurrentUserWalletRequest,
	UpdateCurrentUserInfoRequest,
	UpdateCurrentUserWalletRequest,
	UserClaimRequest, UserId, UserRoleRequest,
} from '@/grpcservices/Account_pb';

import {
	GameId,
	GameDefinition,
	GameDefId,
	InviteParticipantRequest,
	JoinGameRequest,
	SelfInviteRequest,
	DoClosedPlayerActionRequest,
	GetGamesRequest, ParticipantStates, ParticipantHandStates, TrayTypes,
	ToggleGameStateDebugRequest,
	ToggleGameStateVideoRequest,
	DoOpenPlayerActionRequest,
	UpdateParticipantStateRequest,
	UpdateParticipantHandStateRequest,
	UpdateJokerTypeRequest,
	GroupedCardId, TableStates, JokerTypes, ShuffleModes,
	StartGameRoundRequest,
	DoOpenTableActionRequest,
	GetGameDefinitionsRequest,
	GetActiveGamesRequest,
} from '@/grpcservices/Game_pb';

import {
	SendMailRequest,
	GetCacheValueRequest,
	SetCacheValueRequest
} from '../grpcservices/Diagnostics_pb'

import { getKeyByValue } from '@/utils'
import GameStateHelpers from '@/common/gamestatehelpers'
import { BIconArrowReturnLeft } from 'bootstrap-vue'

let PasswordCredential: any;

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		clientid: JWT.getClientId(),
		//environment: config.environment,
		BUILD_TIMESTAMP: BUILD.__BUILD_TIMESTAMP__,
		BUILD_HASH: BUILD.__BUILD_HASH__,

		gameState: {},
		gameDef: {},
		allGameDefs: [],
		tableMessage: '',

		userWallet: [],

		// AUTH
		token: JWT.getToken(),
		rtoken: JWT.getRToken(),
		userinfo: JWT.getUserInfo(),

		isprocessing: false,
		processingpercent: 0,
		haserror: false,
		error: null,
	},

	getters: {
		remotename: state => 'web:' + state.clientid,
		hasRole: (state) => (roleId) => GameStateHelpers.hasRole(state?.userinfo, roleId),
		hasClaim: (state) => (claimName) => GameStateHelpers.hasClaim(state?.userinfo, claimName),
		isuser_admin: (state, getters) => getters.hasRole('00000000-0000-0000-0000-000000000000') || getters.hasClaim('isadministrator'),
		isuser_gamecreator: (state, getters) => getters.hasClaim('isgamecreator'),
		isuser_gamehost: (state, getters) => getters.hasClaim('isgamehost'),
	},

	actions: {
		// authentication
		initConnection(_, initinfo) {
			const token = JWT.getToken()
			SWHelper.worker = initinfo ? initinfo.worker : null
			SWHelper.accessToken = token
		},
		async register({ commit }, registerinfo) {
			DEBUG.log('STORE:ACTION:register... %o', registerinfo)
			const { userdisplayname, email, phonenumber, password } = registerinfo

			const registerRequest = new RegisterRequest()
				.setUserDisplayName(userdisplayname)
				.setPassword(password)
				.setEmail(email)
				.setPhoneNumber(phonenumber)
				;

			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const result = await authenticationServiceClient.register(registerRequest);
				const userId = result.getUserId();
				DEBUG.log('registration succeeded - ', userId)
				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async login({ commit, dispatch, getters }, userLoginInfo) {
			const { email, password } = userLoginInfo

			const request = new LoginRequest()
				.setEmail(email)
				.setPassword(password)
				.setRemoteName(getters.remotename);

			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const response = await authenticationServiceClient.login(request);
				const loginInfo = response.toObject();

				await dispatch('processAuthToken', loginInfo);
				DEBUG.log('STORE:ACTION:LOGIN succeeded')
			}
			catch (error) {
				DEBUG.log("ERROR ---------------- ", error);

				const nerror = wrapError(error);
				JWT.clearAllTokenInfo();
				commit('AUTH_ERROR', nerror.message);
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async isValidGameOTP({ commit }, OTPInfo) {
			const { OTP } = OTPInfo;


			const request = new IsValidGameOTPRequest()
				.setOtp(OTP);

			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				await authenticationServiceClient.isValidGameOTP(request);
				DEBUG.log('STORE:ACTION:ISVALIDGAMEOTP succeeded')
			}
			catch (error) {
				DEBUG.log("ERROR ---------------- ", error);

				const nerror = wrapError(error);
				JWT.clearAllTokenInfo();
				commit('AUTH_ERROR', nerror.message);
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async joinWithGameOTP({ commit, dispatch, getters }, userLoginInfo) {
			const { email, OTP } = userLoginInfo

			const request = new LoginWithGameOTPRequest()
				.setEmail(email)
				.setOtp(OTP)
				.setRemoteName(getters.remotename);

			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const response = await authenticationServiceClient.loginWithGameOTP(request);
				const loginInfo = response.toObject();
				const { gameId } = loginInfo;
				await dispatch('processAuthToken', loginInfo);

				DEBUG.log('STORE:ACTION:JOINWITHGAMEOTP succeeded')
				return { gameId };
			}
			catch (error) {
				DEBUG.log("ERROR ---------------- ", error);

				const nerror = wrapError(error);
				JWT.clearAllTokenInfo();
				commit('AUTH_ERROR', nerror.message);
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async processAuthToken({ commit, getters }, loginInfo) {
			try {
				const { token: tokenInfo } = loginInfo;
				const { token, refreshToken: rtoken, userInfo } = tokenInfo;
				//const { expiresInSeconds, expiresAtUtc } = token;
				const currentuser = GameStateHelpers.recursivelyProcessListProperty(userInfo)

				JWT.saveToken(token)
				JWT.saveRToken(rtoken)
				JWT.saveUserInfo(currentuser)

				SWHelper.accessToken = token

				commit('AUTH_SUCCESS', { rtoken, token, currentuser })
				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error);
				JWT.clearAllTokenInfo();
				commit('AUTH_ERROR', nerror.message);
				throw nerror
			}
		},
		logout({ commit }) {
			commit('AUTH_LOGOUT')
			commit('CLEARERROR')
			DEBUG.log('STORE:ACTION:LOGOUT - ')

			JWT.clearAllTokenInfo()
			SWHelper.accessToken = null
		},

		// user info update
		async updateCurrentUserInfo({ commit, state }, { userId, userDisplayName }) {
			DEBUG.log('STORE:ACTION:UPDATECURRENTUSERINFO...')
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new UpdateCurrentUserInfoRequest()
					.setUserId(userId)
					.setUserDisplayName(new StringValue().setValue(userDisplayName));

				const response = await accountServiceClient.updateCurrentUserInfo(request, {
					'Authorization': 'Bearer ' + state.token
				});

				DEBUG.log('update user info succeeded - ', userId)
				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async getCurrentUserWallet({ commit, state },{currency}) {
			console.log('Index.TS:getCurrentUserWallet...');
			const request = new GetCurrentUserWalletRequest()
					.setCurrency(currency)
			const response = await accountServiceClient.getCurrentUserWallet(request, {
				'Authorization': 'Bearer ' + state.token
			});
			console.log('Index.TS:getCurrentUserWallet...response - ', response);

			if(response.hasWallet())
			{
				var userWalletObj = response.getWallet()?.toObject();
				console.log('Index.TS:getCurrentUserWallet returning - ', userWalletObj);
				commit('UPDATE_USER_WALLET', userWalletObj)
			}
			return userWalletObj;
		},
		// user wallet update
		async updateCurrentUserWallet({ commit, state }, { userId, balance, currency }) {
			console.log('STORE:ACTION:UPDATECURRENTUSERWALLET...', balance, currency)
			try {
				const request = new UpdateCurrentUserWalletRequest()
					.setUserId(userId)
					.setWalletCurrency(currency)
					.setWalletBalance(balance);
				const response = await accountServiceClient.updateCurrentUserWallet(request, {
					'Authorization': 'Bearer ' + state.token
				});
				DEBUG.log('update user wallet succeeded - ', userId)
				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async acceptEula({ commit, state }) {
			DEBUG.log('STORE:ACTION:acceptEULA...')
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const response = await accountServiceClient.acceptEULA(new Empty(), {
					'Authorization': 'Bearer ' + state.token
				});

				DEBUG.log('update EULA succeeded.')
				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async updateGameState({ commit }, stateObj) {
			if (stateObj) {
				commit('UPDATE_GAME_STATE', stateObj)
			}
		},
		async updateTableMessage({ commit }, { tableMessageObj, autoHide }) {
			DEBUG.log('table message', tableMessageObj)
			if (tableMessageObj) {
				commit('UPDATE_TABLE_MESSAGE', { tableMessageObj: tableMessageObj, autoHide: autoHide })
			}
		},

		async passwordReset({ commit }, resetinfo) {
			DEBUG.log('STORE:ACTION:passwordReset... %o', resetinfo)
			const { email } = resetinfo

			const request = new PasswordResetRequest()
				.setEmail(email)
				;

			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const result = await authenticationServiceClient.passwordReset(request);
				DEBUG.log('password reseet triggered - ', result)
				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async passwordResetChallenge({ commit }, resetinfo) {
			const { email, password, change, userDisplayName, token } = resetinfo

			const request = new PasswordResetChallengeRequest()
				.setEmail(email)
				.setPassword(password)
				.setToken(token)
				.setChange(change)
				.setUserDisplayName(userDisplayName)
				;
			
				commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const result = await authenticationServiceClient.passwordResetChallenge(request);
				DEBUG.log('password reseet triggered - ', result)
				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},

		// invoke helper
		async genericInvoke({ commit, state }, { method, request }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const response = await method.call(accountServiceClient, request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
				return response;
			}
			catch (error) {
				console.log("index.ts ERROR : genericInvove ", error);
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},

		// users
		async lockUser({ dispatch }, userId) {
			var request = new UserId()
				.setId(userId);
			await dispatch('genericInvoke', { method: accountServiceClient.lockUser, request });
		},
		async unlockUser({ dispatch }, userId) {
			var request = new UserId()
				.setId(userId);
			await dispatch('genericInvoke', { method: accountServiceClient.unlockUser, request });
		},
		async getUsers({ dispatch }, { nextPageToken }) {
			var request = new GetUsersRequest();
			if (nextPageToken) {
				request = request.setPageToken(new StringValue().setValue(nextPageToken));
			}
			const response = await dispatch('genericInvoke', { method: accountServiceClient.getUsers, request });
			return {
				nextPageToken: response.getNextPageToken()?.getValue(),
				users: response.getUsersList().map(g => g.toObject())
			};
		},
		async getUser({ commit, state }, id) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				var request = new GetUserRequest().setId(id);
				const response = await accountServiceClient.getUser(request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR');
				return response.toObject().user;
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async getUserAvatar({ state }, { id, ts }) {
			let image: string | null = null;
			try {
				const hash = window.location.origin + '::user::' + id + '::' + ts;
				const cachedData = localStorage.getItem(hash);
				if (cachedData) {
					image = cachedData;
					DEBUG.log('useravatar - cache HIT - user id - %o', id);
				}
				else {
					const request = new GetUserAvatarRequest()
						.setUserId(id);
					const response = await accountServiceClient.getUserAvatar(request, {
						'Authorization': 'Bearer ' + state.token
					});

					const { mimeType, data } = response.toObject();
					image = `data:${mimeType};base64,${data}`;
					localStorage.setItem(hash, image);
					DEBUG.log('useravatar - CACHE MISS - user id - %o, hash - %o', id, hash);
				}
			}
			catch (error) {
				const nerror = wrapError(error)
				DEBUG.error(nerror)
			}

			return image;
		},
		async uploadUserAvatar({ commit, state }, file) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				// call metadata API
				const fileName = file.name;
				const mimeType = file.type;
				DEBUG.log('FILE - ', file);
				const buffer = await file.arrayBuffer();
				const request = new UploadAvatarRequest()
					.setName(fileName)
					.setMimeType(mimeType)
					.setData(new Uint8Array(buffer));

				await accountServiceClient.uploadUserAvatar(request, {
					'Authorization': 'Bearer ' + state.token
				});
				commit('CLEARERROR');
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async uploadUserAvatarEx({ commit, state }, file) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				throw "not supported"
				/*
				// call metadata API
				const fileName = file.name;
				const mimeType = file.type;
				DEBUG.log('FILE - ', file);
				await accountServiceClient.uploadUserAvatar(
					new UploadAvatarRequest()
						.setMetadata(new BLOBMetadata()
							.setName(fileName)
							.setMimeType(mimeType)), {
					'Authorization': 'Bearer ' + state.token
				});

				// upload chunks
				const fileStreamer = new FileStreamer(file);
				while (!fileStreamer.isEndOfFile()) {
					const { offset, buffer} = await fileStreamer.readBlockAsArrayBuffer();
					DEBUG.log('uploading - offset=%o buffer=%o', offset, buffer);

					await accountServiceClient.uploadUserAvatar(
						new UploadAvatarRequest()
							.setData(new BLOBData()
								.setOffset(offset)
								.setData(buffer)), {
						'Authorization': 'Bearer ' + state.token
					});
				}
				commit('CLEARERROR');
				*/
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async DeleteUserAvatar({ commit, state }, id) {
			try {
				const request = new DeleteUserAvatarRequest()
					.setUserId(id);

				await accountServiceClient.deleteUserAvatar(request, {
					'Authorization': 'Bearer ' + state.token
				});
			}
			catch (error) {
				const nerror = wrapError(error)
				DEBUG.error(error)
				throw nerror
			}
		},

		// game - returns all published and mine unpublished....
		async getGameDefinitions({ dispatch, commit, state }, { searchText, nextPageToken }) {
				var request = new GetGameDefinitionsRequest();
				if (!(nextPageToken===undefined)) {
					request = request.setPageToken(new StringValue().setValue(nextPageToken));
				}

				if (!(searchText===undefined)) {
					request = request.setSearchText(new StringValue().setValue(searchText));
				}

				const response = await dispatch('genericInvoke', { method: gameServiceClient.getGameDefinitions, request });
				var returnval = {
					nextPageToken: response.getNextPageToken()?.getValue(),
					definitions: response.getDefinitionsList().map(g => g.toObject()).map(g => {
						const lrules = g.rules as any;
						return g;
					})	
				};
				commit('UPDATE_GAME_DEFS', returnval.definitions);
				return returnval ;
		},
		async getPublishedGameDefinitions({ dispatch, commit, state }, { searchText, nextPageToken }) {
			var request = new GetGameDefinitionsRequest();
			if (nextPageToken) {
				request = request.setPageToken(new StringValue().setValue(nextPageToken));
			}
			if (searchText) {
				request = request.setSearchText(new StringValue().setValue(searchText));
			}

			const response = await dispatch('genericInvoke', { method: gameServiceClient.getPublishedGameDefinitions, request });
			return {
				nextPageToken: response.getNextPageToken()?.getValue(),
				definitions: response.getDefinitionsList().map(g => g.toObject()).map(g => {
					const lrules = g.rules as any;
					return g;
				})
			};
		},
		async getGameDefinition({ commit, state }, id) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new GameDefId()
					.setId(id);

				const response = await gameServiceClient.getGameDefinition(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to get game definition"
				}

				commit('UPDATE_GAME_DEF', response.toObject())
				commit('CLEARERROR')
				return response.toObject();
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async insertGameDefinition({ commit, state }, gameDef) {
			DEBUG.log('INSERT GAME DEF - ', gameDef);
                        //TO-DO - Need to fix enum derive logic
			const { environment, rules } = gameDef;

			const request = new GameDefinition()
				.setId("") // setting empty to skip constraints check
				.setName(gameDef.name)
				.setIsPublished(gameDef.isPublished)
				.setGameType(gameDef.gameType)
				.setGameVariant(gameDef.gameVariant)
				.setCurrency(gameDef.currency)
				.setEnvironment(new GameDefinition.GameEnvironment()
					.setRoomType(environment.roomType)
					.setTableType(environment.tableType)
					.setCardType(environment.cardType)
				)
				.setRules(new GameDefinition.GameRules()
					.setPacksPerGame(rules.packsPerGame)
					.setAutoCalculatePacks(rules.autoCalculatePacks)
					.setPictureJokersPerPack(rules.pictureJokersPerPack)
					.setMaxPlayers(rules.maxPlayers)
					.setMustSeatDeal(rules.mustSeatDeal)
					.setCardsPerHand(rules.cardsPerHand)
					.setMaxCardsPerHand(rules.maxCardsPerHand < rules.cardsPerHand ? 
													rules.cardsPerHand : rules.maxCardsPerHand)
					.setMaxSwap(rules.maxSwap)
					.setShowHandOnTable(rules.showHandOnTable)
					.setShuffleMode(rules.shuffleMode)
					.setManualDealMode(rules.manualDealMode)
					.setAutoDealMode(rules.autoDealMode)
					.setIsOpenTable(rules.isOpenTable)
					.setHasPot(rules.hasPot)
					.setMinPot(rules.minPot)
					.setMaxPot(rules.maxPot)
					.setAnte(rules.ante)
					.setDealerBoot(rules.dealerBoot)
					.setCompulsoryBlind(rules.compulsoryBlind)
					.setHiLoType(rules.hiLoType)
					.setMaxBlinds(rules.maxBlinds)
					.setSalaamiAceTrail(rules.salaamiAceTrail)
					.setSalaamiPictureTrail(rules.salaamiPictureTrail)
					.setSalaamiTrail(rules.salaamiTrail)
					.setBettingLimit(rules.bettingLimit)
					.setHasDealTray(rules.hasDealTray)
					.setHasDiscardTray(rules.hasDiscardTray)
					.setHasDeclareTray(rules.hasDeclareTray)
					.setHasBustTray(rules.hasBustTray)
					.setHasBurnTray(rules.hasBurnTray)
					.setHasCommunityTray(rules.hasCommunityTray)
					.setHasJokerTray(rules.hasJokerTray)
					.setJokerType(rules.jokerType)
					.setDisableAudioVideo(rules.disableAudioVideo)
					.setHideAvControls(rules.disableAVControls)
				);
			DEBUG.log('INSERT GAME DEF - ', request.toObject());

			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const response = await gameServiceClient.insertGameDefinition(request, {
					'Authorization': 'Bearer ' + state.token
				});
				const gameDefId = response.getId();
				if (!gameDefId) {
					throw "Unable to create game definition"
				}

				commit('CLEARERROR')
				return gameDefId;
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async updateGameDefinition({ commit, state }, gameDef) {
			DEBUG.log('UPDATE GAME DEF - ', gameDef);

			const { environment, rules } = gameDef;

			const request = new GameDefinition()
				.setId(gameDef.id)
				.setName(gameDef.name)
				.setIsPublished(gameDef.isPublished)
				.setGameType(gameDef.gameType)
				.setGameVariant(gameDef.gameVariant)
				.setCurrency(gameDef.currency)
				.setEnvironment(new GameDefinition.GameEnvironment()
					.setRoomType(environment.roomType)
					.setTableType(environment.tableType)
					.setCardType(environment.cardType)
				)
				.setRules(new GameDefinition.GameRules()
					.setPacksPerGame(rules.packsPerGame)
					.setAutoCalculatePacks(rules.autoCalculatePacks)
					.setPictureJokersPerPack(rules.pictureJokersPerPack)
					.setMaxPlayers(rules.maxPlayers)
					.setMustSeatDeal(rules.mustSeatDeal)
					.setCardsPerHand(rules.cardsPerHand)
					.setMaxCardsPerHand(rules.maxCardsPerHand < rules.cardsPerHand ? 
													rules.cardsPerHand : rules.maxCardsPerHand)
					.setMaxSwap(rules.maxSwap)
					.setShowHandOnTable(rules.showHandOnTable)
					.setShuffleMode(rules.shuffleMode)
					.setManualDealMode(rules.manualDealMode)
					.setAutoDealMode(rules.autoDealMode)
					.setIsOpenTable(rules.isOpenTable)
					.setHasPot(rules.hasPot)
					.setMinPot(rules.minPot)
					.setMaxPot(rules.maxPot)
					.setAnte(rules.ante)
					.setDealerBoot(rules.dealerBoot)
					.setCompulsoryBlind(rules.compulsoryBlind)
					.setHiLoType(rules.hiLoType)
					.setMaxBlinds(rules.maxBlinds)
					.setSalaamiAceTrail(rules.salaamiAceTrail)
					.setSalaamiPictureTrail(rules.salaamiPictureTrail)
					.setSalaamiTrail(rules.salaamiTrail)
					.setBettingLimit(rules.bettingLimit)
					.setHasDealTray(rules.hasDealTray)
					.setHasDiscardTray(rules.hasDiscardTray)
					.setHasDeclareTray(rules.hasDeclareTray)
					.setHasBustTray(rules.hasBustTray)
					.setHasBurnTray(rules.hasBurnTray)
					.setHasCommunityTray(rules.hasCommunityTray)
					.setHasJokerTray(rules.hasJokerTray)
					.setJokerType(rules.jokerType)
					.setDisableAudioVideo(rules.disableAudioVideo)
					.setHideAvControls(rules.disableAVControls)
				);
			DEBUG.log('UPDATE GAME DEF - ', request.toObject());

			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const response = await gameServiceClient.updateGameDefinition(request, {
					'Authorization': 'Bearer ' + state.token
				});
				const gameDefId = response.getId();
				if (!gameDefId) {
					throw "Unable to create game definition"
				}

				commit('CLEARERROR')
				return gameDefId;
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},

		// admin
		async getGames({ dispatch }, { nextPageToken }) {
			var request = new GetGamesRequest();
			if (nextPageToken) {
				request = request.setPageToken(new StringValue().setValue(nextPageToken));
			}
			const response = await dispatch('genericInvoke', { method: gameServiceClient.getGames, request });
			return {
				nextPageToken: response.getNextPageToken()?.getValue(),
				games: response.getGamesList().map(g => g.toObject())
			};
		},

		async getActiveGames({ dispatch }, { gameType, nextPageToken }) {
			try{
				var request = new GetActiveGamesRequest();
				if (!(nextPageToken===undefined)) {
					request.setPageToken(new StringValue().setValue(nextPageToken));
				}
				request.setGameType(gameType);
				const response = await dispatch('genericInvoke', { method: gameServiceClient.getActiveGames, request });
				return {
					nextPageToken: response.getNextPageToken()?.getValue(),
					games: response.getGamesList().map(g => g.toObject())
				};
			}catch(err){
				console.log("index.ts - ERROR! getActiveGames : ",err);
			}
		},
		async getAllGames({ dispatch }, { nextPageToken }) {
			var request = new GetGamesRequest();
			if (nextPageToken) {
				request = request.setPageToken(new StringValue().setValue(nextPageToken));
			}
			const response = await dispatch('genericInvoke', { method: gameServiceClient.getAllGames, request });
			return {
				nextPageToken: response.getNextPageToken()?.getValue(),
				games: response.getGamesList().map(g => g.toObject())
			};
		},
		async getMyOngoingGames({ dispatch }, { nextPageToken }) {
			var request = new GetGamesRequest();
			if (!(nextPageToken===undefined)) {
				request = request.setPageToken(new StringValue().setValue(nextPageToken));
			}
			const response = await dispatch('genericInvoke', { method: gameServiceClient.getMyOnGoingGames, request });
			return {
				nextPageToken: response.getNextPageToken()?.getValue(),
				games: response.getGamesList().map(g => g.toObject())
			};
		},
		async getMyOwnedGames({ dispatch }, { nextPageToken }) {
			var request = new GetGamesRequest();
			if (nextPageToken) {
				request = request.setPageToken(new StringValue().setValue(nextPageToken));
			}
			const response = await dispatch('genericInvoke', { method: gameServiceClient.getMyOwnedGames, request });
			return {
				nextPageToken: response.getNextPageToken()?.getValue(),
				games: response.getGamesList().map(g => g.toObject())
			};
		},
		async getMeAsParticipantGames({ dispatch }, { participantState, nextPageToken }) {
			const pstate = participantState == 'PLAYING' ? ParticipantStates.PLAYING : ParticipantStates.INVITED;
			var request = new GetGamesRequest().setParticipantState(pstate);
			if (nextPageToken) {
				request = request.setPageToken(new StringValue().setValue(nextPageToken));
			}
			const response = await dispatch('genericInvoke', { method: gameServiceClient.getGames, request });
			return {
				nextPageToken: response.getNextPageToken()?.getValue(),
				games: response.getGamesList().map(g => g.toObject())
			};
		},
		async SelfInvite({ commit, state }, gameID){
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new SelfInviteRequest()
					.setGameId(gameID);
					
				const response = await gameServiceClient.selfInvite(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to join game"
				}
				commit('CLEARERROR')
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async joinGame({ commit, state }, otpInfo) {
			
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const { OTP } = otpInfo;

				const request = new JoinGameRequest()
					.setOtp(OTP)
					;

				const response = await gameServiceClient.joinGame(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to join game"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : joinGame");
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async getGame({ commit, state }, id) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new GameId()
					.setId(id);
				
				console.log("getGame get gamestate for",id);
				const response = await gameServiceClient.getGameState(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to get game state"
				}
				console.log("Got gamestate for game ",id);
				console.log("Calling processGameState from : getGame",GameStateHelpers.getFuncName());
				const returnObj = GameStateHelpers.processGameState(response.toObject())

				commit('UPDATE_GAME_STATE', returnObj)
				commit('UPDATE_GAME_DEF', returnObj.gameDef)
				commit('CLEARERROR')
				return returnObj;
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async getGameEx({ commit, state }, id) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new GameId()
					.setId(id);

				const response = await gameServiceClient.getGameStateEx(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to get game state"
				}
				console.log("Calling processGameState from : getGameEx",GameStateHelpers.getFuncName());
				const returnObj = GameStateHelpers.processGameState(response.toObject())

				commit('UPDATE_GAME_STATE', returnObj)
				commit('UPDATE_GAME_DEF', returnObj.gameDef)
				commit('CLEARERROR')
				return returnObj;
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async notifyGameState({ commit, state }, id) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new GameId()
					.setId(id);

				const response = await gameServiceClient.notifyGameState(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to get game state"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : notifyGameState",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async toggleGameStateDebug({ commit, state }, { id, enableDebug }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new ToggleGameStateDebugRequest()
					.setId(id)
					.setEnableDebug(enableDebug);

				const response = await gameServiceClient.toggleGameStateDebug(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to get game state"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : toggleGameStateDebug",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async toggleGameStateVideo({ commit, state }, { id, enableVideo }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new ToggleGameStateVideoRequest()
					.setId(id)
					.setEnableVideo(enableVideo);

				const response = await gameServiceClient.toggleGameStateVideo(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to set video flag"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : toggleGameStateVideo",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},

		async inviteParticipant({ commit, state }, participantInfo) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const { id, email, nickName } = participantInfo;
				console.log("index.ts:InviteParticipant for ",participantInfo);
		
				let request = new InviteParticipantRequest()
					.setGameId(new GameId().setId(id))
					.setEmail(email)
					.setNickName(nickName);
				console.log("index.ts:InviteParticipant with request ",request);

				const response = await gameServiceClient.inviteParticipantToGame(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to invite participants"
				}

				commit('CLEARERROR')
				return response.toObject();
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async updateParticipantState({ commit, state }, { gameId, playerId, participantState }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const participantStateType: ParticipantStates = participantState as ParticipantStates;
				const request = new UpdateParticipantStateRequest()
					.setGameId(new GameId().setId(gameId))
					.setPlayerId(playerId)
					.setParticipantState(participantStateType)
					;
				const response = await gameServiceClient.updateParticipantState(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to update participant"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : updateParticipantState",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async updateParticipantHandState({ commit, state }, { gameId, playerId, participantState }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const participantStateType: ParticipantHandStates = participantState as ParticipantHandStates;
				const request = new UpdateParticipantHandStateRequest()
					.setGameId(new GameId().setId(gameId))
					.setPlayerId(playerId)
					.setParticipantHandState(participantStateType)
					;
				const response = await gameServiceClient.updateParticipantHandState(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to update participant"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : updateParticipantHandState",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async updateJokerType({ commit, state }, { gameId, playerId, jokerTypeValue }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const jokerType: JokerTypes = jokerTypeValue as JokerTypes;
				const request = new UpdateJokerTypeRequest()
					.setGameId(new GameId().setId(gameId))
					.setPlayerId(playerId)
					.setJokerType(jokerType)
					;
					
				const response = await gameServiceClient.updateJokerType(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to update joker type"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : updateJokerType",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async getMyGameVideoToken({ commit, state }, { gameId }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				var request = new GameId()
					.setId(gameId);

				const response = await gameServiceClient.getMyGameVideoToken(request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
				return response.toObject();
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},

		async createGame({ commit, state }, id) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new GameDefId()
					.setId(id);

				const response = await gameServiceClient.createGame(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to create game"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : createGame",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async resetGame({ commit, state }, id) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new GameId()
					.setId(id);

				const response = await gameServiceClient.resetGame(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to initialize game"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : resetGame",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async startGame({ commit, state }, id) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new GameId()
					.setId(id);

				const response = await gameServiceClient.startGame(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to start game"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : startGame",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async startGameRound({ commit, state }, {gameId, gameDefId}) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const request = new StartGameRoundRequest() 
                    .setGameId(gameId) 
                    .setGameDefId(gameDefId); 

				const response = await gameServiceClient.startGameRound(request, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!response) {
					throw "Unable to start game round"
				}

				commit('CLEARERROR')
				console.log("Calling processGameState from : startGameRound",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(response.toObject());
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async closedPlayerAction_Generic({ commit, state }, { request, gameId, playerId }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const args = request
					.setGameId(gameId)
					.setPlayerId(playerId)
					;
					console.log("closedPlayerAction_Generic ", args);
				const aresponse = await gameServiceClient.doClosedPlayerAction(args, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!aresponse) {
					throw "Unable to do an action in the game"
				}

				commit('CLEARERROR')
				const gameState = aresponse.toObject();
				console.log("Calling processGameState from : closedPlayerAction_Generic",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(gameState);
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async openTableAction_Generic({ commit, state }, { request, gameId }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const args = request
					.setGameId(gameId)
					;

				const aresponse = await gameServiceClient.doOpenTableAction(args, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!aresponse) {
					throw "Unable to do an action in the game"
				}

				commit('CLEARERROR')
				const gameState = aresponse.toObject();
				console.log("Calling processGameState from : openTableAction_Generic",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(gameState);
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async closedAction_PlayerHandShareCardGroupArguments({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setPlayerHandShareCardGroupArguments(new DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments()
					.setGroup(args.group)
					.setSharedWithAll(args.sharedWithAll)
					.setSharedWithList(args.sharedWith)
					.setRemove(args.remove? args.remove : false)
				)
			});
		},
		async closedAction_ShuffleClosedTray({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setShuffleClosedTrayArguments(new DoClosedPlayerActionRequest.ShuffleClosedTrayArguments())
			});
		},
		async closedAction_DealClosedTray({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setDealClosedTrayArguments(new DoClosedPlayerActionRequest.DealClosedTrayArguments())
			});
		},
		async closedAction_DealClosedTrayAdditional({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setDealClosedTrayAdditionalArguments(new DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments()
					.setIsFaceUp(args.isFaceUp)
					.setNoOfCards(args.noOfCards)
				)
			});
		},
		async closedAction_MoveCard({ dispatch }, args) {
			const sourceTrayType: TrayTypes = args.sourceTrayType as TrayTypes;
			const targetTrayType: TrayTypes = args.targetTrayType as TrayTypes;
			let actionArgs = new DoClosedPlayerActionRequest.MoveCardArguments()
				.setSourceTrayType(sourceTrayType)
				.setTargetTrayType(targetTrayType);

			if (args.sourceCardId) {
				actionArgs = actionArgs.setSourceCardId(new StringValue().setValue(args.sourceCardId))
			}
			else {
				actionArgs = actionArgs.setSourceCardIndex(new Int32Value().setValue(args.sourceCardIndex))
			}
			if (args.targetGroupName) {
				actionArgs = actionArgs.setTargetGroupName(new StringValue().setValue(args.targetGroupName))
			}

			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setMoveCardArguments(actionArgs)
			});
		},
		async closedAction_MoveStage({ dispatch }, args) {
			const tableState: TableStates = args.tableState as TableStates;
			let actionArgs = new DoClosedPlayerActionRequest.MoveStageArguments()
				.setStage(tableState);
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setMoveStageArguments(actionArgs)
			});
		},
		async closedAction_MoveNext({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setMoveNextArguments(new DoClosedPlayerActionRequest.MoveNextArguments())
			});
		},
		async closedAction_DeclareShow({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setDeclareShowArguments(new DoClosedPlayerActionRequest.DeclareShowArguments())
			});
		},
		async closedAction_DeclareChallenge({ dispatch }, args) {
			const { hasWon, closeGame, score } = args;
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setDeclareChallengeArguments(new DoClosedPlayerActionRequest.DeclareChallengeArguments()
					.setHasWon(hasWon)
					.setScore(score)
				)
			});
		},
		async closedAction_DeclareScore({ dispatch }, args) {
			const { forPlayerId, score } = args;
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setDeclareScoreArguments(new DoClosedPlayerActionRequest.DeclareScoreArguments()
					.setForPlayerId(forPlayerId)
					.setScore(score))
			});
		},
		async closedAction_DeclareWinningAmount({ dispatch }, args) {
			const { forPlayerId, amountWon } = args;
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setDeclareWinningAmountArguments(new DoClosedPlayerActionRequest.DeclareWinningAmountArguments()
					.setForPlayerId(forPlayerId)
					.setAmountWon(amountWon))
			});
		},
		async closedAction_CompleteRound({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setEndSessionArguments(new DoClosedPlayerActionRequest.EndSessionArguments())
			});
		},
		async closedAction_Fold({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setFoldHandArguments(new DoClosedPlayerActionRequest.FoldHandArguments())
			});
		},
		async closedAction_gameAction({ dispatch }, args) {
			console.log("closedAction_gameAction with args:",args)
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setGameActionArguments(new DoClosedPlayerActionRequest.GameActionArguments()
																							.setActionId(args.actionId)
																							.setGameId(args.gameId)
																							.setGameDefId(args.gameDefId)
																							.setBetAmount(args.betAmount)
																					)
			});
		},
		async closedAction_ShowToAll({ dispatch }, args) {
			return await dispatch('closedPlayerAction_Generic', {
				...args,
				request: new DoClosedPlayerActionRequest().setShowToAllArguments(new DoClosedPlayerActionRequest.ShowToAllArguments())
			});
		},

		async openPlayerAction_Generic({ commit, state }, { request, gameId, playerId }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				const args = request
					.setGameId(gameId)
					.setPlayerId(playerId);

				const aresponse = await gameServiceClient.doOpenPlayerAction(args, {
					'Authorization': 'Bearer ' + state.token
				});
				if (!aresponse) {
					throw "Unable to do an action in the game"
				}

				commit('CLEARERROR');
				const gameState = aresponse.toObject();
				console.log("Calling processGameState from : openPlayerAction_Generic",GameStateHelpers.getFuncName());
				return GameStateHelpers.processGameState(gameState);
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async openAction_Rearrange({ dispatch }, args) {
			const rargs = new DoOpenPlayerActionRequest.RearrangeHandArguments();
			args.groups.forEach(g => {
				const group = new GroupedCardId().setGroup(g.group);
				rargs.addGroups(group);
				g.cards.forEach(c => {
					group.addCardIds(c.id);
				});
			});
			return await dispatch('openPlayerAction_Generic', {
				...args,
				request: new DoOpenPlayerActionRequest().setRearrangeHandArguments(rargs)
			});
		},
		async openAction_MeldBySuit({ dispatch }, args) {
			return await dispatch('openPlayerAction_Generic', {
				...args,
				request: new DoOpenPlayerActionRequest().setMeldPlayerHandBySuitArguments(new DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments())
			});
		},
		async openAction_MeldByValue({ dispatch }, args) {
			return await dispatch('openPlayerAction_Generic', {
				...args,
				request: new DoOpenPlayerActionRequest().setMeldPlayerHandByValueArguments(new DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments())
			});
		},
		async openTableAction_Score_Recalculate({ dispatch }, args) {
			return await dispatch('openTableAction_Generic', {
				...args,
				request: new DoOpenTableActionRequest().setScoreRecalculateArguments(new DoOpenTableActionRequest.Score_RecalculateArguments())
			});
		},
		async openAction_UnblindHandCards({ dispatch }, args) {
			return await dispatch('openPlayerAction_Generic', {
				...args,
				request: new DoOpenPlayerActionRequest().setUnblindHandArguments(new DoOpenPlayerActionRequest.UnblindHandArguments())
			});
		},
		async openAction_AddToPot({ dispatch }, args) {
			const rargs = new DoOpenPlayerActionRequest.AddToPotArguments();
			rargs.setAmount(args.amount)
			return await dispatch('openPlayerAction_Generic', {
				...args,
				request: new DoOpenPlayerActionRequest().setAddToPotArguments(rargs)
			});
		},
		async openAction_TopupCurrency({ dispatch }, args) { // TODO this would happen from server side... next from client...
			console.log("openAction_TopupCurrency:: buying currency ",args.amopunt);
			const rargs = new DoOpenPlayerActionRequest.TopupCurrencyArguments();
			rargs.setAmount(args.amount)
			return await dispatch('openPlayerAction_Generic', {
				...args,
				request: new DoOpenPlayerActionRequest().setTopUpCurrencyArguments(rargs)
			});
		},
		async openAction_BuyInCurrency({ dispatch }, args) {
			console.log("openAction_BuyInCurrency:: buying currency ",args.amopunt);
			const rargs = new DoOpenPlayerActionRequest.BuyInCurrencyArguments();
			rargs.setAmount(args.amount)
			return await dispatch('openPlayerAction_Generic', {
				...args,
				request: new DoOpenPlayerActionRequest().setBuyInCurrencyArguments(rargs)
			});
		},

		async openAction_ChangeVariant({ dispatch }, args) {
			const rargs = new DoOpenPlayerActionRequest.ChangeVariantArguments();
			rargs.setVariant(args.variant)
			rargs.setHighOrLow(args.highOrLow)
			rargs.setJokerPresent(args.jokerPresent)
			rargs.setBustPresent(args.bustPresent)
			rargs.setBettingLimit(args.bettingLimit)
			return await dispatch('openPlayerAction_Generic', {
				...args,
				request: new DoOpenPlayerActionRequest().setChangeVariantArguments(rargs)
			});
		},

		async getDefaultSortedCards({ commit, state }, { gameId }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				var request = new GameId()
					.setId(gameId);

				const response = await gameServiceClient.getDefaultSortedCards(request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
				return response.toObject().cardsList;
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async getSeatPickSortedCards({ commit, state }, { gameId }) {
			commit('CHANGELOADINGSTATE', { isprocessing: true, processingpercent: 10 });
			try {
				var request = new GameId()
					.setId(gameId);

				const response = await gameServiceClient.getSeatPickSortedCards(request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
				return response.toObject().cardsList;
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async getSeatPickParticipantsOrder({ dispatch, state }, { gameId }) {
			var request = new GameId()
				.setId(gameId);
			var response = await dispatch('genericInvoke', { method: gameServiceClient.getSeatPickParticipantsOrder, request });
			return response.toObject().participantsList;
		},

		// manage
		async addUserRole({ dispatch }, { id, roleId }) {
			var request = new UserRoleRequest()
				.setUserId(id)
				.setRoleId(roleId);
			return (await dispatch('genericInvoke', { method: accountServiceClient.addRoleToUser, request })).toObject().user;
		},
		async removeUserRole({ dispatch }, { id, roleId }) {
			var request = new UserRoleRequest()
				.setUserId(id)
				.setRoleId(roleId);
			return (await dispatch('genericInvoke', { method: accountServiceClient.removeRoleFromUser, request })).toObject().user;
		},
		async addUserClaim({ dispatch }, { id, claimType }) {
			var request = new UserClaimRequest()
				.setUserId(id)
				.setClaimType(claimType);
			return (await dispatch('genericInvoke', { method: accountServiceClient.addClaimToUser, request })).toObject().user;
		},
		async removeUserClaim({ dispatch }, { id, claimType }) {
			var request = new UserClaimRequest()
				.setUserId(id)
				.setClaimType(claimType);
			return (await dispatch('genericInvoke', { method: accountServiceClient.removeClaimFromUser, request })).toObject().user;
		},

		// diagnostics
		async diagnosticsGetServerEnvironmentInfo({ commit, state }) {
			try {
				const response = await diagnosticsServiceClient.getEnvironmentInfo(new Empty(), {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
				return response.toObject();
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async diagnosticsGetServerEnvironmentInfo2({ commit, state }) {
			try {
				const response = await diagnosticsServiceClient.getEnvironmentInfo2(new Empty(), {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
				return response.toObject();
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async diagnosticsSendMail({ commit, state }, mailInfo) {
			try {
				const { subject, body, to, cc, bcc } = mailInfo

				const request = new SendMailRequest()
					.setSubject(subject)
					.setBody(body)
					.setIsbodyhtml(true)
					.setToList(splitEmailIds(to));

				await diagnosticsServiceClient.sendMail(request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async diagnosticsSendSignalRMessage2({ commit, state }) {
			try {
				await diagnosticsServiceClient.sendSignalRMessage2(new Empty(), {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async diagnosticsSendSignalRMessage({ commit, state }) {
			try {
				await diagnosticsServiceClient.sendSignalRMessage(new Empty(), {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async diagnosticsSetCacheValue({ commit, state }, info) {
			try {
				const { key, value, expiresInSeconds } = info;
				const request = new SetCacheValueRequest()
					.setKey(key)
					.setValue(value)
					.setExpiresInSeconds(expiresInSeconds);

				await diagnosticsServiceClient.setCacheValue(request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR');
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async diagnosticsGetCacheValue({ commit, state }, info) {
			try {
				const { key } = info;
				const request = new GetCacheValueRequest()
					.setKey(key);

				const response = await diagnosticsServiceClient.getCacheValue(request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
				return response.toObject();
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
		async diagnosticsGetCacheValue2({ commit, state }, info) {
			try {
				const { key } = info
				const request = new GetCacheValueRequest()
					.setKey(key)

				const response = await diagnosticsServiceClient.getCacheValue2(request, {
					'Authorization': 'Bearer ' + state.token
				});

				commit('CLEARERROR')
				return response.toObject();
			}
			catch (error) {
				const nerror = wrapError(error)
				commit('SETERROR', nerror)
				throw nerror
			}
			finally {
				commit('CHANGELOADINGSTATE', { isprocessing: false });
			}
		},
	},
	mutations: {
		// AUTH
		AUTH_REQUEST(state) {
			DEBUG.log('STORE:MUTATION:AUTH_REQUEST - State=%o', state)
			state.isprocessing = true
			state.haserror = false
			state.error = null
		},
		AUTH_SUCCESS(state, userinfo) {
			const token = userinfo.token
			const rtoken = userinfo.rtoken
			const user = userinfo.currentuser

			//DEBUG.log('STORE:MUTATION:AUTH_SUCCESS - RToken=%o Token=%o User=%o', rtoken, token, user)
			state.haserror = false
			state.isprocessing = false
			state.error = null
			state.token = token
			state.rtoken = rtoken
			state.userinfo = user
		},
		AUTH_ERROR(state, error) {
			DEBUG.log('STORE:MUTATION:AUTH_ERROR - Error=%o', error)
			state.isprocessing = false
			state.error = error
			state.haserror = error != null
		},
		AUTH_LOGOUT(state) {
			DEBUG.log('STORE:MUTATION:AUTH_LOGOUT')
			state.haserror = false
			state.isprocessing = false
			state.error = null
			state.rtoken = null
			state.token = null
			state.userinfo = null
		},

		UPDATE_GAME_STATE(state, gameObj) {
			state.gameState = gameObj
		},

		UPDATE_USER_WALLET(state, userWalletObj) {
			state.userWallet = userWalletObj
		},

		UPDATE_TABLE_MESSAGE(state, { tableMessageObj, autoHide }) {
			state.tableMessage = tableMessageObj
			if (autoHide) {
				setTimeout(function () {
					state.tableMessage = ''
				}, 10000);
			}

		},

		UPDATE_GAME_DEFS(state, gameDefs) {
			state.allGameDefs = gameDefs
		},		
		UPDATE_GAME_DEF(state, gameDefObj) {
			state.gameDef = gameDefObj
		},
		// COMMON Loading
		CHANGELOADINGSTATE(state, { isprocessing, processingpercent }) {
			state.isprocessing = isprocessing
			state.processingpercent = isprocessing ? processingpercent : 0
		},
		CHANGEPROCESINGPERCENT(state, processingpercent) {
			state.processingpercent = processingpercent
		},
		CLEARERROR(state) {
			state.error = null
			state.haserror = false
			state.processingpercent = 0
			state.isprocessing = false
		},
		SETERROR(state, error) {
			const errorString = (typeof error !== 'string') ? error.message : error
			state.error = errorString
			state.haserror = errorString != null && errorString.length > 0
			state.processingpercent = 0
			state.isprocessing = false
		},
	},
})

export default store