function splitEmailIds(emailids: string) {
	return emailids ? emailids.split(/[,;]/ig) : []
}

function getNumberWithOrdinal(n) {
	var s = ["th", "st", "nd", "rd"],
		v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
function getEnumKeyFromVal(enums, val){
	return Object.keys(enums).find(
			key => enums[key] ===  val
		);
}
export {
	splitEmailIds,
	getNumberWithOrdinal,
	getEnumKeyFromVal
}