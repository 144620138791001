import Vue from 'vue'
import VueRouter, { RouteConfig } from "vue-router";

import { ROLESLOOKUPBYNAME, CLAIMSLOOKUPBYNAME } from '@/common/constants'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		redirect: '/home',
		component: () => import('@/views/sitetemplate.vue'),
		children: [
			{
				path: 'register',
				name: 'register',
				component: () => import('@/views/register.vue'),
				meta: {
					requiresAuth: false,
					ignoreRedirect: true,
				}
			},
			{
				path: 'login',
				name: 'login',
				component: () => import('@/views/login.vue'),
				meta: {
					requiresAuth: false,
					ignoreRedirect: true,
				}
			},
			{
				path: 'joingame',
				name: 'joingame',
				component: () => import('@/views/joingame.vue'),
				meta: {
					requiresAuth: false,
					ignoreRedirect: true,
				}
			},
			{
				path: 'passwordreset',
				name: 'passwordreset',
				component: () => import('@/views/passwordreset.vue'),
				meta: {
					requiresAuth: false,
					ignoreRedirect: true,
				}
			},
			{
				path: 'logout',
				name: 'logout',
				component: () => import('@/views/logout.vue'),
				meta: {
					ignoreRedirect: true,
				}
			},
			{
				path: 'diagnostics',
				name: 'diagnostics',
				component: () => import('@/views/diagnostics.vue'),
				meta: {
					requiresAuth: true,
					requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
					requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator, CLAIMSLOOKUPBYNAME.isgamecreator],
				}
			},
		]
	},

	{
		path: '/home',
		name: 'home',
		component: () => import('@/views/home.vue'),
		meta: {
			requiresAuth: true,
			ignoreRedirect: true,
		}
	},
	{
		path: '/create-room/:id?',
		name: 'create-room',
		component: () => import('@/views/create-room.vue'),
		meta: {
			requiresAuth: true,
//			requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
//			requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator, CLAIMSLOOKUPBYNAME.isgamehost, CLAIMSLOOKUPBYNAME.isgamecreator],
		}
	},
	{
		path: '/otp',
		name: 'otp',
		component: () => import('@/views/otp.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/game/:id/play',
		name: 'play',
		component: () => import('@/views/game/play.vue'),
		meta: {
			requiresAuth: true
		}
	},

	{
		path: '/manage',
		redirect: '/manage/users',
		component: () => import('@/views/sidebartemplate.vue'),
		children: [
			{
				path: 'gamedefinitions/my',
				name: 'managegamedefinitions',
				component: () => import('@/views/manage/gamedefinitions.vue'),
				meta: {
					requiresAuth: true,
					requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
					requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator],
				}
			},
			{
				path: 'gamedefinitions/published',
				name: 'managepublishedgamedefinitions',
				component: () => import('@/views/manage/publishedgamedefinitions.vue'),
				meta: {
					requiresAuth: true,
					requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
					requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator],
				}
			},
			{
				path: 'games/all',
				name: 'managegames',
				component: () => import('@/views/manage/games.vue'),
				meta: {
					requiresAuth: true,
					requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
					requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator],
				}
			},
			{
				path: 'games/myowned',
				name: 'managemyownedgames',
				component: () => import('@/views/manage/myownedgames.vue'),
				meta: {
					requiresAuth: true,
				}
			},
			{
				path: 'games/invited',
				name: 'manageinvitedgames',
				component: () => import('@/views/manage/invitedgames.vue'),
				meta: {
					requiresAuth: true,
				}
			},
			{
				path: 'games/joined',
				name: 'managejoinedgames',
				component: () => import('@/views/manage/joinedgames.vue'),
				meta: {
					requiresAuth: true,
					requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
					requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator],
				}
			},
			{
				path: 'game/:id/play',
				name: 'managegame',
				component: () => import('@/views/manage/game.vue'),
				meta: {
					requiresAuth: true,
					requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
					requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator],
				}
			},
			{
				path: 'users',
				name: 'manageusers',
				component: () => import('@/views/manage/users.vue'),
				meta: {
					requiresAuth: true,
					requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
					requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator],
				}
			},
			{
				path: 'user/:id',
				name: 'manageuser',
				component: () => import('@/views/manage/user.vue'),
				meta: {
					requiresAuth: true,
					requiresAnyRole: [ROLESLOOKUPBYNAME.administrator],
					requiresAnyClaim: [CLAIMSLOOKUPBYNAME.isadministrator],
				}
			},
		]
	},

	// top level - * match
	{
		path: "*",
		component: () => import('@/views/sitetemplate.vue'),
		children: [
			{
				name: 'error_403',
				path: '/error_403',
				component: () => import('@/views/error_403.vue'),
			},
			{
				name: 'error_404',
				path: '/error_404',
				component: () => import('@/views/error_404.vue'),
			},
			{
				name: 'error_500',
				path: '/error_500',
				component: () => import('@/views/error_500.vue'),
			},
			{
				name: 'error_404_fallback',
				path: '*',
				component: () => import('@/views/error_404.vue'),
			},
		]
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router