import { getKeyByValue } from '@/utils'
import {
	ParticipantStates, ParticipantHandStates,
	GameStates, TableStates,
} from '@/grpcservices/Game_pb';

export default class GameStateHelpers {
	static hasRole(userinfo, roleId) {
		return userinfo?.roles?.find(r => r.id == roleId) != null
	}

	static hasClaim(userinfo, claimName) {
		return userinfo?.claims?.find(c => c.name == claimName) != null
	}

	static renameProperty(source, propertyName, renameToName) {
		if (propertyName in source) {
			source[renameToName] = source[propertyName]
			delete source[propertyName]
		}
		return source;
	}

	static recursivelyProcessListProperty(obj) {
		for (var property in obj) {
			if (obj.hasOwnProperty(property)) {
				const propValue = obj[property];
				if (propValue) {
					// flatten .value
					if (typeof propValue == "object") {
						if (propValue.hasOwnProperty('value') && Object.getOwnPropertyNames(propValue).length == 1) {
							obj[property] = propValue.value;
						}
						else {
							GameStateHelpers.recursivelyProcessListProperty(propValue);
						}
					}

					if (property.endsWith('List')) {
						obj = GameStateHelpers.renameProperty(obj, property, property.substr(0, property.length - 4));
					}
				}
			}
		}
		return obj;
	}

	static processGameState(game) {
		console.log("GameStateHelper::processGameState - State:",game.state," Version:", game.version);
		game.state_ = getKeyByValue(GameStates, game.state);
		game.tableState_ = getKeyByValue(TableStates, game.tableState);
		game.previousTableState_ = game.previousTableState ? getKeyByValue(TableStates, game.previousTableState) : null;

		game = GameStateHelpers.recursivelyProcessListProperty(game)

		if (game.participants) {
			game.participants.forEach(participant => {
				participant.state_ = getKeyByValue(ParticipantStates, participant.state);
				if (participant.hand) {
					participant.hand.state_ = getKeyByValue(ParticipantHandStates, participant.hand.state);
				}
				participant.focussed = false;
			})
		}

		console.log("GameStateHelper::processGameState - New State:",game);
		return game;
	}

	static getFuncName()
	{
		return "";//this.caller.name;
	}
}

