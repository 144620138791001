/**
 * @fileoverview gRPC-Web generated client stub for services
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.services = require('./Authentication_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.services.AuthenticationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.services.AuthenticationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.RegisterRequest,
 *   !proto.services.RegisterResponse>}
 */
const methodDescriptor_AuthenticationService_Register = new grpc.web.MethodDescriptor(
  '/services.AuthenticationService/Register',
  grpc.web.MethodType.UNARY,
  proto.services.RegisterRequest,
  proto.services.RegisterResponse,
  /**
   * @param {!proto.services.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.services.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AuthenticationServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AuthenticationService/Register',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_Register,
      callback);
};


/**
 * @param {!proto.services.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.services.AuthenticationServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AuthenticationService/Register',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.LoginRequest,
 *   !proto.services.LoginResponse>}
 */
const methodDescriptor_AuthenticationService_Login = new grpc.web.MethodDescriptor(
  '/services.AuthenticationService/Login',
  grpc.web.MethodType.UNARY,
  proto.services.LoginRequest,
  proto.services.LoginResponse,
  /**
   * @param {!proto.services.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.services.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AuthenticationServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AuthenticationService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_Login,
      callback);
};


/**
 * @param {!proto.services.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.services.AuthenticationServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AuthenticationService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.ResendCodeRequest,
 *   !proto.services.ResendCodeResponse>}
 */
const methodDescriptor_AuthenticationService_ResendCode = new grpc.web.MethodDescriptor(
  '/services.AuthenticationService/ResendCode',
  grpc.web.MethodType.UNARY,
  proto.services.ResendCodeRequest,
  proto.services.ResendCodeResponse,
  /**
   * @param {!proto.services.ResendCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.ResendCodeResponse.deserializeBinary
);


/**
 * @param {!proto.services.ResendCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.ResendCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.ResendCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AuthenticationServiceClient.prototype.resendCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AuthenticationService/ResendCode',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_ResendCode,
      callback);
};


/**
 * @param {!proto.services.ResendCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.ResendCodeResponse>}
 *     Promise that resolves to the response
 */
proto.services.AuthenticationServicePromiseClient.prototype.resendCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AuthenticationService/ResendCode',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_ResendCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.IsValidGameOTPRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AuthenticationService_IsValidGameOTP = new grpc.web.MethodDescriptor(
  '/services.AuthenticationService/IsValidGameOTP',
  grpc.web.MethodType.UNARY,
  proto.services.IsValidGameOTPRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.IsValidGameOTPRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.IsValidGameOTPRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AuthenticationServiceClient.prototype.isValidGameOTP =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AuthenticationService/IsValidGameOTP',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_IsValidGameOTP,
      callback);
};


/**
 * @param {!proto.services.IsValidGameOTPRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AuthenticationServicePromiseClient.prototype.isValidGameOTP =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AuthenticationService/IsValidGameOTP',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_IsValidGameOTP);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.LoginWithGameOTPRequest,
 *   !proto.services.LoginGameOTPResponse>}
 */
const methodDescriptor_AuthenticationService_LoginWithGameOTP = new grpc.web.MethodDescriptor(
  '/services.AuthenticationService/LoginWithGameOTP',
  grpc.web.MethodType.UNARY,
  proto.services.LoginWithGameOTPRequest,
  proto.services.LoginGameOTPResponse,
  /**
   * @param {!proto.services.LoginWithGameOTPRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.LoginGameOTPResponse.deserializeBinary
);


/**
 * @param {!proto.services.LoginWithGameOTPRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.LoginGameOTPResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.LoginGameOTPResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AuthenticationServiceClient.prototype.loginWithGameOTP =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AuthenticationService/LoginWithGameOTP',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_LoginWithGameOTP,
      callback);
};


/**
 * @param {!proto.services.LoginWithGameOTPRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.LoginGameOTPResponse>}
 *     Promise that resolves to the response
 */
proto.services.AuthenticationServicePromiseClient.prototype.loginWithGameOTP =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AuthenticationService/LoginWithGameOTP',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_LoginWithGameOTP);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.RefreshTokenRequest,
 *   !proto.services.RefreshTokenResponse>}
 */
const methodDescriptor_AuthenticationService_RefreshToken = new grpc.web.MethodDescriptor(
  '/services.AuthenticationService/RefreshToken',
  grpc.web.MethodType.UNARY,
  proto.services.RefreshTokenRequest,
  proto.services.RefreshTokenResponse,
  /**
   * @param {!proto.services.RefreshTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.RefreshTokenResponse.deserializeBinary
);


/**
 * @param {!proto.services.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.RefreshTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.RefreshTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AuthenticationServiceClient.prototype.refreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AuthenticationService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_RefreshToken,
      callback);
};


/**
 * @param {!proto.services.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.RefreshTokenResponse>}
 *     Promise that resolves to the response
 */
proto.services.AuthenticationServicePromiseClient.prototype.refreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AuthenticationService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_RefreshToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.PasswordResetRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AuthenticationService_PasswordReset = new grpc.web.MethodDescriptor(
  '/services.AuthenticationService/PasswordReset',
  grpc.web.MethodType.UNARY,
  proto.services.PasswordResetRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.PasswordResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.PasswordResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AuthenticationServiceClient.prototype.passwordReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AuthenticationService/PasswordReset',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_PasswordReset,
      callback);
};


/**
 * @param {!proto.services.PasswordResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AuthenticationServicePromiseClient.prototype.passwordReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AuthenticationService/PasswordReset',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_PasswordReset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.PasswordResetChallengeRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AuthenticationService_PasswordResetChallenge = new grpc.web.MethodDescriptor(
  '/services.AuthenticationService/PasswordResetChallenge',
  grpc.web.MethodType.UNARY,
  proto.services.PasswordResetChallengeRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.services.PasswordResetChallengeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.services.PasswordResetChallengeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.AuthenticationServiceClient.prototype.passwordResetChallenge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.AuthenticationService/PasswordResetChallenge',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_PasswordResetChallenge,
      callback);
};


/**
 * @param {!proto.services.PasswordResetChallengeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.services.AuthenticationServicePromiseClient.prototype.passwordResetChallenge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.AuthenticationService/PasswordResetChallenge',
      request,
      metadata || {},
      methodDescriptor_AuthenticationService_PasswordResetChallenge);
};


module.exports = proto.services;

