import buildinfo from "!webpack-plugin-buildinfo?time!";
//import buildinfo from "!webpack-plugin-buildinfo?gitHashShort&time!";

export default {
	__BUILD_TIMESTAMP__: `${new Date(
		buildinfo.time
	).toISOString()}`,

	__BUILD_HASH__: ``,//`${buildinfo.gitHashShort}`,

}