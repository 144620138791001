// source: Game.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.services.BettingLimits', null, global);
goog.exportSymbol('proto.services.BurnTray', null, global);
goog.exportSymbol('proto.services.BustTray', null, global);
goog.exportSymbol('proto.services.Card', null, global);
goog.exportSymbol('proto.services.CardGroup', null, global);
goog.exportSymbol('proto.services.ClosedPlayerActions', null, global);
goog.exportSymbol('proto.services.CommunityTray', null, global);
goog.exportSymbol('proto.services.DealModes', null, global);
goog.exportSymbol('proto.services.DealTray', null, global);
goog.exportSymbol('proto.services.DeclareTray', null, global);
goog.exportSymbol('proto.services.DiscardTray', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.ActionArgumentsCase', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.DeclareShowArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.EndSessionArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.FoldHandArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.GameActionArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.MoveCardArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.MoveCardArguments.SourceCardTypeCase', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.MoveNextArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.MoveStageArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.ShowToAllArguments', null, global);
goog.exportSymbol('proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.ActionArgumentsCase', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.AddToPotArguments', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments', null, global);
goog.exportSymbol('proto.services.DoOpenPlayerActionRequest.UnblindHandArguments', null, global);
goog.exportSymbol('proto.services.DoOpenTableActionRequest', null, global);
goog.exportSymbol('proto.services.DoOpenTableActionRequest.ActionArgumentsCase', null, global);
goog.exportSymbol('proto.services.DoOpenTableActionRequest.Score_RecalculateArguments', null, global);
goog.exportSymbol('proto.services.GameDefId', null, global);
goog.exportSymbol('proto.services.GameDefinition', null, global);
goog.exportSymbol('proto.services.GameDefinition.GameEnvironment', null, global);
goog.exportSymbol('proto.services.GameDefinition.GameRules', null, global);
goog.exportSymbol('proto.services.GameId', null, global);
goog.exportSymbol('proto.services.GameInfo', null, global);
goog.exportSymbol('proto.services.GameInfo.Participant', null, global);
goog.exportSymbol('proto.services.GameState', null, global);
goog.exportSymbol('proto.services.GameState.Action', null, global);
goog.exportSymbol('proto.services.GameState.ClientAction', null, global);
goog.exportSymbol('proto.services.GameState.Participant', null, global);
goog.exportSymbol('proto.services.GameState.SidePot', null, global);
goog.exportSymbol('proto.services.GameStates', null, global);
goog.exportSymbol('proto.services.GameType', null, global);
goog.exportSymbol('proto.services.GamesList', null, global);
goog.exportSymbol('proto.services.GetActiveGamesRequest', null, global);
goog.exportSymbol('proto.services.GetActiveGamesRequest.GametypeValueCase', null, global);
goog.exportSymbol('proto.services.GetActiveGamesResponse', null, global);
goog.exportSymbol('proto.services.GetAllGamesRequest', null, global);
goog.exportSymbol('proto.services.GetGameDefinitionsRequest', null, global);
goog.exportSymbol('proto.services.GetGameDefinitionsResponse', null, global);
goog.exportSymbol('proto.services.GetGameInfosResponse', null, global);
goog.exportSymbol('proto.services.GetGameRoundRequest', null, global);
goog.exportSymbol('proto.services.GetGameRoundsResponse', null, global);
goog.exportSymbol('proto.services.GetGamesRequest', null, global);
goog.exportSymbol('proto.services.GetGamesRequest.ParticipantstateValueCase', null, global);
goog.exportSymbol('proto.services.GetGamesResponse', null, global);
goog.exportSymbol('proto.services.GetMyOwnedGamesRequest', null, global);
goog.exportSymbol('proto.services.GroupedCardId', null, global);
goog.exportSymbol('proto.services.HiLoTypes', null, global);
goog.exportSymbol('proto.services.InviteParticipantRequest', null, global);
goog.exportSymbol('proto.services.JoinGameRequest', null, global);
goog.exportSymbol('proto.services.JokerTray', null, global);
goog.exportSymbol('proto.services.JokerTypes', null, global);
goog.exportSymbol('proto.services.OpenPlayerActions', null, global);
goog.exportSymbol('proto.services.ParticipantHandStates', null, global);
goog.exportSymbol('proto.services.ParticipantPot', null, global);
goog.exportSymbol('proto.services.ParticipantScore', null, global);
goog.exportSymbol('proto.services.ParticipantSeatPickOrderResponse', null, global);
goog.exportSymbol('proto.services.ParticipantSeatPickOrderResponse.Participant', null, global);
goog.exportSymbol('proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder', null, global);
goog.exportSymbol('proto.services.ParticipantStates', null, global);
goog.exportSymbol('proto.services.ParticipantWallet', null, global);
goog.exportSymbol('proto.services.PlayerActions', null, global);
goog.exportSymbol('proto.services.PlayerHandTray', null, global);
goog.exportSymbol('proto.services.PokerActions', null, global);
goog.exportSymbol('proto.services.Pot', null, global);
goog.exportSymbol('proto.services.Ranks', null, global);
goog.exportSymbol('proto.services.ScoreBoard', null, global);
goog.exportSymbol('proto.services.SeatPickTray', null, global);
goog.exportSymbol('proto.services.SelfInviteRequest', null, global);
goog.exportSymbol('proto.services.ShuffleModes', null, global);
goog.exportSymbol('proto.services.SortedCardGroup', null, global);
goog.exportSymbol('proto.services.StartGameRoundRequest', null, global);
goog.exportSymbol('proto.services.Suits', null, global);
goog.exportSymbol('proto.services.TableStates', null, global);
goog.exportSymbol('proto.services.ToggleGameStateDebugRequest', null, global);
goog.exportSymbol('proto.services.ToggleGameStateVideoRequest', null, global);
goog.exportSymbol('proto.services.TrayProperties', null, global);
goog.exportSymbol('proto.services.TrayTypes', null, global);
goog.exportSymbol('proto.services.UpdateJokerTypeRequest', null, global);
goog.exportSymbol('proto.services.UpdateParticipantHandStateRequest', null, global);
goog.exportSymbol('proto.services.UpdateParticipantStateRequest', null, global);
goog.exportSymbol('proto.services.Wallet', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ParticipantSeatPickOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.ParticipantSeatPickOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.services.ParticipantSeatPickOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ParticipantSeatPickOrderResponse.displayName = 'proto.services.ParticipantSeatPickOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ParticipantSeatPickOrderResponse.Participant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ParticipantSeatPickOrderResponse.Participant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ParticipantSeatPickOrderResponse.Participant.displayName = 'proto.services.ParticipantSeatPickOrderResponse.Participant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.displayName = 'proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameDefId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GameDefId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameDefId.displayName = 'proto.services.GameDefId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.StartGameRoundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.StartGameRoundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.StartGameRoundRequest.displayName = 'proto.services.StartGameRoundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetGameRoundsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GetGameRoundsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.GetGameRoundsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetGameRoundsResponse.displayName = 'proto.services.GetGameRoundsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetGameRoundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetGameRoundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetGameRoundRequest.displayName = 'proto.services.GetGameRoundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetActiveGamesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.GetActiveGamesRequest.oneofGroups_);
};
goog.inherits(proto.services.GetActiveGamesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetActiveGamesRequest.displayName = 'proto.services.GetActiveGamesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetMyOwnedGamesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetMyOwnedGamesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetMyOwnedGamesRequest.displayName = 'proto.services.GetMyOwnedGamesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetGamesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.GetGamesRequest.oneofGroups_);
};
goog.inherits(proto.services.GetGamesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetGamesRequest.displayName = 'proto.services.GetGamesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetAllGamesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetAllGamesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetAllGamesRequest.displayName = 'proto.services.GetAllGamesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetGamesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GetGamesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.GetGamesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetGamesResponse.displayName = 'proto.services.GetGamesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetActiveGamesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GetActiveGamesResponse.repeatedFields_, null);
};
goog.inherits(proto.services.GetActiveGamesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetActiveGamesResponse.displayName = 'proto.services.GetActiveGamesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ToggleGameStateDebugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ToggleGameStateDebugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ToggleGameStateDebugRequest.displayName = 'proto.services.ToggleGameStateDebugRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ToggleGameStateVideoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ToggleGameStateVideoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ToggleGameStateVideoRequest.displayName = 'proto.services.ToggleGameStateVideoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetGameDefinitionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GetGameDefinitionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetGameDefinitionsRequest.displayName = 'proto.services.GetGameDefinitionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetGameDefinitionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GetGameDefinitionsResponse.repeatedFields_, null);
};
goog.inherits(proto.services.GetGameDefinitionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetGameDefinitionsResponse.displayName = 'proto.services.GetGameDefinitionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GetGameInfosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GetGameInfosResponse.repeatedFields_, null);
};
goog.inherits(proto.services.GetGameInfosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GetGameInfosResponse.displayName = 'proto.services.GetGameInfosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.InviteParticipantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.InviteParticipantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.InviteParticipantRequest.displayName = 'proto.services.InviteParticipantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.JoinGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.JoinGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.JoinGameRequest.displayName = 'proto.services.JoinGameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SelfInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.SelfInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.SelfInviteRequest.displayName = 'proto.services.SelfInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.UpdateParticipantStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.UpdateParticipantStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.UpdateParticipantStateRequest.displayName = 'proto.services.UpdateParticipantStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.UpdateParticipantHandStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.UpdateParticipantHandStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.UpdateParticipantHandStateRequest.displayName = 'proto.services.UpdateParticipantHandStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.UpdateJokerTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.UpdateJokerTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.UpdateJokerTypeRequest.displayName = 'proto.services.UpdateJokerTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ParticipantWallet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ParticipantWallet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ParticipantWallet.displayName = 'proto.services.ParticipantWallet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GamesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GamesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GamesList.displayName = 'proto.services.GamesList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GameInfo.repeatedFields_, null);
};
goog.inherits(proto.services.GameInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameInfo.displayName = 'proto.services.GameInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameInfo.Participant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GameInfo.Participant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameInfo.Participant.displayName = 'proto.services.GameInfo.Participant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GameId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameId.displayName = 'proto.services.GameId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GameDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameDefinition.displayName = 'proto.services.GameDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameDefinition.GameEnvironment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GameDefinition.GameEnvironment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameDefinition.GameEnvironment.displayName = 'proto.services.GameDefinition.GameEnvironment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameDefinition.GameRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GameDefinition.GameRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameDefinition.GameRules.displayName = 'proto.services.GameDefinition.GameRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GameState.repeatedFields_, null);
};
goog.inherits(proto.services.GameState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameState.displayName = 'proto.services.GameState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameState.ClientAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GameState.ClientAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameState.ClientAction.displayName = 'proto.services.GameState.ClientAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameState.Participant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GameState.Participant.repeatedFields_, null);
};
goog.inherits(proto.services.GameState.Participant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameState.Participant.displayName = 'proto.services.GameState.Participant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameState.SidePot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GameState.SidePot.repeatedFields_, null);
};
goog.inherits(proto.services.GameState.SidePot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameState.SidePot.displayName = 'proto.services.GameState.SidePot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GameState.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.GameState.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GameState.Action.displayName = 'proto.services.GameState.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.TrayProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.TrayProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.TrayProperties.displayName = 'proto.services.TrayProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DealTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.DealTray.repeatedFields_, null);
};
goog.inherits(proto.services.DealTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DealTray.displayName = 'proto.services.DealTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.JokerTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.JokerTray.repeatedFields_, null);
};
goog.inherits(proto.services.JokerTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.JokerTray.displayName = 'proto.services.JokerTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DiscardTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.DiscardTray.repeatedFields_, null);
};
goog.inherits(proto.services.DiscardTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DiscardTray.displayName = 'proto.services.DiscardTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DeclareTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.DeclareTray.repeatedFields_, null);
};
goog.inherits(proto.services.DeclareTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DeclareTray.displayName = 'proto.services.DeclareTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SeatPickTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.SeatPickTray.repeatedFields_, null);
};
goog.inherits(proto.services.SeatPickTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.SeatPickTray.displayName = 'proto.services.SeatPickTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.PlayerHandTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.PlayerHandTray.repeatedFields_, null);
};
goog.inherits(proto.services.PlayerHandTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.PlayerHandTray.displayName = 'proto.services.PlayerHandTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.BurnTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.BurnTray.repeatedFields_, null);
};
goog.inherits(proto.services.BurnTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.BurnTray.displayName = 'proto.services.BurnTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.BustTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.BustTray.repeatedFields_, null);
};
goog.inherits(proto.services.BustTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.BustTray.displayName = 'proto.services.BustTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.CommunityTray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.CommunityTray.repeatedFields_, null);
};
goog.inherits(proto.services.CommunityTray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.CommunityTray.displayName = 'proto.services.CommunityTray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ParticipantScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ParticipantScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ParticipantScore.displayName = 'proto.services.ParticipantScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ScoreBoard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.ScoreBoard.repeatedFields_, null);
};
goog.inherits(proto.services.ScoreBoard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ScoreBoard.displayName = 'proto.services.ScoreBoard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.ParticipantPot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.ParticipantPot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.ParticipantPot.displayName = 'proto.services.ParticipantPot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.Pot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.Pot.repeatedFields_, null);
};
goog.inherits(proto.services.Pot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.Pot.displayName = 'proto.services.Pot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.Wallet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.Wallet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.Wallet.displayName = 'proto.services.Wallet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.CardGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.CardGroup.repeatedFields_, null);
};
goog.inherits(proto.services.CardGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.CardGroup.displayName = 'proto.services.CardGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.SortedCardGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.SortedCardGroup.repeatedFields_, null);
};
goog.inherits(proto.services.SortedCardGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.SortedCardGroup.displayName = 'proto.services.SortedCardGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.Card = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.Card, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.Card.displayName = 'proto.services.Card';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.GroupedCardId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.GroupedCardId.repeatedFields_, null);
};
goog.inherits(proto.services.GroupedCardId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.GroupedCardId.displayName = 'proto.services.GroupedCardId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.DoClosedPlayerActionRequest.oneofGroups_);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.displayName = 'proto.services.DoClosedPlayerActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.repeatedFields_, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.MoveStageArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.MoveStageArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.MoveStageArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.DoClosedPlayerActionRequest.MoveCardArguments.oneofGroups_);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.MoveCardArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.MoveCardArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.MoveCardArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.GameActionArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.GameActionArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.GameActionArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.MoveNextArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.MoveNextArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.MoveNextArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.MoveNextArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.FoldHandArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.FoldHandArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.FoldHandArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.FoldHandArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.ShowToAllArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.ShowToAllArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.ShowToAllArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.DeclareShowArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.DeclareShowArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.DeclareShowArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoClosedPlayerActionRequest.EndSessionArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoClosedPlayerActionRequest.EndSessionArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoClosedPlayerActionRequest.EndSessionArguments.displayName = 'proto.services.DoClosedPlayerActionRequest.EndSessionArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.DoOpenPlayerActionRequest.oneofGroups_);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.displayName = 'proto.services.DoOpenPlayerActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.repeatedFields_, null);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.displayName = 'proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.displayName = 'proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.displayName = 'proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest.UnblindHandArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest.UnblindHandArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.displayName = 'proto.services.DoOpenPlayerActionRequest.UnblindHandArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest.AddToPotArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.AddToPotArguments.displayName = 'proto.services.DoOpenPlayerActionRequest.AddToPotArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.displayName = 'proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.displayName = 'proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.displayName = 'proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenTableActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.services.DoOpenTableActionRequest.oneofGroups_);
};
goog.inherits(proto.services.DoOpenTableActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenTableActionRequest.displayName = 'proto.services.DoOpenTableActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.services.DoOpenTableActionRequest.Score_RecalculateArguments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.services.DoOpenTableActionRequest.Score_RecalculateArguments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.displayName = 'proto.services.DoOpenTableActionRequest.Score_RecalculateArguments';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.ParticipantSeatPickOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ParticipantSeatPickOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ParticipantSeatPickOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ParticipantSeatPickOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantSeatPickOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    participantsList: jspb.Message.toObjectList(msg.getParticipantsList(),
    proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ParticipantSeatPickOrderResponse}
 */
proto.services.ParticipantSeatPickOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ParticipantSeatPickOrderResponse;
  return proto.services.ParticipantSeatPickOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ParticipantSeatPickOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ParticipantSeatPickOrderResponse}
 */
proto.services.ParticipantSeatPickOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder;
      reader.readMessage(value,proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.deserializeBinaryFromReader);
      msg.addParticipants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ParticipantSeatPickOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ParticipantSeatPickOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ParticipantSeatPickOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantSeatPickOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ParticipantSeatPickOrderResponse.Participant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ParticipantSeatPickOrderResponse.Participant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userDisplayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ParticipantSeatPickOrderResponse.Participant}
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ParticipantSeatPickOrderResponse.Participant;
  return proto.services.ParticipantSeatPickOrderResponse.Participant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ParticipantSeatPickOrderResponse.Participant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ParticipantSeatPickOrderResponse.Participant}
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ParticipantSeatPickOrderResponse.Participant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ParticipantSeatPickOrderResponse.Participant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.ParticipantSeatPickOrderResponse.Participant} returns this
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_display_name = 2;
 * @return {string}
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.prototype.getUserDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.ParticipantSeatPickOrderResponse.Participant} returns this
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.prototype.setUserDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.ParticipantSeatPickOrderResponse.Participant} returns this
 */
proto.services.ParticipantSeatPickOrderResponse.Participant.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    participant: (f = msg.getParticipant()) && proto.services.ParticipantSeatPickOrderResponse.Participant.toObject(includeInstance, f),
    dealSeatCard: (f = msg.getDealSeatCard()) && proto.services.Card.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder}
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder;
  return proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder}
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.ParticipantSeatPickOrderResponse.Participant;
      reader.readMessage(value,proto.services.ParticipantSeatPickOrderResponse.Participant.deserializeBinaryFromReader);
      msg.setParticipant(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.setDealSeatCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParticipant();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.ParticipantSeatPickOrderResponse.Participant.serializeBinaryToWriter
    );
  }
  f = message.getDealSeatCard();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional Participant participant = 1;
 * @return {?proto.services.ParticipantSeatPickOrderResponse.Participant}
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.getParticipant = function() {
  return /** @type{?proto.services.ParticipantSeatPickOrderResponse.Participant} */ (
    jspb.Message.getWrapperField(this, proto.services.ParticipantSeatPickOrderResponse.Participant, 1));
};


/**
 * @param {?proto.services.ParticipantSeatPickOrderResponse.Participant|undefined} value
 * @return {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder} returns this
*/
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.setParticipant = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder} returns this
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.clearParticipant = function() {
  return this.setParticipant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.hasParticipant = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Card deal_seat_card = 2;
 * @return {?proto.services.Card}
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.getDealSeatCard = function() {
  return /** @type{?proto.services.Card} */ (
    jspb.Message.getWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {?proto.services.Card|undefined} value
 * @return {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder} returns this
*/
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.setDealSeatCard = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder} returns this
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.clearDealSeatCard = function() {
  return this.setDealSeatCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder.prototype.hasDealSeatCard = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ParticipantSeatPickOrder participants = 1;
 * @return {!Array<!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder>}
 */
proto.services.ParticipantSeatPickOrderResponse.prototype.getParticipantsList = function() {
  return /** @type{!Array<!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder, 1));
};


/**
 * @param {!Array<!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder>} value
 * @return {!proto.services.ParticipantSeatPickOrderResponse} returns this
*/
proto.services.ParticipantSeatPickOrderResponse.prototype.setParticipantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder}
 */
proto.services.ParticipantSeatPickOrderResponse.prototype.addParticipants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.ParticipantSeatPickOrderResponse.ParticipantSeatPickOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.ParticipantSeatPickOrderResponse} returns this
 */
proto.services.ParticipantSeatPickOrderResponse.prototype.clearParticipantsList = function() {
  return this.setParticipantsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameDefId.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameDefId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameDefId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameDefId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameDefId}
 */
proto.services.GameDefId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameDefId;
  return proto.services.GameDefId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameDefId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameDefId}
 */
proto.services.GameDefId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameDefId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameDefId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameDefId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameDefId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.GameDefId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefId} returns this
 */
proto.services.GameDefId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.StartGameRoundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.StartGameRoundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.StartGameRoundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.StartGameRoundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameDefId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.StartGameRoundRequest}
 */
proto.services.StartGameRoundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.StartGameRoundRequest;
  return proto.services.StartGameRoundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.StartGameRoundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.StartGameRoundRequest}
 */
proto.services.StartGameRoundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameDefId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.StartGameRoundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.StartGameRoundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.StartGameRoundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.StartGameRoundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameDefId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.services.StartGameRoundRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.StartGameRoundRequest} returns this
 */
proto.services.StartGameRoundRequest.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_def_id = 2;
 * @return {string}
 */
proto.services.StartGameRoundRequest.prototype.getGameDefId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.StartGameRoundRequest} returns this
 */
proto.services.StartGameRoundRequest.prototype.setGameDefId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GetGameRoundsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetGameRoundsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetGameRoundsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetGameRoundsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameRoundsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roundsList: jspb.Message.toObjectList(msg.getRoundsList(),
    proto.services.GameState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetGameRoundsResponse}
 */
proto.services.GetGameRoundsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetGameRoundsResponse;
  return proto.services.GetGameRoundsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetGameRoundsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetGameRoundsResponse}
 */
proto.services.GetGameRoundsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.GameState;
      reader.readMessage(value,proto.services.GameState.deserializeBinaryFromReader);
      msg.addRounds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetGameRoundsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetGameRoundsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetGameRoundsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameRoundsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoundsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.GameState.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GameState rounds = 1;
 * @return {!Array<!proto.services.GameState>}
 */
proto.services.GetGameRoundsResponse.prototype.getRoundsList = function() {
  return /** @type{!Array<!proto.services.GameState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameState, 1));
};


/**
 * @param {!Array<!proto.services.GameState>} value
 * @return {!proto.services.GetGameRoundsResponse} returns this
*/
proto.services.GetGameRoundsResponse.prototype.setRoundsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.GameState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameState}
 */
proto.services.GetGameRoundsResponse.prototype.addRounds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.GameState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GetGameRoundsResponse} returns this
 */
proto.services.GetGameRoundsResponse.prototype.clearRoundsList = function() {
  return this.setRoundsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetGameRoundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetGameRoundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetGameRoundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameRoundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    round: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetGameRoundRequest}
 */
proto.services.GetGameRoundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetGameRoundRequest;
  return proto.services.GetGameRoundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetGameRoundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetGameRoundRequest}
 */
proto.services.GetGameRoundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetGameRoundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetGameRoundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetGameRoundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameRoundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRound();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.GetGameRoundRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GetGameRoundRequest} returns this
 */
proto.services.GetGameRoundRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 round = 2;
 * @return {number}
 */
proto.services.GetGameRoundRequest.prototype.getRound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GetGameRoundRequest} returns this
 */
proto.services.GetGameRoundRequest.prototype.setRound = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.GetActiveGamesRequest.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.services.GetActiveGamesRequest.GametypeValueCase = {
  GAMETYPE_VALUE_NOT_SET: 0,
  GAME_TYPE: 3
};

/**
 * @return {proto.services.GetActiveGamesRequest.GametypeValueCase}
 */
proto.services.GetActiveGamesRequest.prototype.getGametypeValueCase = function() {
  return /** @type {proto.services.GetActiveGamesRequest.GametypeValueCase} */(jspb.Message.computeOneofCase(this, proto.services.GetActiveGamesRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetActiveGamesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetActiveGamesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetActiveGamesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetActiveGamesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageToken: (f = msg.getPageToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    pageSize: (f = msg.getPageSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    gameType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetActiveGamesRequest}
 */
proto.services.GetActiveGamesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetActiveGamesRequest;
  return proto.services.GetActiveGamesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetActiveGamesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetActiveGamesRequest}
 */
proto.services.GetActiveGamesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPageToken(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {!proto.services.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetActiveGamesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetActiveGamesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetActiveGamesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetActiveGamesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.services.GameType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue page_token = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetActiveGamesRequest.prototype.getPageToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetActiveGamesRequest} returns this
*/
proto.services.GetActiveGamesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetActiveGamesRequest} returns this
 */
proto.services.GetActiveGamesRequest.prototype.clearPageToken = function() {
  return this.setPageToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetActiveGamesRequest.prototype.hasPageToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value page_size = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.services.GetActiveGamesRequest.prototype.getPageSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.services.GetActiveGamesRequest} returns this
*/
proto.services.GetActiveGamesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetActiveGamesRequest} returns this
 */
proto.services.GetActiveGamesRequest.prototype.clearPageSize = function() {
  return this.setPageSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetActiveGamesRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GameType game_type = 3;
 * @return {!proto.services.GameType}
 */
proto.services.GetActiveGamesRequest.prototype.getGameType = function() {
  return /** @type {!proto.services.GameType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.GameType} value
 * @return {!proto.services.GetActiveGamesRequest} returns this
 */
proto.services.GetActiveGamesRequest.prototype.setGameType = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.services.GetActiveGamesRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.GetActiveGamesRequest} returns this
 */
proto.services.GetActiveGamesRequest.prototype.clearGameType = function() {
  return jspb.Message.setOneofField(this, 3, proto.services.GetActiveGamesRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetActiveGamesRequest.prototype.hasGameType = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetMyOwnedGamesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetMyOwnedGamesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetMyOwnedGamesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetMyOwnedGamesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: (f = msg.getPageSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    pageToken: (f = msg.getPageToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetMyOwnedGamesRequest}
 */
proto.services.GetMyOwnedGamesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetMyOwnedGamesRequest;
  return proto.services.GetMyOwnedGamesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetMyOwnedGamesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetMyOwnedGamesRequest}
 */
proto.services.GetMyOwnedGamesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPageSize(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetMyOwnedGamesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetMyOwnedGamesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetMyOwnedGamesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetMyOwnedGamesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value page_size = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.services.GetMyOwnedGamesRequest.prototype.getPageSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.services.GetMyOwnedGamesRequest} returns this
*/
proto.services.GetMyOwnedGamesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetMyOwnedGamesRequest} returns this
 */
proto.services.GetMyOwnedGamesRequest.prototype.clearPageSize = function() {
  return this.setPageSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetMyOwnedGamesRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue page_token = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetMyOwnedGamesRequest.prototype.getPageToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetMyOwnedGamesRequest} returns this
*/
proto.services.GetMyOwnedGamesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetMyOwnedGamesRequest} returns this
 */
proto.services.GetMyOwnedGamesRequest.prototype.clearPageToken = function() {
  return this.setPageToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetMyOwnedGamesRequest.prototype.hasPageToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.services.GetMyOwnedGamesRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GetMyOwnedGamesRequest} returns this
 */
proto.services.GetMyOwnedGamesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.GetGamesRequest.oneofGroups_ = [[5]];

/**
 * @enum {number}
 */
proto.services.GetGamesRequest.ParticipantstateValueCase = {
  PARTICIPANTSTATE_VALUE_NOT_SET: 0,
  PARTICIPANT_STATE: 5
};

/**
 * @return {proto.services.GetGamesRequest.ParticipantstateValueCase}
 */
proto.services.GetGamesRequest.prototype.getParticipantstateValueCase = function() {
  return /** @type {proto.services.GetGamesRequest.ParticipantstateValueCase} */(jspb.Message.computeOneofCase(this, proto.services.GetGamesRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetGamesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetGamesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetGamesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGamesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: (f = msg.getPageSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    pageToken: (f = msg.getPageToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    participantState: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetGamesRequest}
 */
proto.services.GetGamesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetGamesRequest;
  return proto.services.GetGamesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetGamesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetGamesRequest}
 */
proto.services.GetGamesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPageSize(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {!proto.services.ParticipantStates} */ (reader.readEnum());
      msg.setParticipantState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetGamesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetGamesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetGamesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGamesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.services.ParticipantStates} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value page_size = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.services.GetGamesRequest.prototype.getPageSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.services.GetGamesRequest} returns this
*/
proto.services.GetGamesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetGamesRequest} returns this
 */
proto.services.GetGamesRequest.prototype.clearPageSize = function() {
  return this.setPageSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetGamesRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue page_token = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetGamesRequest.prototype.getPageToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetGamesRequest} returns this
*/
proto.services.GetGamesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetGamesRequest} returns this
 */
proto.services.GetGamesRequest.prototype.clearPageToken = function() {
  return this.setPageToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetGamesRequest.prototype.hasPageToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.services.GetGamesRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GetGamesRequest} returns this
 */
proto.services.GetGamesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ParticipantStates participant_state = 5;
 * @return {!proto.services.ParticipantStates}
 */
proto.services.GetGamesRequest.prototype.getParticipantState = function() {
  return /** @type {!proto.services.ParticipantStates} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.services.ParticipantStates} value
 * @return {!proto.services.GetGamesRequest} returns this
 */
proto.services.GetGamesRequest.prototype.setParticipantState = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.services.GetGamesRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.services.GetGamesRequest} returns this
 */
proto.services.GetGamesRequest.prototype.clearParticipantState = function() {
  return jspb.Message.setOneofField(this, 5, proto.services.GetGamesRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetGamesRequest.prototype.hasParticipantState = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetAllGamesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetAllGamesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetAllGamesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetAllGamesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: (f = msg.getPageSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    pageToken: (f = msg.getPageToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetAllGamesRequest}
 */
proto.services.GetAllGamesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetAllGamesRequest;
  return proto.services.GetAllGamesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetAllGamesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetAllGamesRequest}
 */
proto.services.GetAllGamesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPageSize(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetAllGamesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetAllGamesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetAllGamesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetAllGamesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value page_size = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.services.GetAllGamesRequest.prototype.getPageSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.services.GetAllGamesRequest} returns this
*/
proto.services.GetAllGamesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetAllGamesRequest} returns this
 */
proto.services.GetAllGamesRequest.prototype.clearPageSize = function() {
  return this.setPageSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetAllGamesRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue page_token = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetAllGamesRequest.prototype.getPageToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetAllGamesRequest} returns this
*/
proto.services.GetAllGamesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetAllGamesRequest} returns this
 */
proto.services.GetAllGamesRequest.prototype.clearPageToken = function() {
  return this.setPageToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetAllGamesRequest.prototype.hasPageToken = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GetGamesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetGamesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetGamesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetGamesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGamesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextPageToken: (f = msg.getNextPageToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    gamesList: jspb.Message.toObjectList(msg.getGamesList(),
    proto.services.GameInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetGamesResponse}
 */
proto.services.GetGamesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetGamesResponse;
  return proto.services.GetGamesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetGamesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetGamesResponse}
 */
proto.services.GetGamesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextPageToken(value);
      break;
    case 2:
      var value = new proto.services.GameInfo;
      reader.readMessage(value,proto.services.GameInfo.deserializeBinaryFromReader);
      msg.addGames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetGamesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetGamesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetGamesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGamesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextPageToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getGamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.GameInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue next_page_token = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetGamesResponse.prototype.getNextPageToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetGamesResponse} returns this
*/
proto.services.GetGamesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetGamesResponse} returns this
 */
proto.services.GetGamesResponse.prototype.clearNextPageToken = function() {
  return this.setNextPageToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetGamesResponse.prototype.hasNextPageToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GameInfo games = 2;
 * @return {!Array<!proto.services.GameInfo>}
 */
proto.services.GetGamesResponse.prototype.getGamesList = function() {
  return /** @type{!Array<!proto.services.GameInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameInfo, 2));
};


/**
 * @param {!Array<!proto.services.GameInfo>} value
 * @return {!proto.services.GetGamesResponse} returns this
*/
proto.services.GetGamesResponse.prototype.setGamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.GameInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameInfo}
 */
proto.services.GetGamesResponse.prototype.addGames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.GameInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GetGamesResponse} returns this
 */
proto.services.GetGamesResponse.prototype.clearGamesList = function() {
  return this.setGamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GetActiveGamesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetActiveGamesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetActiveGamesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetActiveGamesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetActiveGamesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextPageToken: (f = msg.getNextPageToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    gamesList: jspb.Message.toObjectList(msg.getGamesList(),
    proto.services.GamesList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetActiveGamesResponse}
 */
proto.services.GetActiveGamesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetActiveGamesResponse;
  return proto.services.GetActiveGamesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetActiveGamesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetActiveGamesResponse}
 */
proto.services.GetActiveGamesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextPageToken(value);
      break;
    case 2:
      var value = new proto.services.GamesList;
      reader.readMessage(value,proto.services.GamesList.deserializeBinaryFromReader);
      msg.addGames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetActiveGamesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetActiveGamesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetActiveGamesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetActiveGamesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextPageToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getGamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.GamesList.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue next_page_token = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetActiveGamesResponse.prototype.getNextPageToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetActiveGamesResponse} returns this
*/
proto.services.GetActiveGamesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetActiveGamesResponse} returns this
 */
proto.services.GetActiveGamesResponse.prototype.clearNextPageToken = function() {
  return this.setNextPageToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetActiveGamesResponse.prototype.hasNextPageToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GamesList games = 2;
 * @return {!Array<!proto.services.GamesList>}
 */
proto.services.GetActiveGamesResponse.prototype.getGamesList = function() {
  return /** @type{!Array<!proto.services.GamesList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GamesList, 2));
};


/**
 * @param {!Array<!proto.services.GamesList>} value
 * @return {!proto.services.GetActiveGamesResponse} returns this
*/
proto.services.GetActiveGamesResponse.prototype.setGamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.GamesList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GamesList}
 */
proto.services.GetActiveGamesResponse.prototype.addGames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.GamesList, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GetActiveGamesResponse} returns this
 */
proto.services.GetActiveGamesResponse.prototype.clearGamesList = function() {
  return this.setGamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ToggleGameStateDebugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ToggleGameStateDebugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ToggleGameStateDebugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ToggleGameStateDebugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enableDebug: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ToggleGameStateDebugRequest}
 */
proto.services.ToggleGameStateDebugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ToggleGameStateDebugRequest;
  return proto.services.ToggleGameStateDebugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ToggleGameStateDebugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ToggleGameStateDebugRequest}
 */
proto.services.ToggleGameStateDebugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDebug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ToggleGameStateDebugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ToggleGameStateDebugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ToggleGameStateDebugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ToggleGameStateDebugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnableDebug();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.ToggleGameStateDebugRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.ToggleGameStateDebugRequest} returns this
 */
proto.services.ToggleGameStateDebugRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enable_debug = 2;
 * @return {boolean}
 */
proto.services.ToggleGameStateDebugRequest.prototype.getEnableDebug = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.ToggleGameStateDebugRequest} returns this
 */
proto.services.ToggleGameStateDebugRequest.prototype.setEnableDebug = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ToggleGameStateVideoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ToggleGameStateVideoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ToggleGameStateVideoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ToggleGameStateVideoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enableVideo: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ToggleGameStateVideoRequest}
 */
proto.services.ToggleGameStateVideoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ToggleGameStateVideoRequest;
  return proto.services.ToggleGameStateVideoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ToggleGameStateVideoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ToggleGameStateVideoRequest}
 */
proto.services.ToggleGameStateVideoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ToggleGameStateVideoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ToggleGameStateVideoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ToggleGameStateVideoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ToggleGameStateVideoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnableVideo();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.ToggleGameStateVideoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.ToggleGameStateVideoRequest} returns this
 */
proto.services.ToggleGameStateVideoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enable_video = 2;
 * @return {boolean}
 */
proto.services.ToggleGameStateVideoRequest.prototype.getEnableVideo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.ToggleGameStateVideoRequest} returns this
 */
proto.services.ToggleGameStateVideoRequest.prototype.setEnableVideo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetGameDefinitionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetGameDefinitionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetGameDefinitionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameDefinitionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchText: (f = msg.getSearchText()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    pageSize: (f = msg.getPageSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    pageToken: (f = msg.getPageToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetGameDefinitionsRequest}
 */
proto.services.GetGameDefinitionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetGameDefinitionsRequest;
  return proto.services.GetGameDefinitionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetGameDefinitionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetGameDefinitionsRequest}
 */
proto.services.GetGameDefinitionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearchText(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPageSize(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetGameDefinitionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetGameDefinitionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetGameDefinitionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameDefinitionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchText();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPageToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue search_text = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetGameDefinitionsRequest.prototype.getSearchText = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetGameDefinitionsRequest} returns this
*/
proto.services.GetGameDefinitionsRequest.prototype.setSearchText = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetGameDefinitionsRequest} returns this
 */
proto.services.GetGameDefinitionsRequest.prototype.clearSearchText = function() {
  return this.setSearchText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetGameDefinitionsRequest.prototype.hasSearchText = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value page_size = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.services.GetGameDefinitionsRequest.prototype.getPageSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.services.GetGameDefinitionsRequest} returns this
*/
proto.services.GetGameDefinitionsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetGameDefinitionsRequest} returns this
 */
proto.services.GetGameDefinitionsRequest.prototype.clearPageSize = function() {
  return this.setPageSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetGameDefinitionsRequest.prototype.hasPageSize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue page_token = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetGameDefinitionsRequest.prototype.getPageToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetGameDefinitionsRequest} returns this
*/
proto.services.GetGameDefinitionsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetGameDefinitionsRequest} returns this
 */
proto.services.GetGameDefinitionsRequest.prototype.clearPageToken = function() {
  return this.setPageToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetGameDefinitionsRequest.prototype.hasPageToken = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GetGameDefinitionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetGameDefinitionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetGameDefinitionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetGameDefinitionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameDefinitionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextPageToken: (f = msg.getNextPageToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    definitionsList: jspb.Message.toObjectList(msg.getDefinitionsList(),
    proto.services.GameDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetGameDefinitionsResponse}
 */
proto.services.GetGameDefinitionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetGameDefinitionsResponse;
  return proto.services.GetGameDefinitionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetGameDefinitionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetGameDefinitionsResponse}
 */
proto.services.GetGameDefinitionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextPageToken(value);
      break;
    case 2:
      var value = new proto.services.GameDefinition;
      reader.readMessage(value,proto.services.GameDefinition.deserializeBinaryFromReader);
      msg.addDefinitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetGameDefinitionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetGameDefinitionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetGameDefinitionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameDefinitionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextPageToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDefinitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.GameDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue next_page_token = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GetGameDefinitionsResponse.prototype.getNextPageToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GetGameDefinitionsResponse} returns this
*/
proto.services.GetGameDefinitionsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GetGameDefinitionsResponse} returns this
 */
proto.services.GetGameDefinitionsResponse.prototype.clearNextPageToken = function() {
  return this.setNextPageToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GetGameDefinitionsResponse.prototype.hasNextPageToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated GameDefinition definitions = 2;
 * @return {!Array<!proto.services.GameDefinition>}
 */
proto.services.GetGameDefinitionsResponse.prototype.getDefinitionsList = function() {
  return /** @type{!Array<!proto.services.GameDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameDefinition, 2));
};


/**
 * @param {!Array<!proto.services.GameDefinition>} value
 * @return {!proto.services.GetGameDefinitionsResponse} returns this
*/
proto.services.GetGameDefinitionsResponse.prototype.setDefinitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.GameDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameDefinition}
 */
proto.services.GetGameDefinitionsResponse.prototype.addDefinitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.GameDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GetGameDefinitionsResponse} returns this
 */
proto.services.GetGameDefinitionsResponse.prototype.clearDefinitionsList = function() {
  return this.setDefinitionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GetGameInfosResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GetGameInfosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GetGameInfosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GetGameInfosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameInfosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamesList: jspb.Message.toObjectList(msg.getGamesList(),
    proto.services.GameInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GetGameInfosResponse}
 */
proto.services.GetGameInfosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GetGameInfosResponse;
  return proto.services.GetGameInfosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GetGameInfosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GetGameInfosResponse}
 */
proto.services.GetGameInfosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.GameInfo;
      reader.readMessage(value,proto.services.GameInfo.deserializeBinaryFromReader);
      msg.addGames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GetGameInfosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GetGameInfosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GetGameInfosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GetGameInfosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.GameInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GameInfo games = 1;
 * @return {!Array<!proto.services.GameInfo>}
 */
proto.services.GetGameInfosResponse.prototype.getGamesList = function() {
  return /** @type{!Array<!proto.services.GameInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameInfo, 1));
};


/**
 * @param {!Array<!proto.services.GameInfo>} value
 * @return {!proto.services.GetGameInfosResponse} returns this
*/
proto.services.GetGameInfosResponse.prototype.setGamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.GameInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameInfo}
 */
proto.services.GetGameInfosResponse.prototype.addGames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.GameInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GetGameInfosResponse} returns this
 */
proto.services.GetGameInfosResponse.prototype.clearGamesList = function() {
  return this.setGamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.InviteParticipantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.InviteParticipantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.InviteParticipantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.InviteParticipantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: (f = msg.getGameId()) && proto.services.GameId.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nickName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.InviteParticipantRequest}
 */
proto.services.InviteParticipantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.InviteParticipantRequest;
  return proto.services.InviteParticipantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.InviteParticipantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.InviteParticipantRequest}
 */
proto.services.InviteParticipantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.GameId;
      reader.readMessage(value,proto.services.GameId.deserializeBinaryFromReader);
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.InviteParticipantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.InviteParticipantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.InviteParticipantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.InviteParticipantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.GameId.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional GameId game_id = 1;
 * @return {?proto.services.GameId}
 */
proto.services.InviteParticipantRequest.prototype.getGameId = function() {
  return /** @type{?proto.services.GameId} */ (
    jspb.Message.getWrapperField(this, proto.services.GameId, 1));
};


/**
 * @param {?proto.services.GameId|undefined} value
 * @return {!proto.services.InviteParticipantRequest} returns this
*/
proto.services.InviteParticipantRequest.prototype.setGameId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.InviteParticipantRequest} returns this
 */
proto.services.InviteParticipantRequest.prototype.clearGameId = function() {
  return this.setGameId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.InviteParticipantRequest.prototype.hasGameId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.services.InviteParticipantRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.InviteParticipantRequest} returns this
 */
proto.services.InviteParticipantRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string nick_name = 3;
 * @return {string}
 */
proto.services.InviteParticipantRequest.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.InviteParticipantRequest} returns this
 */
proto.services.InviteParticipantRequest.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number = 4;
 * @return {string}
 */
proto.services.InviteParticipantRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.InviteParticipantRequest} returns this
 */
proto.services.InviteParticipantRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.JoinGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.JoinGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.JoinGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.JoinGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    otp: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.JoinGameRequest}
 */
proto.services.JoinGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.JoinGameRequest;
  return proto.services.JoinGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.JoinGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.JoinGameRequest}
 */
proto.services.JoinGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.JoinGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.JoinGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.JoinGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.JoinGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string OTP = 1;
 * @return {string}
 */
proto.services.JoinGameRequest.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.JoinGameRequest} returns this
 */
proto.services.JoinGameRequest.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.SelfInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.SelfInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SelfInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SelfInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SelfInviteRequest}
 */
proto.services.SelfInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SelfInviteRequest;
  return proto.services.SelfInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SelfInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SelfInviteRequest}
 */
proto.services.SelfInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SelfInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SelfInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SelfInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SelfInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.services.SelfInviteRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.SelfInviteRequest} returns this
 */
proto.services.SelfInviteRequest.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.UpdateParticipantStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.UpdateParticipantStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.UpdateParticipantStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.UpdateParticipantStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: (f = msg.getGameId()) && proto.services.GameId.toObject(includeInstance, f),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    participantState: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.UpdateParticipantStateRequest}
 */
proto.services.UpdateParticipantStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.UpdateParticipantStateRequest;
  return proto.services.UpdateParticipantStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.UpdateParticipantStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.UpdateParticipantStateRequest}
 */
proto.services.UpdateParticipantStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.GameId;
      reader.readMessage(value,proto.services.GameId.deserializeBinaryFromReader);
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {!proto.services.ParticipantStates} */ (reader.readEnum());
      msg.setParticipantState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.UpdateParticipantStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.UpdateParticipantStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.UpdateParticipantStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.UpdateParticipantStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.GameId.serializeBinaryToWriter
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParticipantState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional GameId game_id = 1;
 * @return {?proto.services.GameId}
 */
proto.services.UpdateParticipantStateRequest.prototype.getGameId = function() {
  return /** @type{?proto.services.GameId} */ (
    jspb.Message.getWrapperField(this, proto.services.GameId, 1));
};


/**
 * @param {?proto.services.GameId|undefined} value
 * @return {!proto.services.UpdateParticipantStateRequest} returns this
*/
proto.services.UpdateParticipantStateRequest.prototype.setGameId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.UpdateParticipantStateRequest} returns this
 */
proto.services.UpdateParticipantStateRequest.prototype.clearGameId = function() {
  return this.setGameId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.UpdateParticipantStateRequest.prototype.hasGameId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.services.UpdateParticipantStateRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.UpdateParticipantStateRequest} returns this
 */
proto.services.UpdateParticipantStateRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ParticipantStates participant_state = 3;
 * @return {!proto.services.ParticipantStates}
 */
proto.services.UpdateParticipantStateRequest.prototype.getParticipantState = function() {
  return /** @type {!proto.services.ParticipantStates} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.ParticipantStates} value
 * @return {!proto.services.UpdateParticipantStateRequest} returns this
 */
proto.services.UpdateParticipantStateRequest.prototype.setParticipantState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.UpdateParticipantHandStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.UpdateParticipantHandStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.UpdateParticipantHandStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.UpdateParticipantHandStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: (f = msg.getGameId()) && proto.services.GameId.toObject(includeInstance, f),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    participantHandState: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.UpdateParticipantHandStateRequest}
 */
proto.services.UpdateParticipantHandStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.UpdateParticipantHandStateRequest;
  return proto.services.UpdateParticipantHandStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.UpdateParticipantHandStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.UpdateParticipantHandStateRequest}
 */
proto.services.UpdateParticipantHandStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.GameId;
      reader.readMessage(value,proto.services.GameId.deserializeBinaryFromReader);
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {!proto.services.ParticipantHandStates} */ (reader.readEnum());
      msg.setParticipantHandState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.UpdateParticipantHandStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.UpdateParticipantHandStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.UpdateParticipantHandStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.UpdateParticipantHandStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.GameId.serializeBinaryToWriter
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParticipantHandState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional GameId game_id = 1;
 * @return {?proto.services.GameId}
 */
proto.services.UpdateParticipantHandStateRequest.prototype.getGameId = function() {
  return /** @type{?proto.services.GameId} */ (
    jspb.Message.getWrapperField(this, proto.services.GameId, 1));
};


/**
 * @param {?proto.services.GameId|undefined} value
 * @return {!proto.services.UpdateParticipantHandStateRequest} returns this
*/
proto.services.UpdateParticipantHandStateRequest.prototype.setGameId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.UpdateParticipantHandStateRequest} returns this
 */
proto.services.UpdateParticipantHandStateRequest.prototype.clearGameId = function() {
  return this.setGameId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.UpdateParticipantHandStateRequest.prototype.hasGameId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.services.UpdateParticipantHandStateRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.UpdateParticipantHandStateRequest} returns this
 */
proto.services.UpdateParticipantHandStateRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ParticipantHandStates participant_hand_state = 3;
 * @return {!proto.services.ParticipantHandStates}
 */
proto.services.UpdateParticipantHandStateRequest.prototype.getParticipantHandState = function() {
  return /** @type {!proto.services.ParticipantHandStates} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.ParticipantHandStates} value
 * @return {!proto.services.UpdateParticipantHandStateRequest} returns this
 */
proto.services.UpdateParticipantHandStateRequest.prototype.setParticipantHandState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.UpdateJokerTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.UpdateJokerTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.UpdateJokerTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.UpdateJokerTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: (f = msg.getGameId()) && proto.services.GameId.toObject(includeInstance, f),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    jokerType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.UpdateJokerTypeRequest}
 */
proto.services.UpdateJokerTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.UpdateJokerTypeRequest;
  return proto.services.UpdateJokerTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.UpdateJokerTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.UpdateJokerTypeRequest}
 */
proto.services.UpdateJokerTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.GameId;
      reader.readMessage(value,proto.services.GameId.deserializeBinaryFromReader);
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = /** @type {!proto.services.JokerTypes} */ (reader.readEnum());
      msg.setJokerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.UpdateJokerTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.UpdateJokerTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.UpdateJokerTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.UpdateJokerTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.GameId.serializeBinaryToWriter
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJokerType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional GameId game_id = 1;
 * @return {?proto.services.GameId}
 */
proto.services.UpdateJokerTypeRequest.prototype.getGameId = function() {
  return /** @type{?proto.services.GameId} */ (
    jspb.Message.getWrapperField(this, proto.services.GameId, 1));
};


/**
 * @param {?proto.services.GameId|undefined} value
 * @return {!proto.services.UpdateJokerTypeRequest} returns this
*/
proto.services.UpdateJokerTypeRequest.prototype.setGameId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.UpdateJokerTypeRequest} returns this
 */
proto.services.UpdateJokerTypeRequest.prototype.clearGameId = function() {
  return this.setGameId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.UpdateJokerTypeRequest.prototype.hasGameId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.services.UpdateJokerTypeRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.UpdateJokerTypeRequest} returns this
 */
proto.services.UpdateJokerTypeRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional JokerTypes joker_type = 3;
 * @return {!proto.services.JokerTypes}
 */
proto.services.UpdateJokerTypeRequest.prototype.getJokerType = function() {
  return /** @type {!proto.services.JokerTypes} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.JokerTypes} value
 * @return {!proto.services.UpdateJokerTypeRequest} returns this
 */
proto.services.UpdateJokerTypeRequest.prototype.setJokerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ParticipantWallet.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ParticipantWallet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ParticipantWallet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantWallet.toObject = function(includeInstance, msg) {
  var f, obj = {
    balance: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalBuyIn: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mustBuyIn: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ParticipantWallet}
 */
proto.services.ParticipantWallet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ParticipantWallet;
  return proto.services.ParticipantWallet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ParticipantWallet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ParticipantWallet}
 */
proto.services.ParticipantWallet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalBuyIn(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMustBuyIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ParticipantWallet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ParticipantWallet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ParticipantWallet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantWallet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalBuyIn();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMustBuyIn();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 balance = 1;
 * @return {number}
 */
proto.services.ParticipantWallet.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantWallet} returns this
 */
proto.services.ParticipantWallet.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_buy_in = 2;
 * @return {number}
 */
proto.services.ParticipantWallet.prototype.getTotalBuyIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantWallet} returns this
 */
proto.services.ParticipantWallet.prototype.setTotalBuyIn = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 must_buy_in = 3;
 * @return {number}
 */
proto.services.ParticipantWallet.prototype.getMustBuyIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantWallet} returns this
 */
proto.services.ParticipantWallet.prototype.setMustBuyIn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GamesList.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GamesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GamesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GamesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameState: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gameType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gameName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    participantsInRoom: jspb.Message.getFieldWithDefault(msg, 5, 0),
    participants: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hostedBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdOnUtcMs: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gameTableState: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GamesList}
 */
proto.services.GamesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GamesList;
  return proto.services.GamesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GamesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GamesList}
 */
proto.services.GamesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParticipantsInRoom(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipants(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostedBy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedOnUtcMs(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameTableState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GamesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GamesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GamesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GamesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGameType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGameName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParticipantsInRoom();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getParticipants();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHostedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedOnUtcMs();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGameTableState();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.services.GamesList.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_state = 2;
 * @return {string}
 */
proto.services.GamesList.prototype.getGameState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setGameState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string game_type = 3;
 * @return {string}
 */
proto.services.GamesList.prototype.getGameType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setGameType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string game_name = 4;
 * @return {string}
 */
proto.services.GamesList.prototype.getGameName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setGameName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 participants_in_room = 5;
 * @return {number}
 */
proto.services.GamesList.prototype.getParticipantsInRoom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setParticipantsInRoom = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string participants = 6;
 * @return {string}
 */
proto.services.GamesList.prototype.getParticipants = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setParticipants = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string hosted_by = 7;
 * @return {string}
 */
proto.services.GamesList.prototype.getHostedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setHostedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string created_on_utc_ms = 8;
 * @return {string}
 */
proto.services.GamesList.prototype.getCreatedOnUtcMs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setCreatedOnUtcMs = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string game_table_state = 9;
 * @return {string}
 */
proto.services.GamesList.prototype.getGameTableState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GamesList} returns this
 */
proto.services.GamesList.prototype.setGameTableState = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GameInfo.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameDefId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    housePlayerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdOnUtcMs: jspb.Message.getFieldWithDefault(msg, 4, ""),
    participantsList: jspb.Message.toObjectList(msg.getParticipantsList(),
    proto.services.GameInfo.Participant.toObject, includeInstance),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hostPlayerName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameInfo}
 */
proto.services.GameInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameInfo;
  return proto.services.GameInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameInfo}
 */
proto.services.GameInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameDefId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHousePlayerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedOnUtcMs(value);
      break;
    case 5:
      var value = new proto.services.GameInfo.Participant;
      reader.readMessage(value,proto.services.GameInfo.Participant.deserializeBinaryFromReader);
      msg.addParticipants(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostPlayerName(value);
      break;
    case 8:
      var value = /** @type {!proto.services.GameType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameDefId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHousePlayerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedOnUtcMs();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.services.GameInfo.Participant.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHostPlayerName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameInfo.Participant.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameInfo.Participant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameInfo.Participant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameInfo.Participant.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userDisplayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gameWallet: (f = msg.getGameWallet()) && proto.services.ParticipantWallet.toObject(includeInstance, f),
    forceChangePassword: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameInfo.Participant}
 */
proto.services.GameInfo.Participant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameInfo.Participant;
  return proto.services.GameInfo.Participant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameInfo.Participant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameInfo.Participant}
 */
proto.services.GameInfo.Participant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {!proto.services.ParticipantStates} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 5:
      var value = new proto.services.ParticipantWallet;
      reader.readMessage(value,proto.services.ParticipantWallet.deserializeBinaryFromReader);
      msg.setGameWallet(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceChangePassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameInfo.Participant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameInfo.Participant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameInfo.Participant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameInfo.Participant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGameWallet();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.services.ParticipantWallet.serializeBinaryToWriter
    );
  }
  f = message.getForceChangePassword();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.GameInfo.Participant.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo.Participant} returns this
 */
proto.services.GameInfo.Participant.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_display_name = 2;
 * @return {string}
 */
proto.services.GameInfo.Participant.prototype.getUserDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo.Participant} returns this
 */
proto.services.GameInfo.Participant.prototype.setUserDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.services.GameInfo.Participant.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo.Participant} returns this
 */
proto.services.GameInfo.Participant.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ParticipantStates state = 4;
 * @return {!proto.services.ParticipantStates}
 */
proto.services.GameInfo.Participant.prototype.getState = function() {
  return /** @type {!proto.services.ParticipantStates} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.services.ParticipantStates} value
 * @return {!proto.services.GameInfo.Participant} returns this
 */
proto.services.GameInfo.Participant.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ParticipantWallet game_wallet = 5;
 * @return {?proto.services.ParticipantWallet}
 */
proto.services.GameInfo.Participant.prototype.getGameWallet = function() {
  return /** @type{?proto.services.ParticipantWallet} */ (
    jspb.Message.getWrapperField(this, proto.services.ParticipantWallet, 5));
};


/**
 * @param {?proto.services.ParticipantWallet|undefined} value
 * @return {!proto.services.GameInfo.Participant} returns this
*/
proto.services.GameInfo.Participant.prototype.setGameWallet = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameInfo.Participant} returns this
 */
proto.services.GameInfo.Participant.prototype.clearGameWallet = function() {
  return this.setGameWallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameInfo.Participant.prototype.hasGameWallet = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool force_change_password = 6;
 * @return {boolean}
 */
proto.services.GameInfo.Participant.prototype.getForceChangePassword = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameInfo.Participant} returns this
 */
proto.services.GameInfo.Participant.prototype.setForceChangePassword = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string Id = 1;
 * @return {string}
 */
proto.services.GameInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo} returns this
 */
proto.services.GameInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_def_id = 2;
 * @return {string}
 */
proto.services.GameInfo.prototype.getGameDefId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo} returns this
 */
proto.services.GameInfo.prototype.setGameDefId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string house_player_id = 3;
 * @return {string}
 */
proto.services.GameInfo.prototype.getHousePlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo} returns this
 */
proto.services.GameInfo.prototype.setHousePlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string created_on_utc_ms = 4;
 * @return {string}
 */
proto.services.GameInfo.prototype.getCreatedOnUtcMs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo} returns this
 */
proto.services.GameInfo.prototype.setCreatedOnUtcMs = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Participant participants = 5;
 * @return {!Array<!proto.services.GameInfo.Participant>}
 */
proto.services.GameInfo.prototype.getParticipantsList = function() {
  return /** @type{!Array<!proto.services.GameInfo.Participant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameInfo.Participant, 5));
};


/**
 * @param {!Array<!proto.services.GameInfo.Participant>} value
 * @return {!proto.services.GameInfo} returns this
*/
proto.services.GameInfo.prototype.setParticipantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.services.GameInfo.Participant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameInfo.Participant}
 */
proto.services.GameInfo.prototype.addParticipants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.services.GameInfo.Participant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GameInfo} returns this
 */
proto.services.GameInfo.prototype.clearParticipantsList = function() {
  return this.setParticipantsList([]);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.services.GameInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo} returns this
 */
proto.services.GameInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string host_player_name = 7;
 * @return {string}
 */
proto.services.GameInfo.prototype.getHostPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameInfo} returns this
 */
proto.services.GameInfo.prototype.setHostPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional GameType type = 8;
 * @return {!proto.services.GameType}
 */
proto.services.GameInfo.prototype.getType = function() {
  return /** @type {!proto.services.GameType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.services.GameType} value
 * @return {!proto.services.GameInfo} returns this
 */
proto.services.GameInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameId.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameId}
 */
proto.services.GameId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameId;
  return proto.services.GameId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameId}
 */
proto.services.GameId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.GameId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameId} returns this
 */
proto.services.GameId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isPublished: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gameType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gameVariant: jspb.Message.getFieldWithDefault(msg, 6, 0),
    variationString: jspb.Message.getFieldWithDefault(msg, 7, ""),
    environment: (f = msg.getEnvironment()) && proto.services.GameDefinition.GameEnvironment.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && proto.services.GameDefinition.GameRules.toObject(includeInstance, f),
    createdOnUtcMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    lastMmodifiedOnUtcMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameDefinition}
 */
proto.services.GameDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameDefinition;
  return proto.services.GameDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameDefinition}
 */
proto.services.GameDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublished(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {!proto.services.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGameVariant(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariationString(value);
      break;
    case 8:
      var value = new proto.services.GameDefinition.GameEnvironment;
      reader.readMessage(value,proto.services.GameDefinition.GameEnvironment.deserializeBinaryFromReader);
      msg.setEnvironment(value);
      break;
    case 9:
      var value = new proto.services.GameDefinition.GameRules;
      reader.readMessage(value,proto.services.GameDefinition.GameRules.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCreatedOnUtcMs(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastMmodifiedOnUtcMs(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsPublished();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getGameVariant();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getVariationString();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEnvironment();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.services.GameDefinition.GameEnvironment.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.services.GameDefinition.GameRules.serializeBinaryToWriter
    );
  }
  f = message.getCreatedOnUtcMs();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLastMmodifiedOnUtcMs();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameDefinition.GameEnvironment.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameDefinition.GameEnvironment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameDefinition.GameEnvironment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameDefinition.GameEnvironment.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cardType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameDefinition.GameEnvironment}
 */
proto.services.GameDefinition.GameEnvironment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameDefinition.GameEnvironment;
  return proto.services.GameDefinition.GameEnvironment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameDefinition.GameEnvironment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameDefinition.GameEnvironment}
 */
proto.services.GameDefinition.GameEnvironment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameDefinition.GameEnvironment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameDefinition.GameEnvironment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameDefinition.GameEnvironment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameDefinition.GameEnvironment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string table_type = 1;
 * @return {string}
 */
proto.services.GameDefinition.GameEnvironment.prototype.getTableType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefinition.GameEnvironment} returns this
 */
proto.services.GameDefinition.GameEnvironment.prototype.setTableType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string room_type = 2;
 * @return {string}
 */
proto.services.GameDefinition.GameEnvironment.prototype.getRoomType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefinition.GameEnvironment} returns this
 */
proto.services.GameDefinition.GameEnvironment.prototype.setRoomType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string card_type = 3;
 * @return {string}
 */
proto.services.GameDefinition.GameEnvironment.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefinition.GameEnvironment} returns this
 */
proto.services.GameDefinition.GameEnvironment.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameDefinition.GameRules.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameDefinition.GameRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameDefinition.GameRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameDefinition.GameRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    packsPerGame: jspb.Message.getFieldWithDefault(msg, 1, 0),
    autoCalculatePacks: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    pictureJokersPerPack: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxPlayers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mustSeatDeal: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    cardsPerHand: jspb.Message.getFieldWithDefault(msg, 6, 0),
    maxCardsPerHand: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxSwap: jspb.Message.getFieldWithDefault(msg, 8, 0),
    showHandOnTable: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    shuffleMode: jspb.Message.getFieldWithDefault(msg, 10, 0),
    manualDealMode: jspb.Message.getFieldWithDefault(msg, 11, 0),
    autoDealMode: jspb.Message.getFieldWithDefault(msg, 12, 0),
    isOpenTable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    hasPot: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    minPot: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    maxPot: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    ante: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    dealerBoot: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    compulsoryBlind: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    hiLoType: jspb.Message.getFieldWithDefault(msg, 20, 0),
    maxBlinds: jspb.Message.getFieldWithDefault(msg, 21, 0),
    salaamiAceTrail: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    salaamiPictureTrail: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    salaamiTrail: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    bettingLimit: jspb.Message.getFieldWithDefault(msg, 25, 0),
    hasDealTray: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    hasDiscardTray: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    hasDeclareTray: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    hasBustTray: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    hasBurnTray: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    hasCommunityTray: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    hasJokerTray: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    jokerType: jspb.Message.getFieldWithDefault(msg, 33, 0),
    hideAvControls: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    disableAudioVideo: jspb.Message.getBooleanFieldWithDefault(msg, 35, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameDefinition.GameRules}
 */
proto.services.GameDefinition.GameRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameDefinition.GameRules;
  return proto.services.GameDefinition.GameRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameDefinition.GameRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameDefinition.GameRules}
 */
proto.services.GameDefinition.GameRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPacksPerGame(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoCalculatePacks(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPictureJokersPerPack(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPlayers(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMustSeatDeal(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCardsPerHand(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxCardsPerHand(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxSwap(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowHandOnTable(value);
      break;
    case 10:
      var value = /** @type {!proto.services.ShuffleModes} */ (reader.readEnum());
      msg.setShuffleMode(value);
      break;
    case 11:
      var value = /** @type {!proto.services.DealModes} */ (reader.readEnum());
      msg.setManualDealMode(value);
      break;
    case 12:
      var value = /** @type {!proto.services.DealModes} */ (reader.readEnum());
      msg.setAutoDealMode(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOpenTable(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPot(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinPot(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxPot(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnte(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDealerBoot(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompulsoryBlind(value);
      break;
    case 20:
      var value = /** @type {!proto.services.HiLoTypes} */ (reader.readEnum());
      msg.setHiLoType(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxBlinds(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSalaamiAceTrail(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSalaamiPictureTrail(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSalaamiTrail(value);
      break;
    case 25:
      var value = /** @type {!proto.services.BettingLimits} */ (reader.readEnum());
      msg.setBettingLimit(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDealTray(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDiscardTray(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDeclareTray(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBustTray(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBurnTray(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCommunityTray(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasJokerTray(value);
      break;
    case 33:
      var value = /** @type {!proto.services.JokerTypes} */ (reader.readEnum());
      msg.setJokerType(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideAvControls(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableAudioVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameDefinition.GameRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameDefinition.GameRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameDefinition.GameRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameDefinition.GameRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPacksPerGame();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAutoCalculatePacks();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPictureJokersPerPack();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMaxPlayers();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMustSeatDeal();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCardsPerHand();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMaxCardsPerHand();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMaxSwap();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getShowHandOnTable();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getShuffleMode();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getManualDealMode();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getAutoDealMode();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getIsOpenTable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getHasPot();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getMinPot();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getMaxPot();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getAnte();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getDealerBoot();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getCompulsoryBlind();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getHiLoType();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getMaxBlinds();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getSalaamiAceTrail();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getSalaamiPictureTrail();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getSalaamiTrail();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getBettingLimit();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getHasDealTray();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getHasDiscardTray();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getHasDeclareTray();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getHasBustTray();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getHasBurnTray();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getHasCommunityTray();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getHasJokerTray();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getJokerType();
  if (f !== 0.0) {
    writer.writeEnum(
      33,
      f
    );
  }
  f = message.getHideAvControls();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getDisableAudioVideo();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
};


/**
 * optional int32 packs_per_game = 1;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getPacksPerGame = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setPacksPerGame = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool auto_calculate_packs = 2;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getAutoCalculatePacks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setAutoCalculatePacks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 picture_jokers_per_pack = 3;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getPictureJokersPerPack = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setPictureJokersPerPack = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 max_players = 4;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getMaxPlayers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setMaxPlayers = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool must_seat_deal = 5;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getMustSeatDeal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setMustSeatDeal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 cards_per_hand = 6;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getCardsPerHand = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setCardsPerHand = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 max_cards_per_hand = 7;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getMaxCardsPerHand = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setMaxCardsPerHand = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 max_swap = 8;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getMaxSwap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setMaxSwap = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool show_hand_on_table = 9;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getShowHandOnTable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setShowHandOnTable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional ShuffleModes shuffle_mode = 10;
 * @return {!proto.services.ShuffleModes}
 */
proto.services.GameDefinition.GameRules.prototype.getShuffleMode = function() {
  return /** @type {!proto.services.ShuffleModes} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.services.ShuffleModes} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setShuffleMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional DealModes manual_deal_mode = 11;
 * @return {!proto.services.DealModes}
 */
proto.services.GameDefinition.GameRules.prototype.getManualDealMode = function() {
  return /** @type {!proto.services.DealModes} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.services.DealModes} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setManualDealMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional DealModes auto_deal_mode = 12;
 * @return {!proto.services.DealModes}
 */
proto.services.GameDefinition.GameRules.prototype.getAutoDealMode = function() {
  return /** @type {!proto.services.DealModes} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.services.DealModes} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setAutoDealMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool is_open_table = 13;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getIsOpenTable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setIsOpenTable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool has_pot = 14;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHasPot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHasPot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional double min_pot = 15;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getMinPot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setMinPot = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double max_pot = 16;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getMaxPot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setMaxPot = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double ante = 17;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getAnte = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setAnte = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double dealer_boot = 18;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getDealerBoot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setDealerBoot = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional bool compulsory_blind = 19;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getCompulsoryBlind = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setCompulsoryBlind = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional HiLoTypes hi_lo_type = 20;
 * @return {!proto.services.HiLoTypes}
 */
proto.services.GameDefinition.GameRules.prototype.getHiLoType = function() {
  return /** @type {!proto.services.HiLoTypes} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.services.HiLoTypes} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHiLoType = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional int32 max_blinds = 21;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getMaxBlinds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setMaxBlinds = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional double salaami_ace_trail = 22;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getSalaamiAceTrail = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setSalaamiAceTrail = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double salaami_picture_trail = 23;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getSalaamiPictureTrail = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setSalaamiPictureTrail = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double salaami_trail = 24;
 * @return {number}
 */
proto.services.GameDefinition.GameRules.prototype.getSalaamiTrail = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setSalaamiTrail = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional BettingLimits betting_limit = 25;
 * @return {!proto.services.BettingLimits}
 */
proto.services.GameDefinition.GameRules.prototype.getBettingLimit = function() {
  return /** @type {!proto.services.BettingLimits} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.services.BettingLimits} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setBettingLimit = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional bool has_deal_tray = 26;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHasDealTray = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHasDealTray = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool has_discard_tray = 27;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHasDiscardTray = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHasDiscardTray = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool has_declare_tray = 28;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHasDeclareTray = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHasDeclareTray = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool has_bust_tray = 29;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHasBustTray = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHasBustTray = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool has_burn_tray = 30;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHasBurnTray = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHasBurnTray = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool has_community_tray = 31;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHasCommunityTray = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHasCommunityTray = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool has_joker_tray = 32;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHasJokerTray = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHasJokerTray = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional JokerTypes joker_type = 33;
 * @return {!proto.services.JokerTypes}
 */
proto.services.GameDefinition.GameRules.prototype.getJokerType = function() {
  return /** @type {!proto.services.JokerTypes} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {!proto.services.JokerTypes} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setJokerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 33, value);
};


/**
 * optional bool hide_AV_controls = 34;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getHideAvControls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setHideAvControls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool disable_audio_video = 35;
 * @return {boolean}
 */
proto.services.GameDefinition.GameRules.prototype.getDisableAudioVideo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition.GameRules} returns this
 */
proto.services.GameDefinition.GameRules.prototype.setDisableAudioVideo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.GameDefinition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.services.GameDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_published = 3;
 * @return {boolean}
 */
proto.services.GameDefinition.prototype.getIsPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setIsPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.services.GameDefinition.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GameType game_type = 5;
 * @return {!proto.services.GameType}
 */
proto.services.GameDefinition.prototype.getGameType = function() {
  return /** @type {!proto.services.GameType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.services.GameType} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setGameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 game_variant = 6;
 * @return {number}
 */
proto.services.GameDefinition.prototype.getGameVariant = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setGameVariant = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string variation_string = 7;
 * @return {string}
 */
proto.services.GameDefinition.prototype.getVariationString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setVariationString = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional GameEnvironment environment = 8;
 * @return {?proto.services.GameDefinition.GameEnvironment}
 */
proto.services.GameDefinition.prototype.getEnvironment = function() {
  return /** @type{?proto.services.GameDefinition.GameEnvironment} */ (
    jspb.Message.getWrapperField(this, proto.services.GameDefinition.GameEnvironment, 8));
};


/**
 * @param {?proto.services.GameDefinition.GameEnvironment|undefined} value
 * @return {!proto.services.GameDefinition} returns this
*/
proto.services.GameDefinition.prototype.setEnvironment = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.clearEnvironment = function() {
  return this.setEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameDefinition.prototype.hasEnvironment = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional GameRules rules = 9;
 * @return {?proto.services.GameDefinition.GameRules}
 */
proto.services.GameDefinition.prototype.getRules = function() {
  return /** @type{?proto.services.GameDefinition.GameRules} */ (
    jspb.Message.getWrapperField(this, proto.services.GameDefinition.GameRules, 9));
};


/**
 * @param {?proto.services.GameDefinition.GameRules|undefined} value
 * @return {!proto.services.GameDefinition} returns this
*/
proto.services.GameDefinition.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameDefinition.prototype.hasRules = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional double created_on_utc_ms = 10;
 * @return {number}
 */
proto.services.GameDefinition.prototype.getCreatedOnUtcMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setCreatedOnUtcMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double last_mmodified_on_utc_ms = 11;
 * @return {number}
 */
proto.services.GameDefinition.prototype.getLastMmodifiedOnUtcMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setLastMmodifiedOnUtcMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string owner_id = 12;
 * @return {string}
 */
proto.services.GameDefinition.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameDefinition} returns this
 */
proto.services.GameDefinition.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GameState.repeatedFields_ = [4,9,20,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameState.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameDef: (f = msg.getGameDef()) && proto.services.GameDefinition.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    messagesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    gameNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dealRound: jspb.Message.getFieldWithDefault(msg, 7, 0),
    state: jspb.Message.getFieldWithDefault(msg, 8, 0),
    participantsList: jspb.Message.toObjectList(msg.getParticipantsList(),
    proto.services.GameState.Participant.toObject, includeInstance),
    jokerTray: (f = msg.getJokerTray()) && proto.services.JokerTray.toObject(includeInstance, f),
    dealTray: (f = msg.getDealTray()) && proto.services.DealTray.toObject(includeInstance, f),
    discardTray: (f = msg.getDiscardTray()) && proto.services.DiscardTray.toObject(includeInstance, f),
    declareTray: (f = msg.getDeclareTray()) && proto.services.DeclareTray.toObject(includeInstance, f),
    bustTray: (f = msg.getBustTray()) && proto.services.BustTray.toObject(includeInstance, f),
    burnTray: (f = msg.getBurnTray()) && proto.services.BurnTray.toObject(includeInstance, f),
    communityTray: (f = msg.getCommunityTray()) && proto.services.CommunityTray.toObject(includeInstance, f),
    communityTray2: (f = msg.getCommunityTray2()) && proto.services.CommunityTray.toObject(includeInstance, f),
    communityTray3: (f = msg.getCommunityTray3()) && proto.services.CommunityTray.toObject(includeInstance, f),
    pot: (f = msg.getPot()) && proto.services.Pot.toObject(includeInstance, f),
    sidePotsList: jspb.Message.toObjectList(msg.getSidePotsList(),
    proto.services.GameState.SidePot.toObject, includeInstance),
    housePlayerId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    dealerPlayerId: (f = msg.getDealerPlayerId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tableState: jspb.Message.getFieldWithDefault(msg, 23, 0),
    activePlayerId: (f = msg.getActivePlayerId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    previousTableState: jspb.Message.getFieldWithDefault(msg, 25, 0),
    previousActivePlayerId: (f = msg.getPreviousActivePlayerId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    debugEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.services.GameState.Action.toObject, includeInstance),
    videoSid: (f = msg.getVideoSid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    videoEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    currency: jspb.Message.getFieldWithDefault(msg, 31, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameState}
 */
proto.services.GameState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameState;
  return proto.services.GameState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameState}
 */
proto.services.GameState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.services.GameDefinition;
      reader.readMessage(value,proto.services.GameDefinition.deserializeBinaryFromReader);
      msg.setGameDef(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addMessages(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGameNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCycle(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDealRound(value);
      break;
    case 8:
      var value = /** @type {!proto.services.GameStates} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 9:
      var value = new proto.services.GameState.Participant;
      reader.readMessage(value,proto.services.GameState.Participant.deserializeBinaryFromReader);
      msg.addParticipants(value);
      break;
    case 10:
      var value = new proto.services.JokerTray;
      reader.readMessage(value,proto.services.JokerTray.deserializeBinaryFromReader);
      msg.setJokerTray(value);
      break;
    case 11:
      var value = new proto.services.DealTray;
      reader.readMessage(value,proto.services.DealTray.deserializeBinaryFromReader);
      msg.setDealTray(value);
      break;
    case 12:
      var value = new proto.services.DiscardTray;
      reader.readMessage(value,proto.services.DiscardTray.deserializeBinaryFromReader);
      msg.setDiscardTray(value);
      break;
    case 13:
      var value = new proto.services.DeclareTray;
      reader.readMessage(value,proto.services.DeclareTray.deserializeBinaryFromReader);
      msg.setDeclareTray(value);
      break;
    case 14:
      var value = new proto.services.BustTray;
      reader.readMessage(value,proto.services.BustTray.deserializeBinaryFromReader);
      msg.setBustTray(value);
      break;
    case 15:
      var value = new proto.services.BurnTray;
      reader.readMessage(value,proto.services.BurnTray.deserializeBinaryFromReader);
      msg.setBurnTray(value);
      break;
    case 16:
      var value = new proto.services.CommunityTray;
      reader.readMessage(value,proto.services.CommunityTray.deserializeBinaryFromReader);
      msg.setCommunityTray(value);
      break;
    case 17:
      var value = new proto.services.CommunityTray;
      reader.readMessage(value,proto.services.CommunityTray.deserializeBinaryFromReader);
      msg.setCommunityTray2(value);
      break;
    case 18:
      var value = new proto.services.CommunityTray;
      reader.readMessage(value,proto.services.CommunityTray.deserializeBinaryFromReader);
      msg.setCommunityTray3(value);
      break;
    case 19:
      var value = new proto.services.Pot;
      reader.readMessage(value,proto.services.Pot.deserializeBinaryFromReader);
      msg.setPot(value);
      break;
    case 20:
      var value = new proto.services.GameState.SidePot;
      reader.readMessage(value,proto.services.GameState.SidePot.deserializeBinaryFromReader);
      msg.addSidePots(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setHousePlayerId(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDealerPlayerId(value);
      break;
    case 23:
      var value = /** @type {!proto.services.TableStates} */ (reader.readEnum());
      msg.setTableState(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActivePlayerId(value);
      break;
    case 25:
      var value = /** @type {!proto.services.TableStates} */ (reader.readEnum());
      msg.setPreviousTableState(value);
      break;
    case 26:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPreviousActivePlayerId(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDebugEnabled(value);
      break;
    case 28:
      var value = new proto.services.GameState.Action;
      reader.readMessage(value,proto.services.GameState.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 29:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVideoSid(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVideoEnabled(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameDef();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.GameDefinition.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getGameNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDealRound();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.services.GameState.Participant.serializeBinaryToWriter
    );
  }
  f = message.getJokerTray();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.services.JokerTray.serializeBinaryToWriter
    );
  }
  f = message.getDealTray();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.services.DealTray.serializeBinaryToWriter
    );
  }
  f = message.getDiscardTray();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.services.DiscardTray.serializeBinaryToWriter
    );
  }
  f = message.getDeclareTray();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.services.DeclareTray.serializeBinaryToWriter
    );
  }
  f = message.getBustTray();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.services.BustTray.serializeBinaryToWriter
    );
  }
  f = message.getBurnTray();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.services.BurnTray.serializeBinaryToWriter
    );
  }
  f = message.getCommunityTray();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.services.CommunityTray.serializeBinaryToWriter
    );
  }
  f = message.getCommunityTray2();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.services.CommunityTray.serializeBinaryToWriter
    );
  }
  f = message.getCommunityTray3();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.services.CommunityTray.serializeBinaryToWriter
    );
  }
  f = message.getPot();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.services.Pot.serializeBinaryToWriter
    );
  }
  f = message.getSidePotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.services.GameState.SidePot.serializeBinaryToWriter
    );
  }
  f = message.getHousePlayerId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDealerPlayerId();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTableState();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getActivePlayerId();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPreviousTableState();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getPreviousActivePlayerId();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDebugEnabled();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.services.GameState.Action.serializeBinaryToWriter
    );
  }
  f = message.getVideoSid();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVideoEnabled();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameState.ClientAction.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameState.ClientAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameState.ClientAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.ClientAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hint: jspb.Message.getFieldWithDefault(msg, 3, ""),
    confirmationPrompt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientCall: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameState.ClientAction}
 */
proto.services.GameState.ClientAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameState.ClientAction;
  return proto.services.GameState.ClientAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameState.ClientAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameState.ClientAction}
 */
proto.services.GameState.ClientAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.services.PlayerActions} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHint(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationPrompt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientCall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameState.ClientAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameState.ClientAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameState.ClientAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.ClientAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConfirmationPrompt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientCall();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional PlayerActions id = 1;
 * @return {!proto.services.PlayerActions}
 */
proto.services.GameState.ClientAction.prototype.getId = function() {
  return /** @type {!proto.services.PlayerActions} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.services.PlayerActions} value
 * @return {!proto.services.GameState.ClientAction} returns this
 */
proto.services.GameState.ClientAction.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.services.GameState.ClientAction.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.ClientAction} returns this
 */
proto.services.GameState.ClientAction.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hint = 3;
 * @return {string}
 */
proto.services.GameState.ClientAction.prototype.getHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.ClientAction} returns this
 */
proto.services.GameState.ClientAction.prototype.setHint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string confirmation_prompt = 4;
 * @return {string}
 */
proto.services.GameState.ClientAction.prototype.getConfirmationPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.ClientAction} returns this
 */
proto.services.GameState.ClientAction.prototype.setConfirmationPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_call = 5;
 * @return {string}
 */
proto.services.GameState.ClientAction.prototype.getClientCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.ClientAction} returns this
 */
proto.services.GameState.ClientAction.prototype.setClientCall = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GameState.Participant.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameState.Participant.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameState.Participant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameState.Participant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.Participant.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userDisplayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    position: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hand: (f = msg.getHand()) && proto.services.PlayerHandTray.toObject(includeInstance, f),
    seatPickTray: (f = msg.getSeatPickTray()) && proto.services.SeatPickTray.toObject(includeInstance, f),
    joinedOnUtcMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    cardSwaps: jspb.Message.getFieldWithDefault(msg, 9, 0),
    gameWallet: (f = msg.getGameWallet()) && proto.services.ParticipantWallet.toObject(includeInstance, f),
    isPlayingBlind: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    participantActionsList: jspb.Message.toObjectList(msg.getParticipantActionsList(),
    proto.services.GameState.ClientAction.toObject, includeInstance),
    participantActionNote: jspb.Message.getFieldWithDefault(msg, 13, ""),
    gameotp: jspb.Message.getFieldWithDefault(msg, 14, ""),
    toFocus: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    forceChangePassword: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    pot: (f = msg.getPot()) && proto.services.ParticipantPot.toObject(includeInstance, f),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameState.Participant}
 */
proto.services.GameState.Participant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameState.Participant;
  return proto.services.GameState.Participant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameState.Participant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameState.Participant}
 */
proto.services.GameState.Participant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {!proto.services.ParticipantStates} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 6:
      var value = new proto.services.PlayerHandTray;
      reader.readMessage(value,proto.services.PlayerHandTray.deserializeBinaryFromReader);
      msg.setHand(value);
      break;
    case 7:
      var value = new proto.services.SeatPickTray;
      reader.readMessage(value,proto.services.SeatPickTray.deserializeBinaryFromReader);
      msg.setSeatPickTray(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setJoinedOnUtcMs(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCardSwaps(value);
      break;
    case 10:
      var value = new proto.services.ParticipantWallet;
      reader.readMessage(value,proto.services.ParticipantWallet.deserializeBinaryFromReader);
      msg.setGameWallet(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPlayingBlind(value);
      break;
    case 12:
      var value = new proto.services.GameState.ClientAction;
      reader.readMessage(value,proto.services.GameState.ClientAction.deserializeBinaryFromReader);
      msg.addParticipantActions(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantActionNote(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameotp(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToFocus(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceChangePassword(value);
      break;
    case 17:
      var value = new proto.services.ParticipantPot;
      reader.readMessage(value,proto.services.ParticipantPot.deserializeBinaryFromReader);
      msg.setPot(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameState.Participant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameState.Participant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameState.Participant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.Participant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getHand();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.services.PlayerHandTray.serializeBinaryToWriter
    );
  }
  f = message.getSeatPickTray();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.services.SeatPickTray.serializeBinaryToWriter
    );
  }
  f = message.getJoinedOnUtcMs();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCardSwaps();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getGameWallet();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.services.ParticipantWallet.serializeBinaryToWriter
    );
  }
  f = message.getIsPlayingBlind();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getParticipantActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.services.GameState.ClientAction.serializeBinaryToWriter
    );
  }
  f = message.getParticipantActionNote();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getGameotp();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getToFocus();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getForceChangePassword();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getPot();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.services.ParticipantPot.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.GameState.Participant.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_display_name = 2;
 * @return {string}
 */
proto.services.GameState.Participant.prototype.getUserDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setUserDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.services.GameState.Participant.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ParticipantStates state = 4;
 * @return {!proto.services.ParticipantStates}
 */
proto.services.GameState.Participant.prototype.getState = function() {
  return /** @type {!proto.services.ParticipantStates} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.services.ParticipantStates} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 position = 5;
 * @return {number}
 */
proto.services.GameState.Participant.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional PlayerHandTray hand = 6;
 * @return {?proto.services.PlayerHandTray}
 */
proto.services.GameState.Participant.prototype.getHand = function() {
  return /** @type{?proto.services.PlayerHandTray} */ (
    jspb.Message.getWrapperField(this, proto.services.PlayerHandTray, 6));
};


/**
 * @param {?proto.services.PlayerHandTray|undefined} value
 * @return {!proto.services.GameState.Participant} returns this
*/
proto.services.GameState.Participant.prototype.setHand = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.clearHand = function() {
  return this.setHand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.Participant.prototype.hasHand = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SeatPickTray seat_pick_tray = 7;
 * @return {?proto.services.SeatPickTray}
 */
proto.services.GameState.Participant.prototype.getSeatPickTray = function() {
  return /** @type{?proto.services.SeatPickTray} */ (
    jspb.Message.getWrapperField(this, proto.services.SeatPickTray, 7));
};


/**
 * @param {?proto.services.SeatPickTray|undefined} value
 * @return {!proto.services.GameState.Participant} returns this
*/
proto.services.GameState.Participant.prototype.setSeatPickTray = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.clearSeatPickTray = function() {
  return this.setSeatPickTray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.Participant.prototype.hasSeatPickTray = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double joined_on_utc_ms = 8;
 * @return {number}
 */
proto.services.GameState.Participant.prototype.getJoinedOnUtcMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setJoinedOnUtcMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 card_swaps = 9;
 * @return {number}
 */
proto.services.GameState.Participant.prototype.getCardSwaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setCardSwaps = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional ParticipantWallet game_wallet = 10;
 * @return {?proto.services.ParticipantWallet}
 */
proto.services.GameState.Participant.prototype.getGameWallet = function() {
  return /** @type{?proto.services.ParticipantWallet} */ (
    jspb.Message.getWrapperField(this, proto.services.ParticipantWallet, 10));
};


/**
 * @param {?proto.services.ParticipantWallet|undefined} value
 * @return {!proto.services.GameState.Participant} returns this
*/
proto.services.GameState.Participant.prototype.setGameWallet = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.clearGameWallet = function() {
  return this.setGameWallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.Participant.prototype.hasGameWallet = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_playing_blind = 11;
 * @return {boolean}
 */
proto.services.GameState.Participant.prototype.getIsPlayingBlind = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setIsPlayingBlind = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated ClientAction participant_actions = 12;
 * @return {!Array<!proto.services.GameState.ClientAction>}
 */
proto.services.GameState.Participant.prototype.getParticipantActionsList = function() {
  return /** @type{!Array<!proto.services.GameState.ClientAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameState.ClientAction, 12));
};


/**
 * @param {!Array<!proto.services.GameState.ClientAction>} value
 * @return {!proto.services.GameState.Participant} returns this
*/
proto.services.GameState.Participant.prototype.setParticipantActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.services.GameState.ClientAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameState.ClientAction}
 */
proto.services.GameState.Participant.prototype.addParticipantActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.services.GameState.ClientAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.clearParticipantActionsList = function() {
  return this.setParticipantActionsList([]);
};


/**
 * optional string participant_action_note = 13;
 * @return {string}
 */
proto.services.GameState.Participant.prototype.getParticipantActionNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setParticipantActionNote = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string gameOTP = 14;
 * @return {string}
 */
proto.services.GameState.Participant.prototype.getGameotp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setGameotp = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool to_focus = 15;
 * @return {boolean}
 */
proto.services.GameState.Participant.prototype.getToFocus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setToFocus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool force_change_password = 16;
 * @return {boolean}
 */
proto.services.GameState.Participant.prototype.getForceChangePassword = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setForceChangePassword = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional ParticipantPot pot = 17;
 * @return {?proto.services.ParticipantPot}
 */
proto.services.GameState.Participant.prototype.getPot = function() {
  return /** @type{?proto.services.ParticipantPot} */ (
    jspb.Message.getWrapperField(this, proto.services.ParticipantPot, 17));
};


/**
 * @param {?proto.services.ParticipantPot|undefined} value
 * @return {!proto.services.GameState.Participant} returns this
*/
proto.services.GameState.Participant.prototype.setPot = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.clearPot = function() {
  return this.setPot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.Participant.prototype.hasPot = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string phone_number = 18;
 * @return {string}
 */
proto.services.GameState.Participant.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Participant} returns this
 */
proto.services.GameState.Participant.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GameState.SidePot.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameState.SidePot.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameState.SidePot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameState.SidePot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.SidePot.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    participantIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameState.SidePot}
 */
proto.services.GameState.SidePot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameState.SidePot;
  return proto.services.GameState.SidePot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameState.SidePot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameState.SidePot}
 */
proto.services.GameState.SidePot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addParticipantIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameState.SidePot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameState.SidePot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameState.SidePot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.SidePot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getParticipantIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.services.GameState.SidePot.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState.SidePot} returns this
 */
proto.services.GameState.SidePot.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * repeated string participant_ids = 2;
 * @return {!Array<string>}
 */
proto.services.GameState.SidePot.prototype.getParticipantIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.GameState.SidePot} returns this
 */
proto.services.GameState.SidePot.prototype.setParticipantIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.GameState.SidePot} returns this
 */
proto.services.GameState.SidePot.prototype.addParticipantIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GameState.SidePot} returns this
 */
proto.services.GameState.SidePot.prototype.clearParticipantIdsList = function() {
  return this.setParticipantIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GameState.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GameState.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GameState.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actionUtcMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    participantId: (f = msg.getParticipantId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    actionArgument1: (f = msg.getActionArgument1()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    actionArgument2: (f = msg.getActionArgument2()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    actionArgument3: (f = msg.getActionArgument3()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GameState.Action}
 */
proto.services.GameState.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GameState.Action;
  return proto.services.GameState.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GameState.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GameState.Action}
 */
proto.services.GameState.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActionUtcMs(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setParticipantId(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActionArgument1(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActionArgument2(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActionArgument3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GameState.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GameState.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GameState.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GameState.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActionUtcMs();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getParticipantId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getActionArgument1();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getActionArgument2();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getActionArgument3();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.GameState.Action.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 version = 2;
 * @return {number}
 */
proto.services.GameState.Action.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.services.GameState.Action.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.services.GameState.Action.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double action_utc_ms = 5;
 * @return {number}
 */
proto.services.GameState.Action.prototype.getActionUtcMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.setActionUtcMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue participant_id = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GameState.Action.prototype.getParticipantId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GameState.Action} returns this
*/
proto.services.GameState.Action.prototype.setParticipantId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.clearParticipantId = function() {
  return this.setParticipantId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.Action.prototype.hasParticipantId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue action_argument1 = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GameState.Action.prototype.getActionArgument1 = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GameState.Action} returns this
*/
proto.services.GameState.Action.prototype.setActionArgument1 = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.clearActionArgument1 = function() {
  return this.setActionArgument1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.Action.prototype.hasActionArgument1 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue action_argument2 = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GameState.Action.prototype.getActionArgument2 = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GameState.Action} returns this
*/
proto.services.GameState.Action.prototype.setActionArgument2 = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.clearActionArgument2 = function() {
  return this.setActionArgument2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.Action.prototype.hasActionArgument2 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue action_argument3 = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GameState.Action.prototype.getActionArgument3 = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GameState.Action} returns this
*/
proto.services.GameState.Action.prototype.setActionArgument3 = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState.Action} returns this
 */
proto.services.GameState.Action.prototype.clearActionArgument3 = function() {
  return this.setActionArgument3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.Action.prototype.hasActionArgument3 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.GameState.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GameDefinition game_def = 2;
 * @return {?proto.services.GameDefinition}
 */
proto.services.GameState.prototype.getGameDef = function() {
  return /** @type{?proto.services.GameDefinition} */ (
    jspb.Message.getWrapperField(this, proto.services.GameDefinition, 2));
};


/**
 * @param {?proto.services.GameDefinition|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setGameDef = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearGameDef = function() {
  return this.setGameDef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasGameDef = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 version = 3;
 * @return {number}
 */
proto.services.GameState.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string messages = 4;
 * @return {!Array<string>}
 */
proto.services.GameState.prototype.getMessagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setMessagesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.addMessages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};


/**
 * optional int32 game_number = 5;
 * @return {number}
 */
proto.services.GameState.prototype.getGameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setGameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 cycle = 6;
 * @return {number}
 */
proto.services.GameState.prototype.getCycle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setCycle = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 deal_round = 7;
 * @return {number}
 */
proto.services.GameState.prototype.getDealRound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setDealRound = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional GameStates state = 8;
 * @return {!proto.services.GameStates}
 */
proto.services.GameState.prototype.getState = function() {
  return /** @type {!proto.services.GameStates} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.services.GameStates} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated Participant participants = 9;
 * @return {!Array<!proto.services.GameState.Participant>}
 */
proto.services.GameState.prototype.getParticipantsList = function() {
  return /** @type{!Array<!proto.services.GameState.Participant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameState.Participant, 9));
};


/**
 * @param {!Array<!proto.services.GameState.Participant>} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setParticipantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.services.GameState.Participant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameState.Participant}
 */
proto.services.GameState.prototype.addParticipants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.services.GameState.Participant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearParticipantsList = function() {
  return this.setParticipantsList([]);
};


/**
 * optional JokerTray joker_tray = 10;
 * @return {?proto.services.JokerTray}
 */
proto.services.GameState.prototype.getJokerTray = function() {
  return /** @type{?proto.services.JokerTray} */ (
    jspb.Message.getWrapperField(this, proto.services.JokerTray, 10));
};


/**
 * @param {?proto.services.JokerTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setJokerTray = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearJokerTray = function() {
  return this.setJokerTray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasJokerTray = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DealTray deal_tray = 11;
 * @return {?proto.services.DealTray}
 */
proto.services.GameState.prototype.getDealTray = function() {
  return /** @type{?proto.services.DealTray} */ (
    jspb.Message.getWrapperField(this, proto.services.DealTray, 11));
};


/**
 * @param {?proto.services.DealTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setDealTray = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearDealTray = function() {
  return this.setDealTray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasDealTray = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DiscardTray discard_tray = 12;
 * @return {?proto.services.DiscardTray}
 */
proto.services.GameState.prototype.getDiscardTray = function() {
  return /** @type{?proto.services.DiscardTray} */ (
    jspb.Message.getWrapperField(this, proto.services.DiscardTray, 12));
};


/**
 * @param {?proto.services.DiscardTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setDiscardTray = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearDiscardTray = function() {
  return this.setDiscardTray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasDiscardTray = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DeclareTray declare_tray = 13;
 * @return {?proto.services.DeclareTray}
 */
proto.services.GameState.prototype.getDeclareTray = function() {
  return /** @type{?proto.services.DeclareTray} */ (
    jspb.Message.getWrapperField(this, proto.services.DeclareTray, 13));
};


/**
 * @param {?proto.services.DeclareTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setDeclareTray = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearDeclareTray = function() {
  return this.setDeclareTray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasDeclareTray = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional BustTray bust_tray = 14;
 * @return {?proto.services.BustTray}
 */
proto.services.GameState.prototype.getBustTray = function() {
  return /** @type{?proto.services.BustTray} */ (
    jspb.Message.getWrapperField(this, proto.services.BustTray, 14));
};


/**
 * @param {?proto.services.BustTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setBustTray = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearBustTray = function() {
  return this.setBustTray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasBustTray = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional BurnTray burn_tray = 15;
 * @return {?proto.services.BurnTray}
 */
proto.services.GameState.prototype.getBurnTray = function() {
  return /** @type{?proto.services.BurnTray} */ (
    jspb.Message.getWrapperField(this, proto.services.BurnTray, 15));
};


/**
 * @param {?proto.services.BurnTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setBurnTray = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearBurnTray = function() {
  return this.setBurnTray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasBurnTray = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional CommunityTray community_tray = 16;
 * @return {?proto.services.CommunityTray}
 */
proto.services.GameState.prototype.getCommunityTray = function() {
  return /** @type{?proto.services.CommunityTray} */ (
    jspb.Message.getWrapperField(this, proto.services.CommunityTray, 16));
};


/**
 * @param {?proto.services.CommunityTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setCommunityTray = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearCommunityTray = function() {
  return this.setCommunityTray(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasCommunityTray = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional CommunityTray community_tray2 = 17;
 * @return {?proto.services.CommunityTray}
 */
proto.services.GameState.prototype.getCommunityTray2 = function() {
  return /** @type{?proto.services.CommunityTray} */ (
    jspb.Message.getWrapperField(this, proto.services.CommunityTray, 17));
};


/**
 * @param {?proto.services.CommunityTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setCommunityTray2 = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearCommunityTray2 = function() {
  return this.setCommunityTray2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasCommunityTray2 = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional CommunityTray community_tray3 = 18;
 * @return {?proto.services.CommunityTray}
 */
proto.services.GameState.prototype.getCommunityTray3 = function() {
  return /** @type{?proto.services.CommunityTray} */ (
    jspb.Message.getWrapperField(this, proto.services.CommunityTray, 18));
};


/**
 * @param {?proto.services.CommunityTray|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setCommunityTray3 = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearCommunityTray3 = function() {
  return this.setCommunityTray3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasCommunityTray3 = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Pot pot = 19;
 * @return {?proto.services.Pot}
 */
proto.services.GameState.prototype.getPot = function() {
  return /** @type{?proto.services.Pot} */ (
    jspb.Message.getWrapperField(this, proto.services.Pot, 19));
};


/**
 * @param {?proto.services.Pot|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setPot = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearPot = function() {
  return this.setPot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasPot = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated SidePot side_pots = 20;
 * @return {!Array<!proto.services.GameState.SidePot>}
 */
proto.services.GameState.prototype.getSidePotsList = function() {
  return /** @type{!Array<!proto.services.GameState.SidePot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameState.SidePot, 20));
};


/**
 * @param {!Array<!proto.services.GameState.SidePot>} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setSidePotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.services.GameState.SidePot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameState.SidePot}
 */
proto.services.GameState.prototype.addSidePots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.services.GameState.SidePot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearSidePotsList = function() {
  return this.setSidePotsList([]);
};


/**
 * optional string house_player_id = 21;
 * @return {string}
 */
proto.services.GameState.prototype.getHousePlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setHousePlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional google.protobuf.StringValue dealer_player_id = 22;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GameState.prototype.getDealerPlayerId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 22));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setDealerPlayerId = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearDealerPlayerId = function() {
  return this.setDealerPlayerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasDealerPlayerId = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional TableStates table_state = 23;
 * @return {!proto.services.TableStates}
 */
proto.services.GameState.prototype.getTableState = function() {
  return /** @type {!proto.services.TableStates} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.services.TableStates} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setTableState = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional google.protobuf.StringValue active_player_id = 24;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GameState.prototype.getActivePlayerId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 24));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setActivePlayerId = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearActivePlayerId = function() {
  return this.setActivePlayerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasActivePlayerId = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional TableStates previous_table_state = 25;
 * @return {!proto.services.TableStates}
 */
proto.services.GameState.prototype.getPreviousTableState = function() {
  return /** @type {!proto.services.TableStates} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.services.TableStates} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setPreviousTableState = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional google.protobuf.StringValue previous_active_player_id = 26;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GameState.prototype.getPreviousActivePlayerId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 26));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setPreviousActivePlayerId = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearPreviousActivePlayerId = function() {
  return this.setPreviousActivePlayerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasPreviousActivePlayerId = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional bool debug_enabled = 27;
 * @return {boolean}
 */
proto.services.GameState.prototype.getDebugEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setDebugEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * repeated Action actions = 28;
 * @return {!Array<!proto.services.GameState.Action>}
 */
proto.services.GameState.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.services.GameState.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GameState.Action, 28));
};


/**
 * @param {!Array<!proto.services.GameState.Action>} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.services.GameState.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GameState.Action}
 */
proto.services.GameState.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.services.GameState.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional google.protobuf.StringValue video_sid = 29;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.GameState.prototype.getVideoSid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 29));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.GameState} returns this
*/
proto.services.GameState.prototype.setVideoSid = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.clearVideoSid = function() {
  return this.setVideoSid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.GameState.prototype.hasVideoSid = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional bool video_enabled = 30;
 * @return {boolean}
 */
proto.services.GameState.prototype.getVideoEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setVideoEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional string currency = 31;
 * @return {string}
 */
proto.services.GameState.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GameState} returns this
 */
proto.services.GameState.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.TrayProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.services.TrayProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.TrayProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.TrayProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    isFaceUp: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    canDrag: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    canDrop: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    canGroup: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    minCardsCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxCardsCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trayTitle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    othertray: jspb.Message.getFieldWithDefault(msg, 8, 0),
    showStacked: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    canShow: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.TrayProperties}
 */
proto.services.TrayProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.TrayProperties;
  return proto.services.TrayProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.TrayProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.TrayProperties}
 */
proto.services.TrayProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFaceUp(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDrag(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDrop(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanGroup(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinCardsCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxCardsCount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrayTitle(value);
      break;
    case 8:
      var value = /** @type {!proto.services.TrayTypes} */ (reader.readEnum());
      msg.setOthertray(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowStacked(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanShow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.TrayProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.TrayProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.TrayProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.TrayProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsFaceUp();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCanDrag();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCanDrop();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCanGroup();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMinCardsCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxCardsCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTrayTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOthertray();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getShowStacked();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCanShow();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional bool is_face_up = 1;
 * @return {boolean}
 */
proto.services.TrayProperties.prototype.getIsFaceUp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setIsFaceUp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool can_drag = 2;
 * @return {boolean}
 */
proto.services.TrayProperties.prototype.getCanDrag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setCanDrag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool can_drop = 3;
 * @return {boolean}
 */
proto.services.TrayProperties.prototype.getCanDrop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setCanDrop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool can_group = 4;
 * @return {boolean}
 */
proto.services.TrayProperties.prototype.getCanGroup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setCanGroup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 min_cards_count = 5;
 * @return {number}
 */
proto.services.TrayProperties.prototype.getMinCardsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setMinCardsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_cards_count = 6;
 * @return {number}
 */
proto.services.TrayProperties.prototype.getMaxCardsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setMaxCardsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string tray_title = 7;
 * @return {string}
 */
proto.services.TrayProperties.prototype.getTrayTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setTrayTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional TrayTypes otherTray = 8;
 * @return {!proto.services.TrayTypes}
 */
proto.services.TrayProperties.prototype.getOthertray = function() {
  return /** @type {!proto.services.TrayTypes} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.services.TrayTypes} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setOthertray = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool show_stacked = 9;
 * @return {boolean}
 */
proto.services.TrayProperties.prototype.getShowStacked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setShowStacked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool can_show = 10;
 * @return {boolean}
 */
proto.services.TrayProperties.prototype.getCanShow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.TrayProperties} returns this
 */
proto.services.TrayProperties.prototype.setCanShow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.DealTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DealTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DealTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DealTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DealTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance),
    numberOfPacksUsed: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DealTray}
 */
proto.services.DealTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DealTray;
  return proto.services.DealTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DealTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DealTray}
 */
proto.services.DealTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfPacksUsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DealTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DealTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DealTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DealTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
  f = message.getNumberOfPacksUsed();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.DealTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.DealTray} returns this
*/
proto.services.DealTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DealTray} returns this
 */
proto.services.DealTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DealTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.DealTray.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.DealTray} returns this
*/
proto.services.DealTray.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.DealTray.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.DealTray} returns this
 */
proto.services.DealTray.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional int32 number_of_packs_used = 3;
 * @return {number}
 */
proto.services.DealTray.prototype.getNumberOfPacksUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.DealTray} returns this
 */
proto.services.DealTray.prototype.setNumberOfPacksUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.JokerTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.JokerTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.JokerTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.JokerTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.JokerTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.JokerTray}
 */
proto.services.JokerTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.JokerTray;
  return proto.services.JokerTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.JokerTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.JokerTray}
 */
proto.services.JokerTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.JokerTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.JokerTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.JokerTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.JokerTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.JokerTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.JokerTray} returns this
*/
proto.services.JokerTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.JokerTray} returns this
 */
proto.services.JokerTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.JokerTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.JokerTray.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.JokerTray} returns this
*/
proto.services.JokerTray.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.JokerTray.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.JokerTray} returns this
 */
proto.services.JokerTray.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.DiscardTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DiscardTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DiscardTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DiscardTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DiscardTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DiscardTray}
 */
proto.services.DiscardTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DiscardTray;
  return proto.services.DiscardTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DiscardTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DiscardTray}
 */
proto.services.DiscardTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DiscardTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DiscardTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DiscardTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DiscardTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.DiscardTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.DiscardTray} returns this
*/
proto.services.DiscardTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DiscardTray} returns this
 */
proto.services.DiscardTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DiscardTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.DiscardTray.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.DiscardTray} returns this
*/
proto.services.DiscardTray.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.DiscardTray.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.DiscardTray} returns this
 */
proto.services.DiscardTray.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.DeclareTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DeclareTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DeclareTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DeclareTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DeclareTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DeclareTray}
 */
proto.services.DeclareTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DeclareTray;
  return proto.services.DeclareTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DeclareTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DeclareTray}
 */
proto.services.DeclareTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DeclareTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DeclareTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DeclareTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DeclareTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.DeclareTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.DeclareTray} returns this
*/
proto.services.DeclareTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DeclareTray} returns this
 */
proto.services.DeclareTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DeclareTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.DeclareTray.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.DeclareTray} returns this
*/
proto.services.DeclareTray.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.DeclareTray.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.DeclareTray} returns this
 */
proto.services.DeclareTray.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.SeatPickTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.SeatPickTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.SeatPickTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SeatPickTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SeatPickTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SeatPickTray}
 */
proto.services.SeatPickTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SeatPickTray;
  return proto.services.SeatPickTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SeatPickTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SeatPickTray}
 */
proto.services.SeatPickTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SeatPickTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SeatPickTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SeatPickTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SeatPickTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.SeatPickTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.SeatPickTray} returns this
*/
proto.services.SeatPickTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.SeatPickTray} returns this
 */
proto.services.SeatPickTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.SeatPickTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.SeatPickTray.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.SeatPickTray} returns this
*/
proto.services.SeatPickTray.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.SeatPickTray.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.SeatPickTray} returns this
 */
proto.services.SeatPickTray.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.PlayerHandTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.PlayerHandTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.PlayerHandTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.PlayerHandTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.PlayerHandTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.services.CardGroup.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 3, 0),
    systemCalculatedScore: jspb.Message.getFieldWithDefault(msg, 4, 0),
    countSet: jspb.Message.getFieldWithDefault(msg, 5, 0),
    countPureSet: jspb.Message.getFieldWithDefault(msg, 6, 0),
    countSequence: jspb.Message.getFieldWithDefault(msg, 7, 0),
    countPureSequence: jspb.Message.getFieldWithDefault(msg, 8, 0),
    cycleBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    roundBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    gameBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    amountWon: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    cardsOnHand: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.PlayerHandTray}
 */
proto.services.PlayerHandTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.PlayerHandTray;
  return proto.services.PlayerHandTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.PlayerHandTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.PlayerHandTray}
 */
proto.services.PlayerHandTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.CardGroup;
      reader.readMessage(value,proto.services.CardGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 3:
      var value = /** @type {!proto.services.ParticipantHandStates} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSystemCalculatedScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountSet(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountPureSet(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountSequence(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountPureSequence(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCycleBet(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoundBet(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGameBet(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmountWon(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCardsOnHand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.PlayerHandTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.PlayerHandTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.PlayerHandTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.PlayerHandTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.CardGroup.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSystemCalculatedScore();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCountSet();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCountPureSet();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCountSequence();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCountPureSequence();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCycleBet();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getRoundBet();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getGameBet();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAmountWon();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getCardsOnHand();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.PlayerHandTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.PlayerHandTray} returns this
*/
proto.services.PlayerHandTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.PlayerHandTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CardGroup groups = 2;
 * @return {!Array<!proto.services.CardGroup>}
 */
proto.services.PlayerHandTray.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.services.CardGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.CardGroup, 2));
};


/**
 * @param {!Array<!proto.services.CardGroup>} value
 * @return {!proto.services.PlayerHandTray} returns this
*/
proto.services.PlayerHandTray.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.CardGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.CardGroup}
 */
proto.services.PlayerHandTray.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.CardGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional ParticipantHandStates state = 3;
 * @return {!proto.services.ParticipantHandStates}
 */
proto.services.PlayerHandTray.prototype.getState = function() {
  return /** @type {!proto.services.ParticipantHandStates} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.ParticipantHandStates} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 system_calculated_score = 4;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getSystemCalculatedScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setSystemCalculatedScore = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 count_set = 5;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getCountSet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setCountSet = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 count_pure_set = 6;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getCountPureSet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setCountPureSet = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 count_sequence = 7;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getCountSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setCountSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 count_pure_sequence = 8;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getCountPureSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setCountPureSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double cycle_bet = 9;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getCycleBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setCycleBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double round_bet = 10;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getRoundBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setRoundBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double game_bet = 11;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getGameBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setGameBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double amount_won = 12;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getAmountWon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setAmountWon = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 cards_on_hand = 13;
 * @return {number}
 */
proto.services.PlayerHandTray.prototype.getCardsOnHand = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.PlayerHandTray} returns this
 */
proto.services.PlayerHandTray.prototype.setCardsOnHand = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.BurnTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.BurnTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.BurnTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.BurnTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.BurnTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.BurnTray}
 */
proto.services.BurnTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.BurnTray;
  return proto.services.BurnTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.BurnTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.BurnTray}
 */
proto.services.BurnTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.BurnTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.BurnTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.BurnTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.BurnTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.BurnTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.BurnTray} returns this
*/
proto.services.BurnTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.BurnTray} returns this
 */
proto.services.BurnTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.BurnTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.BurnTray.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.BurnTray} returns this
*/
proto.services.BurnTray.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.BurnTray.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.BurnTray} returns this
 */
proto.services.BurnTray.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.BustTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.BustTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.BustTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.BustTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.BustTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.BustTray}
 */
proto.services.BustTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.BustTray;
  return proto.services.BustTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.BustTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.BustTray}
 */
proto.services.BustTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.BustTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.BustTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.BustTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.BustTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.BustTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.BustTray} returns this
*/
proto.services.BustTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.BustTray} returns this
 */
proto.services.BustTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.BustTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.BustTray.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.BustTray} returns this
*/
proto.services.BustTray.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.BustTray.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.BustTray} returns this
 */
proto.services.BustTray.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.CommunityTray.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.CommunityTray.prototype.toObject = function(opt_includeInstance) {
  return proto.services.CommunityTray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.CommunityTray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.CommunityTray.toObject = function(includeInstance, msg) {
  var f, obj = {
    props: (f = msg.getProps()) && proto.services.TrayProperties.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.CommunityTray}
 */
proto.services.CommunityTray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.CommunityTray;
  return proto.services.CommunityTray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.CommunityTray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.CommunityTray}
 */
proto.services.CommunityTray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.TrayProperties;
      reader.readMessage(value,proto.services.TrayProperties.deserializeBinaryFromReader);
      msg.setProps(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.CommunityTray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.CommunityTray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.CommunityTray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.CommunityTray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProps();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.services.TrayProperties.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrayProperties props = 1;
 * @return {?proto.services.TrayProperties}
 */
proto.services.CommunityTray.prototype.getProps = function() {
  return /** @type{?proto.services.TrayProperties} */ (
    jspb.Message.getWrapperField(this, proto.services.TrayProperties, 1));
};


/**
 * @param {?proto.services.TrayProperties|undefined} value
 * @return {!proto.services.CommunityTray} returns this
*/
proto.services.CommunityTray.prototype.setProps = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.CommunityTray} returns this
 */
proto.services.CommunityTray.prototype.clearProps = function() {
  return this.setProps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.CommunityTray.prototype.hasProps = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.CommunityTray.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.CommunityTray} returns this
*/
proto.services.CommunityTray.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.CommunityTray.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.CommunityTray} returns this
 */
proto.services.CommunityTray.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ParticipantScore.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ParticipantScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ParticipantScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    participantId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    netLost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    netWon: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    netBuyin: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ParticipantScore}
 */
proto.services.ParticipantScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ParticipantScore;
  return proto.services.ParticipantScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ParticipantScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ParticipantScore}
 */
proto.services.ParticipantScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetLost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetWon(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetBuyin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ParticipantScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ParticipantScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ParticipantScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParticipantId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNetLost();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getNetWon();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getNetBuyin();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string participant_id = 1;
 * @return {string}
 */
proto.services.ParticipantScore.prototype.getParticipantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.ParticipantScore} returns this
 */
proto.services.ParticipantScore.prototype.setParticipantId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double net_lost = 2;
 * @return {number}
 */
proto.services.ParticipantScore.prototype.getNetLost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantScore} returns this
 */
proto.services.ParticipantScore.prototype.setNetLost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double net_won = 3;
 * @return {number}
 */
proto.services.ParticipantScore.prototype.getNetWon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantScore} returns this
 */
proto.services.ParticipantScore.prototype.setNetWon = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double net_buyin = 4;
 * @return {number}
 */
proto.services.ParticipantScore.prototype.getNetBuyin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantScore} returns this
 */
proto.services.ParticipantScore.prototype.setNetBuyin = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.ScoreBoard.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ScoreBoard.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ScoreBoard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ScoreBoard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ScoreBoard.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roundid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scoresList: jspb.Message.toObjectList(msg.getScoresList(),
    proto.services.ParticipantScore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ScoreBoard}
 */
proto.services.ScoreBoard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ScoreBoard;
  return proto.services.ScoreBoard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ScoreBoard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ScoreBoard}
 */
proto.services.ScoreBoard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRoundid(value);
      break;
    case 3:
      var value = new proto.services.ParticipantScore;
      reader.readMessage(value,proto.services.ParticipantScore.deserializeBinaryFromReader);
      msg.addScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ScoreBoard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ScoreBoard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ScoreBoard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ScoreBoard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoundid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.services.ParticipantScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional string gameId = 1;
 * @return {string}
 */
proto.services.ScoreBoard.prototype.getGameid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.ScoreBoard} returns this
 */
proto.services.ScoreBoard.prototype.setGameid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 roundId = 2;
 * @return {number}
 */
proto.services.ScoreBoard.prototype.getRoundid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.ScoreBoard} returns this
 */
proto.services.ScoreBoard.prototype.setRoundid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ParticipantScore scores = 3;
 * @return {!Array<!proto.services.ParticipantScore>}
 */
proto.services.ScoreBoard.prototype.getScoresList = function() {
  return /** @type{!Array<!proto.services.ParticipantScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.ParticipantScore, 3));
};


/**
 * @param {!Array<!proto.services.ParticipantScore>} value
 * @return {!proto.services.ScoreBoard} returns this
*/
proto.services.ScoreBoard.prototype.setScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.services.ParticipantScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.ParticipantScore}
 */
proto.services.ScoreBoard.prototype.addScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.services.ParticipantScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.ScoreBoard} returns this
 */
proto.services.ScoreBoard.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.ParticipantPot.prototype.toObject = function(opt_includeInstance) {
  return proto.services.ParticipantPot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.ParticipantPot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantPot.toObject = function(includeInstance, msg) {
  var f, obj = {
    chaalBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    potBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    raiseBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    allInBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.ParticipantPot}
 */
proto.services.ParticipantPot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.ParticipantPot;
  return proto.services.ParticipantPot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.ParticipantPot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.ParticipantPot}
 */
proto.services.ParticipantPot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setChaalBet(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPotBet(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRaiseBet(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAllInBet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.ParticipantPot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.ParticipantPot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.ParticipantPot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.ParticipantPot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChaalBet();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPotBet();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRaiseBet();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAllInBet();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double chaal_bet = 1;
 * @return {number}
 */
proto.services.ParticipantPot.prototype.getChaalBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantPot} returns this
 */
proto.services.ParticipantPot.prototype.setChaalBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double pot_bet = 2;
 * @return {number}
 */
proto.services.ParticipantPot.prototype.getPotBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantPot} returns this
 */
proto.services.ParticipantPot.prototype.setPotBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double raise_bet = 3;
 * @return {number}
 */
proto.services.ParticipantPot.prototype.getRaiseBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantPot} returns this
 */
proto.services.ParticipantPot.prototype.setRaiseBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double all_in_bet = 4;
 * @return {number}
 */
proto.services.ParticipantPot.prototype.getAllInBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.ParticipantPot} returns this
 */
proto.services.ParticipantPot.prototype.setAllInBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.Pot.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.Pot.prototype.toObject = function(opt_includeInstance) {
  return proto.services.Pot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.Pot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Pot.toObject = function(includeInstance, msg) {
  var f, obj = {
    cycleAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    netAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    recentBetsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    potMinBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    potMaxBet: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.Pot}
 */
proto.services.Pot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.Pot;
  return proto.services.Pot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.Pot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.Pot}
 */
proto.services.Pot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCycleAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetAmount(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRecentBets(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPotMinBet(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPotMaxBet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.Pot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.Pot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.Pot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Pot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCycleAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNetAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRecentBetsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getPotMinBet();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPotMaxBet();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional double cycle_amount = 1;
 * @return {number}
 */
proto.services.Pot.prototype.getCycleAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.Pot} returns this
 */
proto.services.Pot.prototype.setCycleAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double net_amount = 2;
 * @return {number}
 */
proto.services.Pot.prototype.getNetAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.Pot} returns this
 */
proto.services.Pot.prototype.setNetAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated double recent_bets = 3;
 * @return {!Array<number>}
 */
proto.services.Pot.prototype.getRecentBetsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.services.Pot} returns this
 */
proto.services.Pot.prototype.setRecentBetsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.services.Pot} returns this
 */
proto.services.Pot.prototype.addRecentBets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.Pot} returns this
 */
proto.services.Pot.prototype.clearRecentBetsList = function() {
  return this.setRecentBetsList([]);
};


/**
 * optional double pot_min_bet = 4;
 * @return {number}
 */
proto.services.Pot.prototype.getPotMinBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.Pot} returns this
 */
proto.services.Pot.prototype.setPotMinBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double pot_max_bet = 5;
 * @return {number}
 */
proto.services.Pot.prototype.getPotMaxBet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.Pot} returns this
 */
proto.services.Pot.prototype.setPotMaxBet = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.Wallet.prototype.toObject = function(opt_includeInstance) {
  return proto.services.Wallet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.Wallet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Wallet.toObject = function(includeInstance, msg) {
  var f, obj = {
    walletCurrency: jspb.Message.getFieldWithDefault(msg, 1, ""),
    walletBalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.Wallet}
 */
proto.services.Wallet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.Wallet;
  return proto.services.Wallet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.Wallet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.Wallet}
 */
proto.services.Wallet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWalletCurrency(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWalletBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.Wallet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.Wallet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.Wallet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Wallet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalletCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWalletBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string wallet_currency = 1;
 * @return {string}
 */
proto.services.Wallet.prototype.getWalletCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.Wallet} returns this
 */
proto.services.Wallet.prototype.setWalletCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double wallet_balance = 2;
 * @return {number}
 */
proto.services.Wallet.prototype.getWalletBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.Wallet} returns this
 */
proto.services.Wallet.prototype.setWalletBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.CardGroup.repeatedFields_ = [2,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.CardGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.services.CardGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.CardGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.CardGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance),
    score: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isSequence: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isPureSequence: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isSet: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isPureSet: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    sharedWithList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    sharedWithAll: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.CardGroup}
 */
proto.services.CardGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.CardGroup;
  return proto.services.CardGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.CardGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.CardGroup}
 */
proto.services.CardGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 2:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSequence(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPureSequence(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSet(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPureSet(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSharedWith(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSharedWithAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.CardGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.CardGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.CardGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.CardGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsSequence();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsPureSequence();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsSet();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsPureSet();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSharedWithList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getSharedWithAll();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string group = 1;
 * @return {string}
 */
proto.services.CardGroup.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Card cards = 2;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.CardGroup.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 2));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.CardGroup} returns this
*/
proto.services.CardGroup.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.CardGroup.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional int32 score = 3;
 * @return {number}
 */
proto.services.CardGroup.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_sequence = 4;
 * @return {boolean}
 */
proto.services.CardGroup.prototype.getIsSequence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.setIsSequence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_pure_sequence = 5;
 * @return {boolean}
 */
proto.services.CardGroup.prototype.getIsPureSequence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.setIsPureSequence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_set = 6;
 * @return {boolean}
 */
proto.services.CardGroup.prototype.getIsSet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.setIsSet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_pure_set = 7;
 * @return {boolean}
 */
proto.services.CardGroup.prototype.getIsPureSet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.setIsPureSet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated string shared_with = 8;
 * @return {!Array<string>}
 */
proto.services.CardGroup.prototype.getSharedWithList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.setSharedWithList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.addSharedWith = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.clearSharedWithList = function() {
  return this.setSharedWithList([]);
};


/**
 * optional bool shared_with_all = 9;
 * @return {boolean}
 */
proto.services.CardGroup.prototype.getSharedWithAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.CardGroup} returns this
 */
proto.services.CardGroup.prototype.setSharedWithAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.SortedCardGroup.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.SortedCardGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.services.SortedCardGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.SortedCardGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SortedCardGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.services.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.SortedCardGroup}
 */
proto.services.SortedCardGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.SortedCardGroup;
  return proto.services.SortedCardGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.SortedCardGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.SortedCardGroup}
 */
proto.services.SortedCardGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.Card;
      reader.readMessage(value,proto.services.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.SortedCardGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.SortedCardGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.SortedCardGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.SortedCardGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.Card.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Card cards = 1;
 * @return {!Array<!proto.services.Card>}
 */
proto.services.SortedCardGroup.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.services.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.Card, 1));
};


/**
 * @param {!Array<!proto.services.Card>} value
 * @return {!proto.services.SortedCardGroup} returns this
*/
proto.services.SortedCardGroup.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.Card}
 */
proto.services.SortedCardGroup.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.SortedCardGroup} returns this
 */
proto.services.SortedCardGroup.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.Card.prototype.toObject = function(opt_includeInstance) {
  return proto.services.Card.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.Card} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Card.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rank: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pack: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isFaceUp: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    label: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.Card}
 */
proto.services.Card.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.Card;
  return proto.services.Card.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.Card} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.Card}
 */
proto.services.Card.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.services.Suits} */ (reader.readEnum());
      msg.setSuit(value);
      break;
    case 3:
      var value = /** @type {!proto.services.Ranks} */ (reader.readEnum());
      msg.setRank(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPack(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFaceUp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.Card.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.Card.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.Card} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.Card.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRank();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPack();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsFaceUp();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.services.Card.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.Card} returns this
 */
proto.services.Card.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Suits suit = 2;
 * @return {!proto.services.Suits}
 */
proto.services.Card.prototype.getSuit = function() {
  return /** @type {!proto.services.Suits} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.services.Suits} value
 * @return {!proto.services.Card} returns this
 */
proto.services.Card.prototype.setSuit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Ranks rank = 3;
 * @return {!proto.services.Ranks}
 */
proto.services.Card.prototype.getRank = function() {
  return /** @type {!proto.services.Ranks} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.Ranks} value
 * @return {!proto.services.Card} returns this
 */
proto.services.Card.prototype.setRank = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 pack = 4;
 * @return {number}
 */
proto.services.Card.prototype.getPack = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.Card} returns this
 */
proto.services.Card.prototype.setPack = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_face_up = 5;
 * @return {boolean}
 */
proto.services.Card.prototype.getIsFaceUp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.Card} returns this
 */
proto.services.Card.prototype.setIsFaceUp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.services.Card.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.Card} returns this
 */
proto.services.Card.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.GroupedCardId.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.GroupedCardId.prototype.toObject = function(opt_includeInstance) {
  return proto.services.GroupedCardId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.GroupedCardId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GroupedCardId.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cardIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.GroupedCardId}
 */
proto.services.GroupedCardId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.GroupedCardId;
  return proto.services.GroupedCardId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.GroupedCardId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.GroupedCardId}
 */
proto.services.GroupedCardId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCardIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.GroupedCardId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.GroupedCardId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.GroupedCardId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.GroupedCardId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCardIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string group = 1;
 * @return {string}
 */
proto.services.GroupedCardId.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.GroupedCardId} returns this
 */
proto.services.GroupedCardId.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string card_ids = 2;
 * @return {!Array<string>}
 */
proto.services.GroupedCardId.prototype.getCardIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.GroupedCardId} returns this
 */
proto.services.GroupedCardId.prototype.setCardIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.GroupedCardId} returns this
 */
proto.services.GroupedCardId.prototype.addCardIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.GroupedCardId} returns this
 */
proto.services.GroupedCardId.prototype.clearCardIdsList = function() {
  return this.setCardIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.DoClosedPlayerActionRequest.oneofGroups_ = [[3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]];

/**
 * @enum {number}
 */
proto.services.DoClosedPlayerActionRequest.ActionArgumentsCase = {
  ACTION_ARGUMENTS_NOT_SET: 0,
  MOVE_STAGE_ARGUMENTS: 3,
  MOVE_CARD_ARGUMENTS: 4,
  MOVE_NEXT_ARGUMENTS: 5,
  SHUFFLE_CLOSED_TRAY_ARGUMENTS: 6,
  DEAL_CLOSED_TRAY_ARGUMENTS: 7,
  DEAL_CLOSED_TRAY_ADDITIONAL_ARGUMENTS: 8,
  FOLD_HAND_ARGUMENTS: 9,
  DECLARE_SHOW_ARGUMENTS: 10,
  DECLARE_CHALLENGE_ARGUMENTS: 11,
  DECLARE_SCORE_ARGUMENTS: 12,
  END_SESSION_ARGUMENTS: 13,
  PLAYER_HAND_SHARE_CARD_GROUP_ARGUMENTS: 14,
  SHOW_TO_ALL_ARGUMENTS: 15,
  DECLARE_WINNING_AMOUNT_ARGUMENTS: 16,
  GAME_ACTION_ARGUMENTS: 17
};

/**
 * @return {proto.services.DoClosedPlayerActionRequest.ActionArgumentsCase}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getActionArgumentsCase = function() {
  return /** @type {proto.services.DoClosedPlayerActionRequest.ActionArgumentsCase} */(jspb.Message.computeOneofCase(this, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    moveStageArguments: (f = msg.getMoveStageArguments()) && proto.services.DoClosedPlayerActionRequest.MoveStageArguments.toObject(includeInstance, f),
    moveCardArguments: (f = msg.getMoveCardArguments()) && proto.services.DoClosedPlayerActionRequest.MoveCardArguments.toObject(includeInstance, f),
    moveNextArguments: (f = msg.getMoveNextArguments()) && proto.services.DoClosedPlayerActionRequest.MoveNextArguments.toObject(includeInstance, f),
    shuffleClosedTrayArguments: (f = msg.getShuffleClosedTrayArguments()) && proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.toObject(includeInstance, f),
    dealClosedTrayArguments: (f = msg.getDealClosedTrayArguments()) && proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.toObject(includeInstance, f),
    dealClosedTrayAdditionalArguments: (f = msg.getDealClosedTrayAdditionalArguments()) && proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.toObject(includeInstance, f),
    foldHandArguments: (f = msg.getFoldHandArguments()) && proto.services.DoClosedPlayerActionRequest.FoldHandArguments.toObject(includeInstance, f),
    declareShowArguments: (f = msg.getDeclareShowArguments()) && proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.toObject(includeInstance, f),
    declareChallengeArguments: (f = msg.getDeclareChallengeArguments()) && proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.toObject(includeInstance, f),
    declareScoreArguments: (f = msg.getDeclareScoreArguments()) && proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.toObject(includeInstance, f),
    endSessionArguments: (f = msg.getEndSessionArguments()) && proto.services.DoClosedPlayerActionRequest.EndSessionArguments.toObject(includeInstance, f),
    playerHandShareCardGroupArguments: (f = msg.getPlayerHandShareCardGroupArguments()) && proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.toObject(includeInstance, f),
    showToAllArguments: (f = msg.getShowToAllArguments()) && proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.toObject(includeInstance, f),
    declareWinningAmountArguments: (f = msg.getDeclareWinningAmountArguments()) && proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.toObject(includeInstance, f),
    gameActionArguments: (f = msg.getGameActionArguments()) && proto.services.DoClosedPlayerActionRequest.GameActionArguments.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest}
 */
proto.services.DoClosedPlayerActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest;
  return proto.services.DoClosedPlayerActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest}
 */
proto.services.DoClosedPlayerActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 3:
      var value = new proto.services.DoClosedPlayerActionRequest.MoveStageArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.MoveStageArguments.deserializeBinaryFromReader);
      msg.setMoveStageArguments(value);
      break;
    case 4:
      var value = new proto.services.DoClosedPlayerActionRequest.MoveCardArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.MoveCardArguments.deserializeBinaryFromReader);
      msg.setMoveCardArguments(value);
      break;
    case 5:
      var value = new proto.services.DoClosedPlayerActionRequest.MoveNextArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.MoveNextArguments.deserializeBinaryFromReader);
      msg.setMoveNextArguments(value);
      break;
    case 6:
      var value = new proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.deserializeBinaryFromReader);
      msg.setShuffleClosedTrayArguments(value);
      break;
    case 7:
      var value = new proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.deserializeBinaryFromReader);
      msg.setDealClosedTrayArguments(value);
      break;
    case 8:
      var value = new proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.deserializeBinaryFromReader);
      msg.setDealClosedTrayAdditionalArguments(value);
      break;
    case 9:
      var value = new proto.services.DoClosedPlayerActionRequest.FoldHandArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.FoldHandArguments.deserializeBinaryFromReader);
      msg.setFoldHandArguments(value);
      break;
    case 10:
      var value = new proto.services.DoClosedPlayerActionRequest.DeclareShowArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.deserializeBinaryFromReader);
      msg.setDeclareShowArguments(value);
      break;
    case 11:
      var value = new proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.deserializeBinaryFromReader);
      msg.setDeclareChallengeArguments(value);
      break;
    case 12:
      var value = new proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.deserializeBinaryFromReader);
      msg.setDeclareScoreArguments(value);
      break;
    case 13:
      var value = new proto.services.DoClosedPlayerActionRequest.EndSessionArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.EndSessionArguments.deserializeBinaryFromReader);
      msg.setEndSessionArguments(value);
      break;
    case 14:
      var value = new proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.deserializeBinaryFromReader);
      msg.setPlayerHandShareCardGroupArguments(value);
      break;
    case 15:
      var value = new proto.services.DoClosedPlayerActionRequest.ShowToAllArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.deserializeBinaryFromReader);
      msg.setShowToAllArguments(value);
      break;
    case 16:
      var value = new proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.deserializeBinaryFromReader);
      msg.setDeclareWinningAmountArguments(value);
      break;
    case 17:
      var value = new proto.services.DoClosedPlayerActionRequest.GameActionArguments;
      reader.readMessage(value,proto.services.DoClosedPlayerActionRequest.GameActionArguments.deserializeBinaryFromReader);
      msg.setGameActionArguments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMoveStageArguments();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.services.DoClosedPlayerActionRequest.MoveStageArguments.serializeBinaryToWriter
    );
  }
  f = message.getMoveCardArguments();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.services.DoClosedPlayerActionRequest.MoveCardArguments.serializeBinaryToWriter
    );
  }
  f = message.getMoveNextArguments();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.services.DoClosedPlayerActionRequest.MoveNextArguments.serializeBinaryToWriter
    );
  }
  f = message.getShuffleClosedTrayArguments();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.serializeBinaryToWriter
    );
  }
  f = message.getDealClosedTrayArguments();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.serializeBinaryToWriter
    );
  }
  f = message.getDealClosedTrayAdditionalArguments();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.serializeBinaryToWriter
    );
  }
  f = message.getFoldHandArguments();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.services.DoClosedPlayerActionRequest.FoldHandArguments.serializeBinaryToWriter
    );
  }
  f = message.getDeclareShowArguments();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.serializeBinaryToWriter
    );
  }
  f = message.getDeclareChallengeArguments();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.serializeBinaryToWriter
    );
  }
  f = message.getDeclareScoreArguments();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.serializeBinaryToWriter
    );
  }
  f = message.getEndSessionArguments();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.services.DoClosedPlayerActionRequest.EndSessionArguments.serializeBinaryToWriter
    );
  }
  f = message.getPlayerHandShareCardGroupArguments();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.serializeBinaryToWriter
    );
  }
  f = message.getShowToAllArguments();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.serializeBinaryToWriter
    );
  }
  f = message.getDeclareWinningAmountArguments();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.serializeBinaryToWriter
    );
  }
  f = message.getGameActionArguments();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.services.DoClosedPlayerActionRequest.GameActionArguments.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    group: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sharedWithList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    sharedWithAll: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    remove: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments}
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments;
  return proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments}
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSharedWith(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSharedWithAll(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemove(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSharedWithList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getSharedWithAll();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRemove();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string group = 1;
 * @return {string}
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string shared_with = 2;
 * @return {!Array<string>}
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.getSharedWithList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.setSharedWithList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.addSharedWith = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.clearSharedWithList = function() {
  return this.setSharedWithList([]);
};


/**
 * optional bool shared_with_all = 3;
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.getSharedWithAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.setSharedWithAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool remove = 4;
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.getRemove = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments.prototype.setRemove = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.MoveStageArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveStageArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    stage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveStageArguments}
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.MoveStageArguments;
  return proto.services.DoClosedPlayerActionRequest.MoveStageArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveStageArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveStageArguments}
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.services.TableStates} */ (reader.readEnum());
      msg.setStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.MoveStageArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveStageArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional TableStates stage = 1;
 * @return {!proto.services.TableStates}
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments.prototype.getStage = function() {
  return /** @type {!proto.services.TableStates} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.services.TableStates} value
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveStageArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.MoveStageArguments.prototype.setStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.SourceCardTypeCase = {
  SOURCE_CARD_TYPE_NOT_SET: 0,
  SOURCE_CARD_INDEX: 2,
  SOURCE_CARD_ID: 3
};

/**
 * @return {proto.services.DoClosedPlayerActionRequest.MoveCardArguments.SourceCardTypeCase}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.getSourceCardTypeCase = function() {
  return /** @type {proto.services.DoClosedPlayerActionRequest.MoveCardArguments.SourceCardTypeCase} */(jspb.Message.computeOneofCase(this, proto.services.DoClosedPlayerActionRequest.MoveCardArguments.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.MoveCardArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceTrayType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourceCardIndex: (f = msg.getSourceCardIndex()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    sourceCardId: (f = msg.getSourceCardId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    targetTrayType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    targetGroupName: (f = msg.getTargetGroupName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    moveToPosition: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.MoveCardArguments;
  return proto.services.DoClosedPlayerActionRequest.MoveCardArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.services.TrayTypes} */ (reader.readEnum());
      msg.setSourceTrayType(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setSourceCardIndex(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSourceCardId(value);
      break;
    case 4:
      var value = /** @type {!proto.services.TrayTypes} */ (reader.readEnum());
      msg.setTargetTrayType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTargetGroupName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMoveToPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.MoveCardArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceTrayType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSourceCardIndex();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSourceCardId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTargetTrayType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTargetGroupName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMoveToPosition();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional TrayTypes source_tray_type = 1;
 * @return {!proto.services.TrayTypes}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.getSourceTrayType = function() {
  return /** @type {!proto.services.TrayTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.services.TrayTypes} value
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.setSourceTrayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value source_card_index = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.getSourceCardIndex = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
*/
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.setSourceCardIndex = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.DoClosedPlayerActionRequest.MoveCardArguments.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.clearSourceCardIndex = function() {
  return this.setSourceCardIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.hasSourceCardIndex = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue source_card_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.getSourceCardId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
*/
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.setSourceCardId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.DoClosedPlayerActionRequest.MoveCardArguments.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.clearSourceCardId = function() {
  return this.setSourceCardId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.hasSourceCardId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TrayTypes target_tray_type = 4;
 * @return {!proto.services.TrayTypes}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.getTargetTrayType = function() {
  return /** @type {!proto.services.TrayTypes} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.services.TrayTypes} value
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.setTargetTrayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue target_group_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.getTargetGroupName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
*/
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.setTargetGroupName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.clearTargetGroupName = function() {
  return this.setTargetGroupName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.hasTargetGroupName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 move_to_position = 6;
 * @return {number}
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.getMoveToPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveCardArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.MoveCardArguments.prototype.setMoveToPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.GameActionArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.GameActionArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameDefId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    betAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.GameActionArguments}
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.GameActionArguments;
  return proto.services.DoClosedPlayerActionRequest.GameActionArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.GameActionArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.GameActionArguments}
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameDefId(value);
      break;
    case 3:
      var value = /** @type {!proto.services.PlayerActions} */ (reader.readEnum());
      msg.setActionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBetAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.GameActionArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.GameActionArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameDefId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionId();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBetAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoClosedPlayerActionRequest.GameActionArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_def_id = 2;
 * @return {string}
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.getGameDefId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoClosedPlayerActionRequest.GameActionArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.setGameDefId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PlayerActions action_id = 3;
 * @return {!proto.services.PlayerActions}
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.getActionId = function() {
  return /** @type {!proto.services.PlayerActions} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.services.PlayerActions} value
 * @return {!proto.services.DoClosedPlayerActionRequest.GameActionArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.setActionId = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double bet_amount = 4;
 * @return {number}
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.getBetAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoClosedPlayerActionRequest.GameActionArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.GameActionArguments.prototype.setBetAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.MoveNextArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.MoveNextArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveNextArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.MoveNextArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveNextArguments}
 */
proto.services.DoClosedPlayerActionRequest.MoveNextArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.MoveNextArguments;
  return proto.services.DoClosedPlayerActionRequest.MoveNextArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveNextArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.MoveNextArguments}
 */
proto.services.DoClosedPlayerActionRequest.MoveNextArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.MoveNextArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.MoveNextArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.MoveNextArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.MoveNextArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments}
 */
proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments;
  return proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments}
 */
proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments;
  return proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    isFaceUp: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    noOfCards: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments;
  return proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFaceUp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoOfCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsFaceUp();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getNoOfCards();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool is_face_up = 1;
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.prototype.getIsFaceUp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.prototype.setIsFaceUp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 no_of_cards = 2;
 * @return {number}
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.prototype.getNoOfCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments.prototype.setNoOfCards = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.FoldHandArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.FoldHandArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.FoldHandArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.FoldHandArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.FoldHandArguments}
 */
proto.services.DoClosedPlayerActionRequest.FoldHandArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.FoldHandArguments;
  return proto.services.DoClosedPlayerActionRequest.FoldHandArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.FoldHandArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.FoldHandArguments}
 */
proto.services.DoClosedPlayerActionRequest.FoldHandArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.FoldHandArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.FoldHandArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.FoldHandArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.FoldHandArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.ShowToAllArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.ShowToAllArguments}
 */
proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.ShowToAllArguments;
  return proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.ShowToAllArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.ShowToAllArguments}
 */
proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.ShowToAllArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.ShowToAllArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareShowArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareShowArguments}
 */
proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.DeclareShowArguments;
  return proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareShowArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareShowArguments}
 */
proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareShowArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DeclareShowArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasWon: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    score: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments}
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments;
  return proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments}
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasWon(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasWon();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool has_won = 1;
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.prototype.getHasWon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.prototype.setHasWon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 score = 3;
 * @return {number}
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    forPlayerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments}
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments;
  return proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments}
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForPlayerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * optional string for_player_id = 1;
 * @return {string}
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.prototype.getForPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.prototype.setForPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sint32 score = 2;
 * @return {number}
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    forPlayerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amountWon: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments}
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments;
  return proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments}
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForPlayerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmountWon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmountWon();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string for_player_id = 1;
 * @return {string}
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.prototype.getForPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.prototype.setForPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount_won = 2;
 * @return {number}
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.prototype.getAmountWon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments} returns this
 */
proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments.prototype.setAmountWon = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoClosedPlayerActionRequest.EndSessionArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoClosedPlayerActionRequest.EndSessionArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoClosedPlayerActionRequest.EndSessionArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.EndSessionArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoClosedPlayerActionRequest.EndSessionArguments}
 */
proto.services.DoClosedPlayerActionRequest.EndSessionArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoClosedPlayerActionRequest.EndSessionArguments;
  return proto.services.DoClosedPlayerActionRequest.EndSessionArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoClosedPlayerActionRequest.EndSessionArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoClosedPlayerActionRequest.EndSessionArguments}
 */
proto.services.DoClosedPlayerActionRequest.EndSessionArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoClosedPlayerActionRequest.EndSessionArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoClosedPlayerActionRequest.EndSessionArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoClosedPlayerActionRequest.EndSessionArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoClosedPlayerActionRequest.EndSessionArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string player_id = 2;
 * @return {string}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MoveStageArguments move_stage_arguments = 3;
 * @return {?proto.services.DoClosedPlayerActionRequest.MoveStageArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getMoveStageArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.MoveStageArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.MoveStageArguments, 3));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.MoveStageArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setMoveStageArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearMoveStageArguments = function() {
  return this.setMoveStageArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasMoveStageArguments = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MoveCardArguments move_card_arguments = 4;
 * @return {?proto.services.DoClosedPlayerActionRequest.MoveCardArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getMoveCardArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.MoveCardArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.MoveCardArguments, 4));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.MoveCardArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setMoveCardArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearMoveCardArguments = function() {
  return this.setMoveCardArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasMoveCardArguments = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MoveNextArguments move_next_arguments = 5;
 * @return {?proto.services.DoClosedPlayerActionRequest.MoveNextArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getMoveNextArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.MoveNextArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.MoveNextArguments, 5));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.MoveNextArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setMoveNextArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearMoveNextArguments = function() {
  return this.setMoveNextArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasMoveNextArguments = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ShuffleClosedTrayArguments shuffle_closed_tray_arguments = 6;
 * @return {?proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getShuffleClosedTrayArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments, 6));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.ShuffleClosedTrayArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setShuffleClosedTrayArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearShuffleClosedTrayArguments = function() {
  return this.setShuffleClosedTrayArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasShuffleClosedTrayArguments = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DealClosedTrayArguments deal_closed_tray_arguments = 7;
 * @return {?proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getDealClosedTrayArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments, 7));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.DealClosedTrayArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setDealClosedTrayArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearDealClosedTrayArguments = function() {
  return this.setDealClosedTrayArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasDealClosedTrayArguments = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DealClosedTrayAdditionalArguments deal_closed_tray_additional_arguments = 8;
 * @return {?proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getDealClosedTrayAdditionalArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments, 8));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.DealClosedTrayAdditionalArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setDealClosedTrayAdditionalArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearDealClosedTrayAdditionalArguments = function() {
  return this.setDealClosedTrayAdditionalArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasDealClosedTrayAdditionalArguments = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional FoldHandArguments fold_hand_arguments = 9;
 * @return {?proto.services.DoClosedPlayerActionRequest.FoldHandArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getFoldHandArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.FoldHandArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.FoldHandArguments, 9));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.FoldHandArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setFoldHandArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearFoldHandArguments = function() {
  return this.setFoldHandArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasFoldHandArguments = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DeclareShowArguments declare_show_arguments = 10;
 * @return {?proto.services.DoClosedPlayerActionRequest.DeclareShowArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getDeclareShowArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.DeclareShowArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.DeclareShowArguments, 10));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.DeclareShowArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setDeclareShowArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearDeclareShowArguments = function() {
  return this.setDeclareShowArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasDeclareShowArguments = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DeclareChallengeArguments declare_challenge_arguments = 11;
 * @return {?proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getDeclareChallengeArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments, 11));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.DeclareChallengeArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setDeclareChallengeArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearDeclareChallengeArguments = function() {
  return this.setDeclareChallengeArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasDeclareChallengeArguments = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DeclareScoreArguments declare_score_arguments = 12;
 * @return {?proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getDeclareScoreArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments, 12));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.DeclareScoreArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setDeclareScoreArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearDeclareScoreArguments = function() {
  return this.setDeclareScoreArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasDeclareScoreArguments = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional EndSessionArguments end_session_arguments = 13;
 * @return {?proto.services.DoClosedPlayerActionRequest.EndSessionArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getEndSessionArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.EndSessionArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.EndSessionArguments, 13));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.EndSessionArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setEndSessionArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearEndSessionArguments = function() {
  return this.setEndSessionArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasEndSessionArguments = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PlayerHandShareCardGroupArguments player_hand_share_card_group_arguments = 14;
 * @return {?proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getPlayerHandShareCardGroupArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments, 14));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.PlayerHandShareCardGroupArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setPlayerHandShareCardGroupArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearPlayerHandShareCardGroupArguments = function() {
  return this.setPlayerHandShareCardGroupArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasPlayerHandShareCardGroupArguments = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ShowToAllArguments show_to_all_arguments = 15;
 * @return {?proto.services.DoClosedPlayerActionRequest.ShowToAllArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getShowToAllArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.ShowToAllArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.ShowToAllArguments, 15));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.ShowToAllArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setShowToAllArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearShowToAllArguments = function() {
  return this.setShowToAllArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasShowToAllArguments = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional DeclareWinningAmountArguments declare_winning_amount_arguments = 16;
 * @return {?proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getDeclareWinningAmountArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments, 16));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.DeclareWinningAmountArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setDeclareWinningAmountArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearDeclareWinningAmountArguments = function() {
  return this.setDeclareWinningAmountArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasDeclareWinningAmountArguments = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional GameActionArguments game_action_arguments = 17;
 * @return {?proto.services.DoClosedPlayerActionRequest.GameActionArguments}
 */
proto.services.DoClosedPlayerActionRequest.prototype.getGameActionArguments = function() {
  return /** @type{?proto.services.DoClosedPlayerActionRequest.GameActionArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoClosedPlayerActionRequest.GameActionArguments, 17));
};


/**
 * @param {?proto.services.DoClosedPlayerActionRequest.GameActionArguments|undefined} value
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
*/
proto.services.DoClosedPlayerActionRequest.prototype.setGameActionArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.services.DoClosedPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoClosedPlayerActionRequest} returns this
 */
proto.services.DoClosedPlayerActionRequest.prototype.clearGameActionArguments = function() {
  return this.setGameActionArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoClosedPlayerActionRequest.prototype.hasGameActionArguments = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.DoOpenPlayerActionRequest.oneofGroups_ = [[4,5,6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.services.DoOpenPlayerActionRequest.ActionArgumentsCase = {
  ACTION_ARGUMENTS_NOT_SET: 0,
  REARRANGE_HAND_ARGUMENTS: 4,
  MELD_PLAYER_HAND_BY_SUIT_ARGUMENTS: 5,
  MELD_PLAYER_HAND_BY_VALUE_ARGUMENTS: 6,
  UNBLIND_HAND_ARGUMENTS: 7,
  ADD_TO_POT_ARGUMENTS: 8,
  BUY_IN_CURRENCY_ARGUMENTS: 9,
  TOP_UP_CURRENCY_ARGUMENTS: 10,
  CHANGE_VARIANT_ARGUMENTS: 11
};

/**
 * @return {proto.services.DoOpenPlayerActionRequest.ActionArgumentsCase}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getActionArgumentsCase = function() {
  return /** @type {proto.services.DoOpenPlayerActionRequest.ActionArgumentsCase} */(jspb.Message.computeOneofCase(this, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rearrangeHandArguments: (f = msg.getRearrangeHandArguments()) && proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.toObject(includeInstance, f),
    meldPlayerHandBySuitArguments: (f = msg.getMeldPlayerHandBySuitArguments()) && proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.toObject(includeInstance, f),
    meldPlayerHandByValueArguments: (f = msg.getMeldPlayerHandByValueArguments()) && proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.toObject(includeInstance, f),
    unblindHandArguments: (f = msg.getUnblindHandArguments()) && proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.toObject(includeInstance, f),
    addToPotArguments: (f = msg.getAddToPotArguments()) && proto.services.DoOpenPlayerActionRequest.AddToPotArguments.toObject(includeInstance, f),
    buyInCurrencyArguments: (f = msg.getBuyInCurrencyArguments()) && proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.toObject(includeInstance, f),
    topUpCurrencyArguments: (f = msg.getTopUpCurrencyArguments()) && proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.toObject(includeInstance, f),
    changeVariantArguments: (f = msg.getChangeVariantArguments()) && proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest}
 */
proto.services.DoOpenPlayerActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest;
  return proto.services.DoOpenPlayerActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest}
 */
proto.services.DoOpenPlayerActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 4:
      var value = new proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments;
      reader.readMessage(value,proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.deserializeBinaryFromReader);
      msg.setRearrangeHandArguments(value);
      break;
    case 5:
      var value = new proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments;
      reader.readMessage(value,proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.deserializeBinaryFromReader);
      msg.setMeldPlayerHandBySuitArguments(value);
      break;
    case 6:
      var value = new proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments;
      reader.readMessage(value,proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.deserializeBinaryFromReader);
      msg.setMeldPlayerHandByValueArguments(value);
      break;
    case 7:
      var value = new proto.services.DoOpenPlayerActionRequest.UnblindHandArguments;
      reader.readMessage(value,proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.deserializeBinaryFromReader);
      msg.setUnblindHandArguments(value);
      break;
    case 8:
      var value = new proto.services.DoOpenPlayerActionRequest.AddToPotArguments;
      reader.readMessage(value,proto.services.DoOpenPlayerActionRequest.AddToPotArguments.deserializeBinaryFromReader);
      msg.setAddToPotArguments(value);
      break;
    case 9:
      var value = new proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments;
      reader.readMessage(value,proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.deserializeBinaryFromReader);
      msg.setBuyInCurrencyArguments(value);
      break;
    case 10:
      var value = new proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments;
      reader.readMessage(value,proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.deserializeBinaryFromReader);
      msg.setTopUpCurrencyArguments(value);
      break;
    case 11:
      var value = new proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments;
      reader.readMessage(value,proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.deserializeBinaryFromReader);
      msg.setChangeVariantArguments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRearrangeHandArguments();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.serializeBinaryToWriter
    );
  }
  f = message.getMeldPlayerHandBySuitArguments();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.serializeBinaryToWriter
    );
  }
  f = message.getMeldPlayerHandByValueArguments();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.serializeBinaryToWriter
    );
  }
  f = message.getUnblindHandArguments();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.serializeBinaryToWriter
    );
  }
  f = message.getAddToPotArguments();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.services.DoOpenPlayerActionRequest.AddToPotArguments.serializeBinaryToWriter
    );
  }
  f = message.getBuyInCurrencyArguments();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.serializeBinaryToWriter
    );
  }
  f = message.getTopUpCurrencyArguments();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.serializeBinaryToWriter
    );
  }
  f = message.getChangeVariantArguments();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.services.GroupedCardId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments}
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments;
  return proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments}
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.services.GroupedCardId;
      reader.readMessage(value,proto.services.GroupedCardId.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.services.GroupedCardId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GroupedCardId groups = 1;
 * @return {!Array<!proto.services.GroupedCardId>}
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.services.GroupedCardId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.services.GroupedCardId, 1));
};


/**
 * @param {!Array<!proto.services.GroupedCardId>} value
 * @return {!proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments} returns this
*/
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.services.GroupedCardId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.services.GroupedCardId}
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.services.GroupedCardId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments}
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments;
  return proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments}
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments}
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments;
  return proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments}
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest.UnblindHandArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest.UnblindHandArguments}
 */
proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest.UnblindHandArguments;
  return proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest.UnblindHandArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest.UnblindHandArguments}
 */
proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest.UnblindHandArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.UnblindHandArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.AddToPotArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest.AddToPotArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest.AddToPotArguments}
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest.AddToPotArguments;
  return proto.services.DoOpenPlayerActionRequest.AddToPotArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest.AddToPotArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest.AddToPotArguments}
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.AddToPotArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest.AddToPotArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoOpenPlayerActionRequest.AddToPotArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.AddToPotArguments.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments}
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments;
  return proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments}
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments}
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments;
  return proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments}
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.toObject = function(includeInstance, msg) {
  var f, obj = {
    variant: jspb.Message.getFieldWithDefault(msg, 3, 0),
    highOrLow: jspb.Message.getFieldWithDefault(msg, 4, 0),
    jokerPresent: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bustPresent: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    bettingLimit: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments;
  return proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVariant(value);
      break;
    case 4:
      var value = /** @type {!proto.services.HiLoTypes} */ (reader.readEnum());
      msg.setHighOrLow(value);
      break;
    case 5:
      var value = /** @type {!proto.services.JokerTypes} */ (reader.readEnum());
      msg.setJokerPresent(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBustPresent(value);
      break;
    case 7:
      var value = /** @type {!proto.services.BettingLimits} */ (reader.readEnum());
      msg.setBettingLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariant();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHighOrLow();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJokerPresent();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBustPresent();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getBettingLimit();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional int32 variant = 3;
 * @return {number}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.getVariant = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.setVariant = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional HiLoTypes high_or_low = 4;
 * @return {!proto.services.HiLoTypes}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.getHighOrLow = function() {
  return /** @type {!proto.services.HiLoTypes} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.services.HiLoTypes} value
 * @return {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.setHighOrLow = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional JokerTypes joker_present = 5;
 * @return {!proto.services.JokerTypes}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.getJokerPresent = function() {
  return /** @type {!proto.services.JokerTypes} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.services.JokerTypes} value
 * @return {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.setJokerPresent = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool bust_present = 6;
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.getBustPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.setBustPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional BettingLimits betting_limit = 7;
 * @return {!proto.services.BettingLimits}
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.getBettingLimit = function() {
  return /** @type {!proto.services.BettingLimits} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.services.BettingLimits} value
 * @return {!proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} returns this
 */
proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments.prototype.setBettingLimit = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string game_id = 2;
 * @return {string}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RearrangeHandArguments rearrange_hand_arguments = 4;
 * @return {?proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getRearrangeHandArguments = function() {
  return /** @type{?proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments, 4));
};


/**
 * @param {?proto.services.DoOpenPlayerActionRequest.RearrangeHandArguments|undefined} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
*/
proto.services.DoOpenPlayerActionRequest.prototype.setRearrangeHandArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.clearRearrangeHandArguments = function() {
  return this.setRearrangeHandArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.prototype.hasRearrangeHandArguments = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MeldPlayerHand_BySuitArguments meld_player_hand_by_suit_arguments = 5;
 * @return {?proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getMeldPlayerHandBySuitArguments = function() {
  return /** @type{?proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments, 5));
};


/**
 * @param {?proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_BySuitArguments|undefined} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
*/
proto.services.DoOpenPlayerActionRequest.prototype.setMeldPlayerHandBySuitArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.clearMeldPlayerHandBySuitArguments = function() {
  return this.setMeldPlayerHandBySuitArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.prototype.hasMeldPlayerHandBySuitArguments = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MeldPlayerHand_ByValueArguments meld_player_hand_by_value_arguments = 6;
 * @return {?proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getMeldPlayerHandByValueArguments = function() {
  return /** @type{?proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments, 6));
};


/**
 * @param {?proto.services.DoOpenPlayerActionRequest.MeldPlayerHand_ByValueArguments|undefined} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
*/
proto.services.DoOpenPlayerActionRequest.prototype.setMeldPlayerHandByValueArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.clearMeldPlayerHandByValueArguments = function() {
  return this.setMeldPlayerHandByValueArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.prototype.hasMeldPlayerHandByValueArguments = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UnblindHandArguments unblind_hand_arguments = 7;
 * @return {?proto.services.DoOpenPlayerActionRequest.UnblindHandArguments}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getUnblindHandArguments = function() {
  return /** @type{?proto.services.DoOpenPlayerActionRequest.UnblindHandArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenPlayerActionRequest.UnblindHandArguments, 7));
};


/**
 * @param {?proto.services.DoOpenPlayerActionRequest.UnblindHandArguments|undefined} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
*/
proto.services.DoOpenPlayerActionRequest.prototype.setUnblindHandArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.clearUnblindHandArguments = function() {
  return this.setUnblindHandArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.prototype.hasUnblindHandArguments = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AddToPotArguments add_to_pot_arguments = 8;
 * @return {?proto.services.DoOpenPlayerActionRequest.AddToPotArguments}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getAddToPotArguments = function() {
  return /** @type{?proto.services.DoOpenPlayerActionRequest.AddToPotArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenPlayerActionRequest.AddToPotArguments, 8));
};


/**
 * @param {?proto.services.DoOpenPlayerActionRequest.AddToPotArguments|undefined} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
*/
proto.services.DoOpenPlayerActionRequest.prototype.setAddToPotArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.clearAddToPotArguments = function() {
  return this.setAddToPotArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.prototype.hasAddToPotArguments = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional BuyInCurrencyArguments buy_in_currency_arguments = 9;
 * @return {?proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getBuyInCurrencyArguments = function() {
  return /** @type{?proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments, 9));
};


/**
 * @param {?proto.services.DoOpenPlayerActionRequest.BuyInCurrencyArguments|undefined} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
*/
proto.services.DoOpenPlayerActionRequest.prototype.setBuyInCurrencyArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.clearBuyInCurrencyArguments = function() {
  return this.setBuyInCurrencyArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.prototype.hasBuyInCurrencyArguments = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TopupCurrencyArguments top_up_currency_arguments = 10;
 * @return {?proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getTopUpCurrencyArguments = function() {
  return /** @type{?proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments, 10));
};


/**
 * @param {?proto.services.DoOpenPlayerActionRequest.TopupCurrencyArguments|undefined} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
*/
proto.services.DoOpenPlayerActionRequest.prototype.setTopUpCurrencyArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.clearTopUpCurrencyArguments = function() {
  return this.setTopUpCurrencyArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.prototype.hasTopUpCurrencyArguments = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ChangeVariantArguments change_variant_arguments = 11;
 * @return {?proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments}
 */
proto.services.DoOpenPlayerActionRequest.prototype.getChangeVariantArguments = function() {
  return /** @type{?proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments, 11));
};


/**
 * @param {?proto.services.DoOpenPlayerActionRequest.ChangeVariantArguments|undefined} value
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
*/
proto.services.DoOpenPlayerActionRequest.prototype.setChangeVariantArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.services.DoOpenPlayerActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenPlayerActionRequest} returns this
 */
proto.services.DoOpenPlayerActionRequest.prototype.clearChangeVariantArguments = function() {
  return this.setChangeVariantArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenPlayerActionRequest.prototype.hasChangeVariantArguments = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.services.DoOpenTableActionRequest.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.services.DoOpenTableActionRequest.ActionArgumentsCase = {
  ACTION_ARGUMENTS_NOT_SET: 0,
  SCORE_RECALCULATE_ARGUMENTS: 2
};

/**
 * @return {proto.services.DoOpenTableActionRequest.ActionArgumentsCase}
 */
proto.services.DoOpenTableActionRequest.prototype.getActionArgumentsCase = function() {
  return /** @type {proto.services.DoOpenTableActionRequest.ActionArgumentsCase} */(jspb.Message.computeOneofCase(this, proto.services.DoOpenTableActionRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenTableActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenTableActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenTableActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenTableActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scoreRecalculateArguments: (f = msg.getScoreRecalculateArguments()) && proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenTableActionRequest}
 */
proto.services.DoOpenTableActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenTableActionRequest;
  return proto.services.DoOpenTableActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenTableActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenTableActionRequest}
 */
proto.services.DoOpenTableActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGameId(value);
      break;
    case 2:
      var value = new proto.services.DoOpenTableActionRequest.Score_RecalculateArguments;
      reader.readMessage(value,proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.deserializeBinaryFromReader);
      msg.setScoreRecalculateArguments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenTableActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenTableActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenTableActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenTableActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScoreRecalculateArguments();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.prototype.toObject = function(opt_includeInstance) {
  return proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.services.DoOpenTableActionRequest.Score_RecalculateArguments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.services.DoOpenTableActionRequest.Score_RecalculateArguments}
 */
proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.services.DoOpenTableActionRequest.Score_RecalculateArguments;
  return proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.services.DoOpenTableActionRequest.Score_RecalculateArguments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.services.DoOpenTableActionRequest.Score_RecalculateArguments}
 */
proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.services.DoOpenTableActionRequest.Score_RecalculateArguments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.services.DoOpenTableActionRequest.Score_RecalculateArguments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional string game_id = 1;
 * @return {string}
 */
proto.services.DoOpenTableActionRequest.prototype.getGameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.services.DoOpenTableActionRequest} returns this
 */
proto.services.DoOpenTableActionRequest.prototype.setGameId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Score_RecalculateArguments score_recalculate_arguments = 2;
 * @return {?proto.services.DoOpenTableActionRequest.Score_RecalculateArguments}
 */
proto.services.DoOpenTableActionRequest.prototype.getScoreRecalculateArguments = function() {
  return /** @type{?proto.services.DoOpenTableActionRequest.Score_RecalculateArguments} */ (
    jspb.Message.getWrapperField(this, proto.services.DoOpenTableActionRequest.Score_RecalculateArguments, 2));
};


/**
 * @param {?proto.services.DoOpenTableActionRequest.Score_RecalculateArguments|undefined} value
 * @return {!proto.services.DoOpenTableActionRequest} returns this
*/
proto.services.DoOpenTableActionRequest.prototype.setScoreRecalculateArguments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.services.DoOpenTableActionRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.services.DoOpenTableActionRequest} returns this
 */
proto.services.DoOpenTableActionRequest.prototype.clearScoreRecalculateArguments = function() {
  return this.setScoreRecalculateArguments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.services.DoOpenTableActionRequest.prototype.hasScoreRecalculateArguments = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.services.PlayerActions = {
  ID_BEGIN_GAME: 0,
  ID_START_DEAL: 1,
  ID_PLAY_NEXT: 2,
  ID_DEAL_NEXT_ROUND: 3,
  ID_FOLD: 4,
  ID_SHOW: 5,
  ID_ACCEPT_SHOW: 6,
  ID_REJECT_SHOW: 7,
  ID_ACCEPT_REJECTION: 8,
  ID_CHALLENGE_REJECTION: 9,
  ID_CHECK: 10,
  ID_CHAAL: 11,
  ID_RAISE: 12,
  ID_SIDE_SHOW: 13,
  ID_PLAY_NEXT_ROUND: 14,
  ID_UNBLIND: 15,
  ID_FACEUP_DEAL: 16,
  ID_FACEDOWN_DEAL: 17,
  ID_GROUP_CARDS: 18,
  ID_SHOW_CARDS: 19,
  ID_SITOUT: 20,
  ID_IAM_BACK: 21,
  ID_END_SESSION: 22,
  ID_CHANGE_GAME: 23,
  ID_DECLARE_SCORE: 24,
  ID_TOPUP: 25,
  ID_DISCARD: 26,
  ID_DECLARE: 27,
  ID_INVITE: 28,
  ID_CHANGED_PASSWORD: 29,
  ID_SAVE: 30,
  ID_VIEW_REPORT: 31,
  ID_CHANGE_VARIANT: 32,
  ID_MERGE_CARDS: 33,
  ID_RUN_IT_ONCE: 34,
  ID_RUN_IT_TWICE: 35,
  ID_RUN_IT_THRICE: 36,
  ID_RUN_IT_TWOPLUSONE: 37
};

/**
 * @enum {number}
 */
proto.services.GameStates = {
  CREATED: 0,
  INLOBBY: 10,
  INPROGRESS: 20,
  COMPLETED: 30
};

/**
 * @enum {number}
 */
proto.services.ParticipantStates = {
  INVITED: 0,
  WAITING: 1,
  WAITINGTOBUYIN: 2,
  WAITINGTOPLAY: 3,
  PLAYING: 4,
  SITTINGOUT: 5,
  DISCONNECTED: 6,
  EXITED: 7
};

/**
 * @enum {number}
 */
proto.services.ParticipantHandStates = {
  INPLAY: 0,
  ALLIN: 2,
  DECLARED: 3,
  DROPPED: 4,
  WON: 5,
  NOTDEALT: 6,
  UNKNOWNHAND: 7
};

/**
 * @enum {number}
 */
proto.services.PokerActions = {
  NOACTION: 0,
  POSTSMALLBLIND: 1,
  POSTBIGBLIND: 2,
  POST: 3,
  FOLD: 4,
  CHECK: 5,
  CALL: 6,
  BET: 7,
  RAISE: 8
};

/**
 * @enum {number}
 */
proto.services.JokerTypes = {
  OPEN: 0,
  CLOSED: 1,
  NOJOKER: 2
};

/**
 * @enum {number}
 */
proto.services.DealModes = {
  FACEUP: 0,
  FACEDOWN: 1,
  BOTH: 2,
  NONE: 3
};

/**
 * @enum {number}
 */
proto.services.ShuffleModes = {
  FULL: 0,
  FAST: 1,
  AMATEUR: 2
};

/**
 * @enum {number}
 */
proto.services.GameType = {
  RUMMY: 0,
  TEENPATTI: 1,
  POKER: 2,
  ANY: 3
};

/**
 * @enum {number}
 */
proto.services.HiLoTypes = {
  HIGHONLY: 0,
  LOWONLY: 1,
  HIGHLOW: 2,
  HIGHLOWDECLARE: 3
};

/**
 * @enum {number}
 */
proto.services.BettingLimits = {
  FIXEDLIMIT: 0,
  POTLIMIT: 1,
  NOLIMIT: 2
};

/**
 * @enum {number}
 */
proto.services.Suits = {
  HIDDEN: 0,
  JOKER: 1,
  HEARTS: 2,
  DIAMONDS: 3,
  CLUBS: 4,
  SPADES: 5
};

/**
 * @enum {number}
 */
proto.services.Ranks = {
  UNKNOWN: 0,
  C1: 1,
  C2: 2,
  C3: 3,
  C4: 4,
  C5: 5,
  C6: 6,
  C7: 7,
  C8: 8,
  C9: 9,
  C10: 10,
  C11: 11,
  C12: 12,
  C13: 13,
  DUMMY: 14
};

/**
 * @enum {number}
 */
proto.services.TableStates = {
  UNSPECIFIEDTABLEACTION: 0,
  BEGIN: 1,
  PICKTOSEAT: 2,
  DEAL: 3,
  PLAY: 4,
  SHOW: 5,
  DECLARE: 6,
  ROUNDCOMPLETE: 7
};

/**
 * @enum {number}
 */
proto.services.TrayTypes = {
  NO_TRAY: 0,
  SEAT_PICK_TRAY: 1,
  DEAL_TRAY: 2,
  DISCARD_TRAY: 3,
  JOKER_TRAY: 4,
  PLAYERHAND_TRAY: 5,
  DECLARE_TRAY: 6,
  BUST_TRAY: 7,
  BURN_TRAY: 8,
  COMMUNITY_TRAY: 9,
  COMMUNITY_TRAY2: 10,
  COMMUNITY_TRAY3: 11
};

/**
 * @enum {number}
 */
proto.services.ClosedPlayerActions = {
  UNSPECIFIEDTRAYACTION: 0,
  MOVECARD: 1,
  SHUFFLECLOSEDTRAY: 2,
  DEALCLOSEDTRAY: 3
};

/**
 * @enum {number}
 */
proto.services.OpenPlayerActions = {
  MELDPLAYERHAND_BYSUIT: 0,
  MELDPLAYERHAND_BYVALUE: 1
};

goog.object.extend(exports, proto.services);
