import { nanoid } from 'nanoid'

const ID_TOKEN_KEY = "token";
const ID_RTOKEN_KEY = "rtoken";
const ID_USERINFO_KEY = "userinfo";
const ID_COOKIE_ACCEPTED = "cookie:accepted";
const ID_CLIENT_UNIQUEID = "cid";

function getClientId() {
	let clientId = localStorage.getItem(ID_CLIENT_UNIQUEID);
	if (!clientId) {
		clientId = nanoid();
		localStorage.setItem(ID_CLIENT_UNIQUEID, clientId);
	}
	return clientId;
}

function isCookieAccepted() {
	return localStorage.getItem(ID_COOKIE_ACCEPTED) == 'true'
}

function getToken() {
	return localStorage.getItem(ID_TOKEN_KEY) ?? sessionStorage.getItem(ID_TOKEN_KEY)
}

function saveToken(token) {
	localStorage.setItem(ID_TOKEN_KEY, token)
}

function saveSessionToken(token) {
	sessionStorage.setItem(ID_TOKEN_KEY, token)
}

function getRToken() {
	return localStorage.getItem(ID_RTOKEN_KEY) ?? sessionStorage.getItem(ID_RTOKEN_KEY)
}

function saveRToken(token) {
	localStorage.setItem(ID_RTOKEN_KEY, token)
}

function getUserInfo() {
	let value;
	if ((value = localStorage.getItem(ID_USERINFO_KEY)) != null) {
		return JSON.parse(value)
	}
	else if ((value = sessionStorage.getItem(ID_USERINFO_KEY)) != null) {
		return JSON.parse(value)
	}

	return {
		id: null,
		userdisplayname: null,
		email: null,
		phone: null,
		tzoffsetminutes: 0,
		tzid: "UTC"
	}
}

function saveUserInfo(userInfo) {
	const value = userInfo ? JSON.stringify(userInfo) : null
	localStorage.setItem(ID_USERINFO_KEY, value)
}

function clearAllTokenInfo() {
	localStorage.removeItem(ID_TOKEN_KEY)
	sessionStorage.removeItem(ID_TOKEN_KEY)

	localStorage.removeItem(ID_RTOKEN_KEY)
	sessionStorage.removeItem(ID_RTOKEN_KEY)

	localStorage.removeItem(ID_USERINFO_KEY)
	sessionStorage.removeItem(ID_USERINFO_KEY)
}

export default {
	getClientId,
	getToken,
	saveToken,
	saveSessionToken,
	getRToken,
	saveRToken,
	getUserInfo,
	saveUserInfo,
	clearAllTokenInfo,
	isCookieAccepted
};