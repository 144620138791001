function getKeyByValue(object, value) {
	return value != undefined && value != null
		? Object.keys(object).find(key => object[key] === value)
		: null;
}

function isMobile(){
	if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
		return false;
	}
	return /Mobile/.test(navigator.userAgent);
}

export {
	getKeyByValue,
	isMobile
}