/**
 * @fileoverview gRPC-Web generated client stub for services
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.services = require('./Game_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.services.GameServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.services.GameServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetGameDefinitionsRequest,
 *   !proto.services.GetGameDefinitionsResponse>}
 */
const methodDescriptor_GameService_GetGameDefinitions = new grpc.web.MethodDescriptor(
  '/services.GameService/GetGameDefinitions',
  grpc.web.MethodType.UNARY,
  proto.services.GetGameDefinitionsRequest,
  proto.services.GetGameDefinitionsResponse,
  /**
   * @param {!proto.services.GetGameDefinitionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetGameDefinitionsResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetGameDefinitionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetGameDefinitionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetGameDefinitionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getGameDefinitions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetGameDefinitions',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameDefinitions,
      callback);
};


/**
 * @param {!proto.services.GetGameDefinitionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetGameDefinitionsResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getGameDefinitions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetGameDefinitions',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameDefinitions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameDefId,
 *   !proto.services.GameDefinition>}
 */
const methodDescriptor_GameService_GetGameDefinition = new grpc.web.MethodDescriptor(
  '/services.GameService/GetGameDefinition',
  grpc.web.MethodType.UNARY,
  proto.services.GameDefId,
  proto.services.GameDefinition,
  /**
   * @param {!proto.services.GameDefId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameDefinition.deserializeBinary
);


/**
 * @param {!proto.services.GameDefId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameDefinition)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameDefinition>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getGameDefinition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetGameDefinition',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameDefinition,
      callback);
};


/**
 * @param {!proto.services.GameDefId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameDefinition>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getGameDefinition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetGameDefinition',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameDefinition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameDefinition,
 *   !proto.services.GameDefId>}
 */
const methodDescriptor_GameService_InsertGameDefinition = new grpc.web.MethodDescriptor(
  '/services.GameService/InsertGameDefinition',
  grpc.web.MethodType.UNARY,
  proto.services.GameDefinition,
  proto.services.GameDefId,
  /**
   * @param {!proto.services.GameDefinition} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameDefId.deserializeBinary
);


/**
 * @param {!proto.services.GameDefinition} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameDefId)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameDefId>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.insertGameDefinition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/InsertGameDefinition',
      request,
      metadata || {},
      methodDescriptor_GameService_InsertGameDefinition,
      callback);
};


/**
 * @param {!proto.services.GameDefinition} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameDefId>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.insertGameDefinition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/InsertGameDefinition',
      request,
      metadata || {},
      methodDescriptor_GameService_InsertGameDefinition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameDefinition,
 *   !proto.services.GameDefId>}
 */
const methodDescriptor_GameService_UpdateGameDefinition = new grpc.web.MethodDescriptor(
  '/services.GameService/UpdateGameDefinition',
  grpc.web.MethodType.UNARY,
  proto.services.GameDefinition,
  proto.services.GameDefId,
  /**
   * @param {!proto.services.GameDefinition} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameDefId.deserializeBinary
);


/**
 * @param {!proto.services.GameDefinition} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameDefId)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameDefId>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.updateGameDefinition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/UpdateGameDefinition',
      request,
      metadata || {},
      methodDescriptor_GameService_UpdateGameDefinition,
      callback);
};


/**
 * @param {!proto.services.GameDefinition} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameDefId>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.updateGameDefinition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/UpdateGameDefinition',
      request,
      metadata || {},
      methodDescriptor_GameService_UpdateGameDefinition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetGameDefinitionsRequest,
 *   !proto.services.GetGameDefinitionsResponse>}
 */
const methodDescriptor_GameService_GetPublishedGameDefinitions = new grpc.web.MethodDescriptor(
  '/services.GameService/GetPublishedGameDefinitions',
  grpc.web.MethodType.UNARY,
  proto.services.GetGameDefinitionsRequest,
  proto.services.GetGameDefinitionsResponse,
  /**
   * @param {!proto.services.GetGameDefinitionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetGameDefinitionsResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetGameDefinitionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetGameDefinitionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetGameDefinitionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getPublishedGameDefinitions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetPublishedGameDefinitions',
      request,
      metadata || {},
      methodDescriptor_GameService_GetPublishedGameDefinitions,
      callback);
};


/**
 * @param {!proto.services.GetGameDefinitionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetGameDefinitionsResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getPublishedGameDefinitions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetPublishedGameDefinitions',
      request,
      metadata || {},
      methodDescriptor_GameService_GetPublishedGameDefinitions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetMyOwnedGamesRequest,
 *   !proto.services.GetGamesResponse>}
 */
const methodDescriptor_GameService_GetMyOwnedGames = new grpc.web.MethodDescriptor(
  '/services.GameService/GetMyOwnedGames',
  grpc.web.MethodType.UNARY,
  proto.services.GetMyOwnedGamesRequest,
  proto.services.GetGamesResponse,
  /**
   * @param {!proto.services.GetMyOwnedGamesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetGamesResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetMyOwnedGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetGamesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetGamesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getMyOwnedGames =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetMyOwnedGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetMyOwnedGames,
      callback);
};


/**
 * @param {!proto.services.GetMyOwnedGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetGamesResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getMyOwnedGames =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetMyOwnedGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetMyOwnedGames);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetGamesRequest,
 *   !proto.services.GetGamesResponse>}
 */
const methodDescriptor_GameService_GetGames = new grpc.web.MethodDescriptor(
  '/services.GameService/GetGames',
  grpc.web.MethodType.UNARY,
  proto.services.GetGamesRequest,
  proto.services.GetGamesResponse,
  /**
   * @param {!proto.services.GetGamesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetGamesResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetGamesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetGamesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getGames =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGames,
      callback);
};


/**
 * @param {!proto.services.GetGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetGamesResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getGames =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGames);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetMyOwnedGamesRequest,
 *   !proto.services.GetActiveGamesResponse>}
 */
const methodDescriptor_GameService_GetMyOnGoingGames = new grpc.web.MethodDescriptor(
  '/services.GameService/GetMyOnGoingGames',
  grpc.web.MethodType.UNARY,
  proto.services.GetMyOwnedGamesRequest,
  proto.services.GetActiveGamesResponse,
  /**
   * @param {!proto.services.GetMyOwnedGamesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetActiveGamesResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetMyOwnedGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetActiveGamesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetActiveGamesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getMyOnGoingGames =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetMyOnGoingGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetMyOnGoingGames,
      callback);
};


/**
 * @param {!proto.services.GetMyOwnedGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetActiveGamesResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getMyOnGoingGames =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetMyOnGoingGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetMyOnGoingGames);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetActiveGamesRequest,
 *   !proto.services.GetActiveGamesResponse>}
 */
const methodDescriptor_GameService_GetActiveGames = new grpc.web.MethodDescriptor(
  '/services.GameService/GetActiveGames',
  grpc.web.MethodType.UNARY,
  proto.services.GetActiveGamesRequest,
  proto.services.GetActiveGamesResponse,
  /**
   * @param {!proto.services.GetActiveGamesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetActiveGamesResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetActiveGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetActiveGamesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetActiveGamesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getActiveGames =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetActiveGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetActiveGames,
      callback);
};


/**
 * @param {!proto.services.GetActiveGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetActiveGamesResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getActiveGames =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetActiveGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetActiveGames);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetAllGamesRequest,
 *   !proto.services.GetGamesResponse>}
 */
const methodDescriptor_GameService_GetAllGames = new grpc.web.MethodDescriptor(
  '/services.GameService/GetAllGames',
  grpc.web.MethodType.UNARY,
  proto.services.GetAllGamesRequest,
  proto.services.GetGamesResponse,
  /**
   * @param {!proto.services.GetAllGamesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetGamesResponse.deserializeBinary
);


/**
 * @param {!proto.services.GetAllGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetGamesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetGamesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getAllGames =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetAllGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetAllGames,
      callback);
};


/**
 * @param {!proto.services.GetAllGamesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetGamesResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getAllGames =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetAllGames',
      request,
      metadata || {},
      methodDescriptor_GameService_GetAllGames);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameDefId,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_CreateGame = new grpc.web.MethodDescriptor(
  '/services.GameService/CreateGame',
  grpc.web.MethodType.UNARY,
  proto.services.GameDefId,
  proto.services.GameState,
  /**
   * @param {!proto.services.GameDefId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.GameDefId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.createGame =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/CreateGame',
      request,
      metadata || {},
      methodDescriptor_GameService_CreateGame,
      callback);
};


/**
 * @param {!proto.services.GameDefId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.createGame =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/CreateGame',
      request,
      metadata || {},
      methodDescriptor_GameService_CreateGame);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_ResetGame = new grpc.web.MethodDescriptor(
  '/services.GameService/ResetGame',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.GameState,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.resetGame =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/ResetGame',
      request,
      metadata || {},
      methodDescriptor_GameService_ResetGame,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.resetGame =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/ResetGame',
      request,
      metadata || {},
      methodDescriptor_GameService_ResetGame);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_StartGame = new grpc.web.MethodDescriptor(
  '/services.GameService/StartGame',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.GameState,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.startGame =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/StartGame',
      request,
      metadata || {},
      methodDescriptor_GameService_StartGame,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.startGame =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/StartGame',
      request,
      metadata || {},
      methodDescriptor_GameService_StartGame);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.StartGameRoundRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_StartGameRound = new grpc.web.MethodDescriptor(
  '/services.GameService/StartGameRound',
  grpc.web.MethodType.UNARY,
  proto.services.StartGameRoundRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.StartGameRoundRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.StartGameRoundRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.startGameRound =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/StartGameRound',
      request,
      metadata || {},
      methodDescriptor_GameService_StartGameRound,
      callback);
};


/**
 * @param {!proto.services.StartGameRoundRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.startGameRound =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/StartGameRound',
      request,
      metadata || {},
      methodDescriptor_GameService_StartGameRound);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_GetGameState = new grpc.web.MethodDescriptor(
  '/services.GameService/GetGameState',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.GameState,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getGameState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetGameState',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameState,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getGameState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetGameState',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_GetGameStateEx = new grpc.web.MethodDescriptor(
  '/services.GameService/GetGameStateEx',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.GameState,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getGameStateEx =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetGameStateEx',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameStateEx,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getGameStateEx =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetGameStateEx',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameStateEx);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_NotifyGameState = new grpc.web.MethodDescriptor(
  '/services.GameService/NotifyGameState',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.GameState,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.notifyGameState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/NotifyGameState',
      request,
      metadata || {},
      methodDescriptor_GameService_NotifyGameState,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.notifyGameState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/NotifyGameState',
      request,
      metadata || {},
      methodDescriptor_GameService_NotifyGameState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.ToggleGameStateDebugRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_ToggleGameStateDebug = new grpc.web.MethodDescriptor(
  '/services.GameService/ToggleGameStateDebug',
  grpc.web.MethodType.UNARY,
  proto.services.ToggleGameStateDebugRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.ToggleGameStateDebugRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.ToggleGameStateDebugRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.toggleGameStateDebug =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/ToggleGameStateDebug',
      request,
      metadata || {},
      methodDescriptor_GameService_ToggleGameStateDebug,
      callback);
};


/**
 * @param {!proto.services.ToggleGameStateDebugRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.toggleGameStateDebug =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/ToggleGameStateDebug',
      request,
      metadata || {},
      methodDescriptor_GameService_ToggleGameStateDebug);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.ToggleGameStateVideoRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_ToggleGameStateVideo = new grpc.web.MethodDescriptor(
  '/services.GameService/ToggleGameStateVideo',
  grpc.web.MethodType.UNARY,
  proto.services.ToggleGameStateVideoRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.ToggleGameStateVideoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.ToggleGameStateVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.toggleGameStateVideo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/ToggleGameStateVideo',
      request,
      metadata || {},
      methodDescriptor_GameService_ToggleGameStateVideo,
      callback);
};


/**
 * @param {!proto.services.ToggleGameStateVideoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.toggleGameStateVideo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/ToggleGameStateVideo',
      request,
      metadata || {},
      methodDescriptor_GameService_ToggleGameStateVideo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.InviteParticipantRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_InviteParticipantToGame = new grpc.web.MethodDescriptor(
  '/services.GameService/InviteParticipantToGame',
  grpc.web.MethodType.UNARY,
  proto.services.InviteParticipantRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.InviteParticipantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.InviteParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.inviteParticipantToGame =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/InviteParticipantToGame',
      request,
      metadata || {},
      methodDescriptor_GameService_InviteParticipantToGame,
      callback);
};


/**
 * @param {!proto.services.InviteParticipantRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.inviteParticipantToGame =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/InviteParticipantToGame',
      request,
      metadata || {},
      methodDescriptor_GameService_InviteParticipantToGame);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.JoinGameRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_JoinGame = new grpc.web.MethodDescriptor(
  '/services.GameService/JoinGame',
  grpc.web.MethodType.UNARY,
  proto.services.JoinGameRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.JoinGameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.JoinGameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.joinGame =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/JoinGame',
      request,
      metadata || {},
      methodDescriptor_GameService_JoinGame,
      callback);
};


/**
 * @param {!proto.services.JoinGameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.joinGame =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/JoinGame',
      request,
      metadata || {},
      methodDescriptor_GameService_JoinGame);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UpdateParticipantStateRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_UpdateParticipantState = new grpc.web.MethodDescriptor(
  '/services.GameService/UpdateParticipantState',
  grpc.web.MethodType.UNARY,
  proto.services.UpdateParticipantStateRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.UpdateParticipantStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.UpdateParticipantStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.updateParticipantState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/UpdateParticipantState',
      request,
      metadata || {},
      methodDescriptor_GameService_UpdateParticipantState,
      callback);
};


/**
 * @param {!proto.services.UpdateParticipantStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.updateParticipantState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/UpdateParticipantState',
      request,
      metadata || {},
      methodDescriptor_GameService_UpdateParticipantState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UpdateParticipantHandStateRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_UpdateParticipantHandState = new grpc.web.MethodDescriptor(
  '/services.GameService/UpdateParticipantHandState',
  grpc.web.MethodType.UNARY,
  proto.services.UpdateParticipantHandStateRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.UpdateParticipantHandStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.UpdateParticipantHandStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.updateParticipantHandState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/UpdateParticipantHandState',
      request,
      metadata || {},
      methodDescriptor_GameService_UpdateParticipantHandState,
      callback);
};


/**
 * @param {!proto.services.UpdateParticipantHandStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.updateParticipantHandState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/UpdateParticipantHandState',
      request,
      metadata || {},
      methodDescriptor_GameService_UpdateParticipantHandState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.UpdateJokerTypeRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_UpdateJokerType = new grpc.web.MethodDescriptor(
  '/services.GameService/UpdateJokerType',
  grpc.web.MethodType.UNARY,
  proto.services.UpdateJokerTypeRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.UpdateJokerTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.UpdateJokerTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.updateJokerType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/UpdateJokerType',
      request,
      metadata || {},
      methodDescriptor_GameService_UpdateJokerType,
      callback);
};


/**
 * @param {!proto.services.UpdateJokerTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.updateJokerType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/UpdateJokerType',
      request,
      metadata || {},
      methodDescriptor_GameService_UpdateJokerType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.SelfInviteRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_SelfInvite = new grpc.web.MethodDescriptor(
  '/services.GameService/SelfInvite',
  grpc.web.MethodType.UNARY,
  proto.services.SelfInviteRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.SelfInviteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.SelfInviteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.selfInvite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/SelfInvite',
      request,
      metadata || {},
      methodDescriptor_GameService_SelfInvite,
      callback);
};


/**
 * @param {!proto.services.SelfInviteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.selfInvite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/SelfInvite',
      request,
      metadata || {},
      methodDescriptor_GameService_SelfInvite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.GetGameRoundsResponse>}
 */
const methodDescriptor_GameService_GetGameRounds = new grpc.web.MethodDescriptor(
  '/services.GameService/GetGameRounds',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.GetGameRoundsResponse,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GetGameRoundsResponse.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GetGameRoundsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GetGameRoundsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getGameRounds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetGameRounds',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameRounds,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GetGameRoundsResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getGameRounds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetGameRounds',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameRounds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GetGameRoundRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_GetGameRound = new grpc.web.MethodDescriptor(
  '/services.GameService/GetGameRound',
  grpc.web.MethodType.UNARY,
  proto.services.GetGameRoundRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.GetGameRoundRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.GetGameRoundRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getGameRound =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetGameRound',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameRound,
      callback);
};


/**
 * @param {!proto.services.GetGameRoundRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getGameRound =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetGameRound',
      request,
      metadata || {},
      methodDescriptor_GameService_GetGameRound);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.SortedCardGroup>}
 */
const methodDescriptor_GameService_GetDefaultSortedCards = new grpc.web.MethodDescriptor(
  '/services.GameService/GetDefaultSortedCards',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.SortedCardGroup,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.SortedCardGroup.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.SortedCardGroup)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.SortedCardGroup>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getDefaultSortedCards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetDefaultSortedCards',
      request,
      metadata || {},
      methodDescriptor_GameService_GetDefaultSortedCards,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.SortedCardGroup>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getDefaultSortedCards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetDefaultSortedCards',
      request,
      metadata || {},
      methodDescriptor_GameService_GetDefaultSortedCards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.SortedCardGroup>}
 */
const methodDescriptor_GameService_GetSeatPickSortedCards = new grpc.web.MethodDescriptor(
  '/services.GameService/GetSeatPickSortedCards',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.SortedCardGroup,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.SortedCardGroup.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.SortedCardGroup)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.SortedCardGroup>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getSeatPickSortedCards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetSeatPickSortedCards',
      request,
      metadata || {},
      methodDescriptor_GameService_GetSeatPickSortedCards,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.SortedCardGroup>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getSeatPickSortedCards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetSeatPickSortedCards',
      request,
      metadata || {},
      methodDescriptor_GameService_GetSeatPickSortedCards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.services.ParticipantSeatPickOrderResponse>}
 */
const methodDescriptor_GameService_GetSeatPickParticipantsOrder = new grpc.web.MethodDescriptor(
  '/services.GameService/GetSeatPickParticipantsOrder',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  proto.services.ParticipantSeatPickOrderResponse,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.ParticipantSeatPickOrderResponse.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.ParticipantSeatPickOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.ParticipantSeatPickOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getSeatPickParticipantsOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetSeatPickParticipantsOrder',
      request,
      metadata || {},
      methodDescriptor_GameService_GetSeatPickParticipantsOrder,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.ParticipantSeatPickOrderResponse>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getSeatPickParticipantsOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetSeatPickParticipantsOrder',
      request,
      metadata || {},
      methodDescriptor_GameService_GetSeatPickParticipantsOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.GameId,
 *   !proto.google.protobuf.StringValue>}
 */
const methodDescriptor_GameService_GetMyGameVideoToken = new grpc.web.MethodDescriptor(
  '/services.GameService/GetMyGameVideoToken',
  grpc.web.MethodType.UNARY,
  proto.services.GameId,
  google_protobuf_wrappers_pb.StringValue,
  /**
   * @param {!proto.services.GameId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.StringValue.deserializeBinary
);


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.StringValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.StringValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.getMyGameVideoToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/GetMyGameVideoToken',
      request,
      metadata || {},
      methodDescriptor_GameService_GetMyGameVideoToken,
      callback);
};


/**
 * @param {!proto.services.GameId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.StringValue>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.getMyGameVideoToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/GetMyGameVideoToken',
      request,
      metadata || {},
      methodDescriptor_GameService_GetMyGameVideoToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.DoClosedPlayerActionRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_DoClosedPlayerAction = new grpc.web.MethodDescriptor(
  '/services.GameService/DoClosedPlayerAction',
  grpc.web.MethodType.UNARY,
  proto.services.DoClosedPlayerActionRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.DoClosedPlayerActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.DoClosedPlayerActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.doClosedPlayerAction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/DoClosedPlayerAction',
      request,
      metadata || {},
      methodDescriptor_GameService_DoClosedPlayerAction,
      callback);
};


/**
 * @param {!proto.services.DoClosedPlayerActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.doClosedPlayerAction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/DoClosedPlayerAction',
      request,
      metadata || {},
      methodDescriptor_GameService_DoClosedPlayerAction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.DoOpenPlayerActionRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_DoOpenPlayerAction = new grpc.web.MethodDescriptor(
  '/services.GameService/DoOpenPlayerAction',
  grpc.web.MethodType.UNARY,
  proto.services.DoOpenPlayerActionRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.DoOpenPlayerActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.DoOpenPlayerActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.doOpenPlayerAction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/DoOpenPlayerAction',
      request,
      metadata || {},
      methodDescriptor_GameService_DoOpenPlayerAction,
      callback);
};


/**
 * @param {!proto.services.DoOpenPlayerActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.doOpenPlayerAction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/DoOpenPlayerAction',
      request,
      metadata || {},
      methodDescriptor_GameService_DoOpenPlayerAction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.services.DoOpenTableActionRequest,
 *   !proto.services.GameState>}
 */
const methodDescriptor_GameService_DoOpenTableAction = new grpc.web.MethodDescriptor(
  '/services.GameService/DoOpenTableAction',
  grpc.web.MethodType.UNARY,
  proto.services.DoOpenTableActionRequest,
  proto.services.GameState,
  /**
   * @param {!proto.services.DoOpenTableActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.services.GameState.deserializeBinary
);


/**
 * @param {!proto.services.DoOpenTableActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.services.GameState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.services.GameState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.services.GameServiceClient.prototype.doOpenTableAction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/services.GameService/DoOpenTableAction',
      request,
      metadata || {},
      methodDescriptor_GameService_DoOpenTableAction,
      callback);
};


/**
 * @param {!proto.services.DoOpenTableActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.services.GameState>}
 *     Promise that resolves to the response
 */
proto.services.GameServicePromiseClient.prototype.doOpenTableAction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/services.GameService/DoOpenTableAction',
      request,
      metadata || {},
      methodDescriptor_GameService_DoOpenTableAction);
};


module.exports = proto.services;

