var SuitSymbols = ['x','J','♥','♦','♣','♠'];

const Suits = [
	'U', // hidden/unknown
	'J', // joker
	'H',
	'D',
	'C',
	'S',
]
const RankFaces = [
	'X', // unknown
	'A',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'J',
	'Q',
	'K',
];

// TODO: fetch via API
const ROLES = [
	{ id: "00000000-0000-0000-0000-000000000000", role: "administrator" }
];
const ROLESLOOKUPBYID = ROLES.reduce((acc, cur) => {
	acc[cur.id] = cur.role;
	return acc;
}, {});
const ROLESLOOKUPBYNAME = ROLES.reduce((acc, cur) => {
	acc[cur.role] = cur.id;
	return acc;
}, {});

const ADMIN_ROLE_ID = ROLES[0]
const ADMIN_USER_ID = '00000000-0000-0000-0000-000000000000'

const CLAIMS = [
	{ name: "isadministrator", value: "isadministrator" },
	{ name: "isgamecreator", value: "isgamecreator" },
	{ name: "isgamehost", value: "isgamehost" },
];
const CLAIMSLOOKUPBYNAME = CLAIMS.reduce((acc, cur) => {
	acc[cur.name] = cur.name;
	return acc;
}, {});


const env = process.env.NODE_ENV; // production, dev, test
const APIURL =  env == "production"
	? window.origin
	: (env == "test" ? window.location.protocol + '//' + window.location.hostname : 'https://dev.zoomgamez.com:80');

export {
	Suits,
	SuitSymbols,
	RankFaces,
	APIURL, //TODO - Can move to env later

	ROLES,
	ROLESLOOKUPBYID,
	ROLESLOOKUPBYNAME,
	CLAIMS,
	CLAIMSLOOKUPBYNAME,

	ADMIN_ROLE_ID,
	ADMIN_USER_ID,
}