'use strict;'

import Vue from 'vue';
import DEBUG from '@/common/DEBUG';

Vue.config.productionTip = true
Vue.config.performance = true

import app from './appex.vue';
import router from './router';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas);
Vue.component('fa-icon', FontAwesomeIcon);

import jQuery from 'jquery'
var $ = jQuery;
(window as any).$ = $;

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { BootstrapVue, ToastPlugin, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(IconsPlugin)

import toastmixin from './mixins/toast'
Vue.mixin(toastmixin)

import fullscreen from 'vue-fullscreen'
Vue.use(fullscreen)

// Google - Tracking
import VueGtag from "vue-gtag";
import { GAKey } from '@/common/ga'
DEBUG.log(`Using GA - ${GAKey}`);

Vue.use(VueGtag, {
	config: { id: GAKey },
	appName: 'ZoomGamez-Web',
	pageTrackerScreenviewEnabled: true
}, router);

// Google - Ads
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

/*
Usage: TODO:
<Adsense
	data-ad-client="ca-pub-XXXXXXXXXXXXXXXX"
	data-ad-slot="1234567890">
</Adsense>
<InArticleAdsense
	data-ad-client="ca-pub-XXXXXXXXXXXXXXXX"
	data-ad-slot="1234567890">
</InArticleAdsense>
<InFeedAdsense
	data-ad-layout-key="-fg+5n+6t-e7+r"
	data-ad-client="ca-pub-XXXXXXXXXXXXXXXX"
	data-ad-slot="1234567890">
</InFeedAdsense>
*/

router.beforeEach((to: any, from: any, next: any) => {

	const state = store.state;

	const isAuthenticated = state.token != null;
	const requiresAuth = to.matched.some((record: any) => record.meta.requiresAuth);
	const ignoreRedirect = to.matched.some((record: any) => record.meta.ignoreRedirect);

	// console.log('ROUTE:BEFOREEACH - from - ',from,' to - ', to, ' Next - ',next);
	// console.log('ROUTE:BEFOREEACH - state - ',state);
	// console.log('isAuthenticated - ',isAuthenticated,' Required Auth - ',requiresAuth, ' Ignore Redirect - ',ignoreRedirect)

	const ignoreRoutes = ['login', 'logout', 'error_403', 'error_404', 'error_500'];
	if (!ignoreRoutes.includes(to.name)) {
		const requiresAnyRole = to?.meta?.requiresAnyRole;
		const requiresAnyClaim = to?.meta?.requiresAnyClaim;
		console.log('ROUTE:BEFOREEACH - %o requiresAnyRole=%o requiresAnyClaim=%o', to?.path, requiresAnyRole, requiresAnyClaim)

		if (requiresAuth && !isAuthenticated) {
			console.log('required Auth, but not authenticated, redirecting to login... ignoreRedirect=', ignoreRedirect)
			const query = ignoreRedirect ? null : { r: window.location.pathname }
			next({ name: 'joingame', query })
			return
		} 
		
		if (requiresAnyRole || requiresAnyClaim) {
			const getters = store.getters;
			const allowed = (requiresAnyRole && requiresAnyRole.some(r => getters.hasRole(r)))
				|| (requiresAnyClaim && requiresAnyClaim.some(c => getters.hasClaim(c)));
			if (!allowed) {
				const query = { r: window.location.pathname }
				next({ name: 'error_403', query })
				return
			}
		}
	}
	
	console.log('default redirecting to - %o', to)
	next()
})

import i18n from './i18n'
import filters from '@/filters';
Vue.use(filters)

new Vue({
	i18n,
	router,
	store,
	render: h => h(app),
	created() {
		const that: any = this
		that.$store.dispatch("initConnection", {});
	},
}).$mount('#app')