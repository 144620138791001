<template>
	<b-progress v-if="localprocessingpercent>0"
				class="siteprogressbar"
				variant="warning"
				:value="localprocessingpercent"
				:max="100"
				:striped="false"
				height="2px"
				animated />
	<b-progress v-else-if="haserror"
				class="siteprogressbar"
				variant="danger"
				:value="100"
				:max="100"
				:striped="false"
				height="2px"
				animated />
</template>
<script>
	import { mapState } from 'vuex'
	import DEBUG from '@/common/DEBUG';

	export default {
		name: 'appprogressbar',

		computed: {
			...mapState([
				'haserror', 'processingpercent'
			]),
		},

		data() {
			return {
				localprocessingpercent: null,
				timerobject: null,
			}
		},

		watch: {
			processingpercent(value) {
				if (value > 0) {
					//DEBUG.log('setting progress timer...')
					this.timerobject = setInterval(this.incrementPercent, 1000)
				} else {
					//DEBUG.log('clearing progress timer...')
					clearInterval(this.timerobject)
				}

				this.localprocessingpercent = value
			}
		},

		methods: {
			incrementPercent() {
				if (this.localprocessingpercent < 90) {
					this.localprocessingpercent += 3;
				}
			}
		}
	}
</script>
<style lang="scss">
	.siteprogressbar {
		z-index: 10;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
	}

		.siteprogressbar.progress {
			background-color: transparent;
		}
</style>