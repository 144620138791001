import { format } from 'date-fns'

export default {
	install(Vue) {
		Vue.filter("localdate", function (value) {
			if (!value) {
				return "";
			}
			return format(new Date(value), "dd-MMM-yyyy");
		});

		Vue.filter("localdatetime", function (value) {
			if (!value) {
				return "";
			}
			return format(new Date(value), "dd-MMM-yyyy HH:mm");
		});

		Vue.filter("todatetime", function (value) {
			if (!value || !value.seconds) {
				return "";
			}
			return new Date(value.seconds * 1000);
		});
	}
}